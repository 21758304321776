import styled, { css } from 'styled-components';
import DateIcon from '../../../assets/images/date-icon.svg?react';

export interface DatePickerProps {
  $isSoftPull?: boolean;
  $iconColor?: string;
  size?: string;
  $isError?: boolean;
  $isOccupancyDate?: boolean;
  $hasAsterisk?: boolean;
  $isHidden?: boolean;
  $isDisabled?: boolean;
}

const AlignText = css`
  display: flex;
  align-items: center;
`;

const FontFamily = css`
  font-family: Poppins;
`;

const FontStyles = css`
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Container = styled.div<DatePickerProps>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;

export const Label = styled.label<DatePickerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  ${FontFamily};
  font-size: 1.063rem;
  ${FontStyles};
  color: ${({ theme }) => theme.colors.bgGrey};
  width: ${({ $hasAsterisk }) => ($hasAsterisk ? 'max-content' : '100%')};
  p {
    color: ${({ theme }) => theme.colors.errorColor};
    width: max-content;
  }
`;

export const InputContainer = styled.div<DatePickerProps>`
  position: relative;
  ${AlignText};
  overflow: hidden;
  border-radius: 1.875rem;
  border: 0.063rem solid ${({ $isError, theme }) => ($isError ? theme.colors.errorColor : theme.colors.borderColor)};
  width: ${({ $isOccupancyDate }) => ($isOccupancyDate ? '30%' : '100%')};
  height: 3.125rem;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export const DataPickerContainer = styled.div<DatePickerProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  flex-direction: column;
  flex-grow: 1;
  width: ${({ size }) => size};
  ${({ $hasAsterisk, theme }) =>
    $hasAsterisk &&
    css`
      label::after {
        content: '*';
        color: ${theme.colors.errorColor || 'red'};
        visibility: visible;
      }
    `}
  span {
    color: ${({ theme }) => theme.colors.errorColor};
    ${FontFamily};
    font-size: 0.625rem;
    ${FontStyles};
  }
  div {
    opacity: ${({ $isDisabled }) => ($isDisabled ? '0.5' : 'none')};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export const InputDataPicker = styled.input<DatePickerProps>`
  ${AlignText};
  border: none;
  width: ${({ $isSoftPull }) => ($isSoftPull ? '100%' : '80%')};
  padding-left: ${({ $isSoftPull }) => ($isSoftPull ? '0' : '1rem')};
  text-align: ${({ $isSoftPull }) => ($isSoftPull ? 'center' : 'start')};
  background: ${({ theme }) => theme.colors.bgWhite};
  color: ${({ theme }) => theme.colors.textGrey};
  ${FontFamily};
  font-size: 1.188rem;
  font-weight: 400;
  max-width: 100%;
  line-height: normal;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.borderColor};
  }
`;

export const DateInputIcon = styled(DateIcon)<DatePickerProps>`
  width: 1.75rem;
  height: 1.75rem;
  path {
    width: 1.25rem;
    fill: ${({ theme }) => theme.colors.primary};
  }
  .circle-color {
    fill: ${({ theme }) => theme.colors.borderColor};
  }
`;

export const IconContainer = styled.div`
  position: relative;
  margin-left: 0.8rem;
  ${AlignText};
  pointer-events: none;
`;
