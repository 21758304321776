import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  DropDownContainer,
  DropDownHeader,
  DropDownListContainer,
  DropDownList,
  ListItem,
  ErrorMessage,
  IconContainer,
  DownIcon,
  InputContainer,
  ErrorIcon,
  Label,
  ContainerInfoIcon,
  InfoIcon,
  InputText,
  WrapperLabel,
} from './DrowDownInput.styles';
import { DefaultModal } from '@/components/Modals/DefaultModal/DefaultModal';
import { useAppSelector } from '@app/hooks';
import { handleIcons } from '@/utils/handleIcons';
import { capitalizeWords } from '@/utils/capitalizeWords';

export interface DropDownInputProps {
  dataTestId?: string;
  label?: string;
  options?: [{ label: string; beValue: string }];
  size?: string;
  error?: string;
  isRequired?: boolean;
  value?: string;
  onChange?: (option: string, propertyType?: string, index?: number) => void;
  propertyType?: string;
  typeOfIcon?: IconType;
  isOpen?: boolean;
  isHidden?: boolean;
  iconColor?: string;
  labelContainer?: boolean;
  placeHolder?: string;
  isInfoModal?: boolean;
  hasAsterisk?: boolean;
  index?: number;
  capitalizeWord?: boolean;
  infoModal?: { header?: string; body?: string; bodyLow?: string; bodyHigh?: string; button?: string; textOne?: string; textTwo?: string };
  validationMessage?: string;
}

type IconType = 'dollar' | 'employment' | 'location';

export const DropDownInput: React.FC<DropDownInputProps> = ({
  dataTestId,
  onChange,
  propertyType,
  label = '',
  options = [],
  size = '10rem',
  error = '',
  isRequired = true,
  value = '',
  typeOfIcon,
  isHidden = false,
  iconColor = '#3A149E',
  labelContainer = false,
  placeHolder = '',
  isInfoModal = false,
  hasAsterisk = false,
  infoModal,
  index,
  capitalizeWord = false,
  validationMessage = '',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [isOptionSelected, setOpenOption] = useState<string | undefined>(undefined);
  const [inputError, setInputError] = useState<string | undefined>(error);
  const [openModal, setOpenModal] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const currentForm = useAppSelector((state) => state.CurrentForm);

  const IconComponent = typeOfIcon ? handleIcons(typeOfIcon) : null;
  const hasIcon = IconComponent !== null;
  const toggling = () => {
    setInputError(undefined);
    setIsOpen(!isOpen);
    setOpenOption(placeHolder);
  };

  const onOptionClicked = (option: { label: string; beValue: string }) => () => {
    setSelectedOption(option.label);
    setIsOpen(false);
    if (onChange) {
      onChange(option.beValue, propertyType, index);
    }
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      if (!selectedOption && isOptionSelected && isRequired) {
        setInputError(validationMessage || `You must provide a ${placeHolder} `);
        setIsOpen(false);
      } else {
        setInputError(undefined);
        setIsOpen(false);
      }
    }
  };

  const getLabelByBeValue = useCallback(
    (beValue: string) => {
      const option = options.find((item) => item.beValue === beValue);
      return option ? option.label : null;
    },
    [options],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    if (propertyType) setSelectedOption(getLabelByBeValue(currentForm[propertyType]) || '');
  }, [propertyType, currentForm]);

  const handleModal = () => {
    setOpenModal(true);
  };
  return (
    <DropDownContainer ref={ref} $hasError={!!inputError} size={size} $isHidden={isHidden} $hasAsterisk={hasAsterisk}>
      {labelContainer && (
        <WrapperLabel>
          <Label $hasAsterisk={hasAsterisk}>{label}</Label>
          {isInfoModal && (
            <ContainerInfoIcon>
              <InfoIcon data-testid="info-icon" onClick={handleModal} />
            </ContainerInfoIcon>
          )}
        </WrapperLabel>
      )}
      <DropDownHeader style={{ cursor: 'pointer' }} $hasIcon={hasIcon} size={size} $hasError={!!inputError} onClick={toggling}>
        {typeOfIcon && <IconContainer $iconColor={iconColor}>{typeOfIcon && <IconContainer $iconColor={iconColor}>{IconComponent}</IconContainer>}</IconContainer>}
        <InputContainer>
          <InputText data-testid={dataTestId}>{capitalizeWords(selectedOption ? selectedOption : value || placeHolder, capitalizeWord)}</InputText>
          <DownIcon $isIconUp={isOpen} />
        </InputContainer>
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList size={size} $hasError={!!inputError} $labelContainer={labelContainer}>
            {options?.map((option) => (
              <ListItem style={{ cursor: 'pointer' }} onClick={onOptionClicked(option)} key={Math.random()}>
                {capitalizeWords(option?.label, capitalizeWord)}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
      {inputError && (
        <ErrorMessage>
          <ErrorIcon />
          {inputError}
        </ErrorMessage>
      )}
      {openModal && (
        <DefaultModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          infoModal={infoModal || templateConfig?.pages?.LetsGetStarted?.modals?.exampleModal}
          data-testid="modal"
          typeModal="default"
        />
      )}
    </DropDownContainer>
  );
};
