import SafeHTML from '@/utils/safeHTML';
import { CheckedIcon, ActiveIcon, TableContainer, Head, Body, IconContainer } from './TableProgram.styles';
import React, { useState, useCallback, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@app/hooks';
import { cashBack, calculateTotalTradeLineBalance } from '@/utils/debtConsolidationCalculator';
import { DefaultModal } from '@/components/Modals/DefaultModal/DefaultModal';
import { formatNumber } from '@/utils/formatValues';
import { pages } from '@/defaultVerbiages';
interface TradeLine {
  include?: boolean;
  accountNumber: string;
  tradelineName: string;
  tradelineBalance: string;
  tradelineMonthlyPayment: string;
  remove?: boolean;
  isChecked: boolean;
}

interface TableProgramsProps {
  isBarCustomColor?: boolean;
  barCustomColor?: string;
  isIconCustomColor?: boolean;
  iconCustomColor?: string;
  barTextCustomColor?: string;
}

const getIdForTradeLine = (tradeline: TradeLine): string => {
  return tradeline?.accountNumber + tradeline?.tradelineName + tradeline?.tradelineBalance + tradeline?.tradelineMonthlyPayment;
};
export const TableProgram: React.FC<TableProgramsProps> = ({
  isBarCustomColor = false,
  barCustomColor = '#3A149E',
  isIconCustomColor = false,
  iconCustomColor = '#3A149E',
  barTextCustomColor = '#FFFFFF',
}) => {
  const [checkGroup, setCheckGroup] = useState<{ [key: string]: boolean }>({});
  const [checkGroupData, setCheckGroupData] = useState<{ [key: string]: boolean }>({});
  const [openModal, setOpenModal] = useState(false);
  const [lastChecked, setLastChecked] = useState<string | null>(null);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let loanApplication = useAppSelector((state) => state.LoanApplication);

  useEffect(() => {
    const totalUsed = calculateTotalTradeLineBalance(tradelineObject);
    const cash = cashBack(loanApplication, totalUsed);
    if (cash < 0 && lastChecked) {
      handleCheckClick(lastChecked);
      setOpenModal(true);
    }
  }, [loanApplication?.borrowers[0]?.tradelines]); // eslint-disable-line react-hooks/exhaustive-deps

  const dispatch = useAppDispatch();
  const tradelineObject = loanApplication?.borrowers[0]?.tradelines || [];

  const updateTradelinesInRedux = (updatedTradelines: TradeLine[]) => {
    dispatch({
      type: 'LoanApplication/updateSpecificField',
      payload: {
        depth: ['borrowers', 0, 'tradelines'],
        value: updatedTradelines,
      },
    });
  };

  const getUpdatedTradelines = (checkGroup: Record<string, boolean>, tradelineObject: TradeLine[]): TradeLine[] => {
    return tradelineObject.map((tradeline) => ({
      ...tradeline,
      include: checkGroup[getIdForTradeLine(tradeline)] || false,
      isChecked: checkGroup[getIdForTradeLine(tradeline)] || false,
    }));
  };

  const handleCheckClick = useCallback(
    (check: string) => {
      setCheckGroup((prevCheckGroup) => {
        const updatedCheckGroup = {
          ...prevCheckGroup,
          [check]: !prevCheckGroup[check],
        };
        const updatedTradelines = getUpdatedTradelines(updatedCheckGroup, tradelineObject);
        updateTradelinesInRedux(updatedTradelines);
        if (updatedCheckGroup[check]) {
          setLastChecked(check);
        }
        return updatedCheckGroup;
      });
    },
    [tradelineObject], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleRemoveClick = useCallback(
    (check: string) => {
      setCheckGroupData((prevCheckGroupData) => ({
        ...prevCheckGroupData,
        [check]: !prevCheckGroupData[check],
      }));
      setCheckGroup((prevCheckGroup) => {
        const updatedCheckGroup = {
          ...prevCheckGroup,
          [check]: false,
        };
        const updatedTradelines = getUpdatedTradelines(updatedCheckGroup, tradelineObject);
        updateTradelinesInRedux(updatedTradelines);

        return updatedCheckGroup;
      });
    },
    [setCheckGroupData], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const renderIconBtn = useCallback(
    (check: string, isActive: boolean, handleClick: (check: string) => void): JSX.Element => {
      return (
        <IconContainer
          onClick={(e) => {
            e.preventDefault();
            handleClick(check);
          }}
          data-testid="check-button"
        >
          {isActive ? <CheckedIcon $isIconCustomColor={isIconCustomColor} $iconCustomColor={iconCustomColor} /> : <ActiveIcon />}
        </IconContainer>
      );
    },
    [isIconCustomColor, iconCustomColor],
  );

  const renderCheckBtn = useCallback(
    (check: string, isActive: boolean): JSX.Element => {
      const isChecked = isActive ? isActive : checkGroup[check];
      return renderIconBtn(check, isChecked, handleCheckClick);
    },
    [checkGroup, handleCheckClick, renderIconBtn],
  );

  const renderRemoveBtn = useCallback(
    (check: string): JSX.Element => {
      const isRemoved = checkGroupData[check];
      return renderIconBtn(check, isRemoved, handleRemoveClick);
    },
    [checkGroupData, handleRemoveClick, renderIconBtn],
  );

  return (
    <>
      <TableContainer $isHidden={templateConfig?.pages?.vmo?.vmoV2?.tableProgram?.isHidden ?? false}>
        <Head $isBarCustomColor={isBarCustomColor} $barCustomColor={barCustomColor} $barTextCustomColor={barTextCustomColor}>
          <tr>
            <th>{templateConfig?.pages?.vmo?.vmoV2?.tableProgram?.head?.include || 'include'}</th>
            <th>{templateConfig?.pages?.vmo?.vmoV2?.tableProgram?.head?.account || 'Account'}</th>
            <th>{templateConfig?.pages?.vmo?.vmoV2?.tableProgram?.head?.balance || 'Balance'}</th>
            <th>{templateConfig?.pages?.vmo?.vmoV2?.tableProgram?.head?.monthlyPayment || 'Monthly Payment'}</th>
            <SafeHTML tag="th" html={templateConfig?.pages?.vmo?.vmoV2?.tableProgram?.head?.remove || 'Not Yours or Old? <br>Remove From Calculation'} />
          </tr>
        </Head>
        <Body>
          {tradelineObject?.map((debts) => {
            const tradeLineId = getIdForTradeLine(debts);
            const isInclude = debts?.include ? debts?.include : false;
            return (
              <tr key={tradeLineId} className={checkGroupData[tradeLineId] ? 'disabled' : ''}>
                <th>{renderCheckBtn(tradeLineId, isInclude)}</th>
                <td>{debts.tradelineName}</td>
                <td>{formatNumber(Number(debts.tradelineBalance))}</td>
                <td>{formatNumber(Number(debts.tradelineMonthlyPayment))}</td>
                <td>{renderRemoveBtn(tradeLineId)}</td>
              </tr>
            );
          })}
        </Body>
      </TableContainer>
      {openModal && (
        <DefaultModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          infoModal={templateConfig?.pages?.vmo?.vmoV2?.modal?.tableAmountReached || pages?.vmo?.vmoV2?.modal?.tableAmountReached}
        />
      )}
    </>
  );
};

export default TableProgram;
