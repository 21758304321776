import SafeHTML from '@/utils/safeHTML';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { Chart } from '../Chart/Chart';
import { useAppSelector } from '@app/hooks';
import {
  WrapperContainer,
  WrapperPotentialContainer,
  Row,
  Col,
  Separator,
  ColPercent,
  HeaderContainer,
  ChardContainer,
  ProgressContainer,
  RowContainer,
  ColMonthly,
} from './PotentialMonthly.styles';
import { convertToNumber } from '@/utils/formatValues';
import { formatNumber } from '@/utils/formatValues';

interface Tradeline {
  include?: boolean;
  tradelineName: string;
  tradelineType: string;
  accountNumber: string;
  tradelineMonthlyPayment: string;
  tradelineBalance: string;
  isSecured: boolean;
  isChecked: boolean;
}

export const PotentialMonthly = () => {
  const isLoanDepot = true;
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let LoanApplication = useAppSelector((state) => state.LoanApplication);
  const EPayment = LoanApplication?.selectedProgram?.estimatedMonthlyPayment ? Math.round(convertToNumber(LoanApplication?.selectedProgram?.estimatedMonthlyPayment)) : 0;
  const tradeLines = LoanApplication?.borrowers[0]?.tradelines || [];
  const getTotalSelectedDebts = (arr: Tradeline[]): number =>
    arr.reduce((sum, { tradelineMonthlyPayment, include }) => (include ? sum + convertToNumber(tradelineMonthlyPayment || '0') : sum), 0);
  const calculatePercentage = (partial: number, total: number): number => (total === 0 ? 0 : Math.round(Math.min((partial / total) * 100, 100)));
  const handlePercentagePS = (): number => Math.max(calculatePercentage(totalSelectedDebts, EPayment + othersDebtsData), 0);
  const getTradeLineMonthlyPayment = (arr: Tradeline[]): number => arr.reduce((sum, { tradelineMonthlyPayment }) => sum + convertToNumber(tradelineMonthlyPayment || '0'), 0);
  const totalSelectedDebts = getTotalSelectedDebts(tradeLines);
  const othersDebtsData = getTradeLineMonthlyPayment(tradeLines);
  const potentialSavings = tradeLines.reduce((total, { tradelineMonthlyPayment, include }) => (!include ? total + convertToNumber(tradelineMonthlyPayment || '0') : total), 0);
  const getPotentialMonthly = (): string => {
    const data = totalSelectedDebts - EPayment;
    if (data <= 0) return '$0';
    return potentialSavings < 0 ? `-$${Math.abs(data)}` : `$${data}`;
  };
  return (
    <>
      <WrapperPotentialContainer data-testid="potential-monthly" $isHidden={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.potentialMonthly?.isHidden ?? false}>
        <HeaderContainer>
          <SafeHTML
            tag="h4"
            html={`${templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.potentialMonthly?.title || 'Potential Monthly Payment Savings'}${isLoanDepot ? `<span class="counter-tags">${templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.potentialMonthly?.superScript || '(4)'}</span>` : ''}`}
          />
          <Separator />
        </HeaderContainer>
        <WrapperContainer>
          <ProgressContainer>
            <RowContainer $isHidden={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.potentialMonthly?.yourLoan?.isHidden ?? false}>
              <Row>
                <Col>
                  <SafeHTML tag="span" html={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.potentialMonthly?.yourLoan?.title || 'Your loanDepot HELOC'} />
                </Col>
                <ColPercent>
                  <p>{calculatePercentage(EPayment, EPayment + othersDebtsData)}%</p>
                </ColPercent>
              </Row>
              <ProgressBar percent={calculatePercentage(EPayment, EPayment + othersDebtsData)} />
              <Row>
                <Col>
                  <SafeHTML tag="p" html={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.potentialMonthly?.yourLoan?.subtitle || 'Estimated I/O payment'} />
                </Col>
                <Col>
                  <p>{formatNumber(EPayment)}</p>
                </Col>
              </Row>
            </RowContainer>
            <RowContainer $isHidden={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.potentialMonthly?.otherDebts?.isHidden ?? false}>
              <Row>
                <Col>
                  <SafeHTML
                    tag="span"
                    html={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.potentialMonthly?.otherDebts?.title || 'Other Debts'}
                    data-testid="Other Debts"
                  />
                </Col>
                <ColPercent>
                  <p>{calculatePercentage(potentialSavings, EPayment + othersDebtsData)}%</p>
                </ColPercent>
              </Row>
              <ProgressBar percent={calculatePercentage(potentialSavings, EPayment + othersDebtsData)} barColor={'#7A59D0'} />
              <Row>
                <Col>
                  <SafeHTML tag="p" html={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.potentialMonthly?.otherDebts?.subtitle || 'Monthly payment'} />
                </Col>
                <Col>
                  <p>{formatNumber(potentialSavings)}</p>
                </Col>
              </Row>
            </RowContainer>
            <RowContainer $isHidden={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.potentialMonthly?.potentialMonthly?.isHidden ?? false}>
              <Row>
                <Col>
                  <SafeHTML tag="span" html={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.potentialMonthly?.potentialMonthly?.title || 'Potential Savings'} />
                </Col>
                <ColPercent>
                  <p>{handlePercentagePS()}%</p>
                </ColPercent>
              </Row>
              <ProgressBar percent={handlePercentagePS()} barColor={'#B3D8AD'} />
              <Row>
                <Col>
                  <SafeHTML tag="p" html={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.potentialMonthly?.potentialMonthly?.subtitle || 'Potential monthly savings'} />
                </Col>
                <ColMonthly>
                  <p>{getPotentialMonthly()}</p>
                </ColMonthly>
              </Row>
            </RowContainer>
          </ProgressContainer>
          <ChardContainer $isHidden={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.potentialMonthly?.chart?.isHidden ?? false}>
            <Chart
              loanDepot={calculatePercentage(EPayment, EPayment + othersDebtsData)}
              otherDebts={calculatePercentage(potentialSavings, EPayment + othersDebtsData)}
              potentialSavings={handlePercentagePS()}
              estimatedPayment={EPayment}
            />
          </ChardContainer>
        </WrapperContainer>
      </WrapperPotentialContainer>
    </>
  );
};
