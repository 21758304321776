import styled, { css, keyframes } from 'styled-components';

export interface AppProps {
  $loading?: boolean;
  $isResume?: boolean;
  $isCompletionPage?: boolean;
}

export const AppContainer = styled.nav<AppProps>`
  ${(props) => {
    return css`
      display: ${props.$loading || props.$isResume ? 'none' : 'flex'};
      height: 100vh;
      width: 100vw;
      flex-direction: row;
      background: ${props.theme.colors.bgWhite};
    `;
  }}
`;

export const ContentContainer = styled.nav<AppProps>`
  ${(props) => {
    return css`
      display: flex;
      flex-direction: column;
      margin-left: ${props.$isCompletionPage ? '0rem' : '23.5rem'};
      width: 100%;
      min-height: 100vh;
      @media only screen and (max-width: ${props.theme.breakpoints.xl}) {
        margin-left: 0rem;
      }
    `;
  }}
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
export const WrapperLoading = styled.div<AppProps>`
  display: ${({ $loading }) => ($loading ? 'flex' : 'none')};
  width: 100%;
  min-height: 80vh;
  justify-content: center;
  align-items: center;
`;

export const Spinner = styled.div<AppProps>`
  display: ${({ $loading }) => ($loading ? 'inline-block' : 'none')};
  width: 3.438rem;
  height: 3.438rem;
  margin-bottom: 3rem;
  border: 0.375rem solid ${({ theme }) => theme.colors.bgWhite};
  border-top: 0.375rem solid ${({ theme }) => theme.colors.bgSecondary};
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

export const WrapperResume = styled.div<AppProps>`
  display: ${({ $loading, $isResume }) => (!$loading && $isResume ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;
