import styled from 'styled-components';
import spinnerIcon from '../../../assets/images/spinner.svg?react';
import InfoIcons from '../../../assets/images/info-icons.svg?react';

export interface DefaultModalProps {
  $isOpen?: boolean;
  $isLoading?: boolean;
  $flexDirectionContent?: 'row' | 'column';
}
export const BackDrop = styled.div<DefaultModalProps>`
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1040;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  pointer-events: ${(props) => (props.$isOpen ? 'auto' : 'none')};
`;
export const Modal = styled.div<DefaultModalProps>`
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0.625rem 3.125rem rgba(0, 0, 0, 0.5);
  z-index: 1050;
  max-height: 50rem;
  min-width: 21.875rem;
  overflow-y: auto;
`;
export const ModalTitle = styled.div<DefaultModalProps>`
  font-weight: 600;
  margin-bottom: 0;
  font-size: ${(props) => (props.$isLoading ? '0.8rem' : '1.563rem')};
  display: flex;
  line-height: normal;
  font-style: normal;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  text-align: center;
  color: ${(props) => (props.$isLoading ? props.theme.colors.textDarkGrey : props.theme.colors.textPrimaryLd)};
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1.2rem;
  }
`;
export const ModalBody = styled.div`
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  flex-direction: column;
  gap: 1.563rem;
  padding: 3.125rem;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 1.125rem;
  }
`;
export const ModalContent = styled.div<DefaultModalProps>`
  font-weight: 400;
  margin-bottom: 0;
  font-size: ${(props) => (props.$isLoading ? '0.8rem' : '1.25rem')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  line-height: normal;
  font-style: normal;
  white-space: normal;
  text-align: justify;
  p div {
    flex-direction: ${(props) => props.$flexDirectionContent};
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1rem;
  }
`;
export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  max-height: 20vh;
  width: 100%;
`;

export const Spinner = styled(spinnerIcon)`
  width: 3.438rem;
  height: 3.438rem;
  margin-bottom: 3rem;
`;

export const InfoIcon = styled(InfoIcons)`
  width: 1.5rem;
  height: 1.714rem;
  margin-left: 1rem;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 3.25rem;
  }
`;
