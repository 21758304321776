import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wrapper } from '../wrapper.styles';
import TermsAmounts from '@/components/TermsAmounts/TermsAmounts';
import { NavigationButton, PageOrigin } from '@components/Button/NavigationButton/NavigationButton';
import { useAppSelector } from '@app/hooks';
import AmountCalculator from '../AmountCalculator/AmountCalculator';
import { pages } from '@/defaultVerbiages';
interface VmoProps {
  onAccess: (page: string) => void;
}
const VmoDrawAmount: React.FC<VmoProps> = ({ onAccess }) => {
  const navigate = useNavigate();
  const [isReprice, setIsReprice] = useState<boolean>(false);

  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const amountPage = 'Draw Amount';
  const drawCalculatorInfo = templateConfig?.pages?.vmo?.vmoCalculate?.vmoDrawAmount || pages?.vmo?.vmoCalculate?.vmoDrawAmount;
  const NavigationButtonText = templateConfig?.pages?.vmo?.NavigationButton || pages?.vmo?.NavigationButton;

  return (
    <>
      <Wrapper $gap="2rem">
        <TermsAmounts vmoPage={amountPage} hasDrawAmount={true} />
        <AmountCalculator info={drawCalculatorInfo} setIsReprice={setIsReprice} />
      </Wrapper>
      <NavigationButton
        variant="primary"
        nextButton={NavigationButtonText?.nextButton}
        isBackButton={NavigationButtonText?.backButton?.isHidden || false}
        backButton={NavigationButtonText?.backButton?.text}
        disabled={!isReprice}
        type="next"
        onAccess={onAccess}
        navigation={navigate}
        pageOrigin={PageOrigin.VMO_LINE_AMOUNT}
        currentPage={PageOrigin.VMO_DRAW_AMOUNT}
        destinationPage={PageOrigin.PERSONAL_INFORMATION}
      />
    </>
  );
};

export default VmoDrawAmount;
