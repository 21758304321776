import React, { useState } from 'react';
import { Container, Text, InfoIcon, Span } from './TextBlock.styles';
import SafeHTML from '@utils/safeHTML';
import { DefaultModal } from '@components/Modals/DefaultModal/DefaultModal';
import { useAppSelector } from '@app/hooks';

export enum TypeOfText {
  'H1',
  'H2',
  'H3',
  'H4',
  'Subtitle',
  'Text',
}
export interface TextBlockProps {
  text?: string;
  direction?: string;
  color?: 'primary' | 'default' | 'textPrimary';
  width?: string;
  typeModal?: string;
  isInfoModal?: boolean;
  isLinkModal?: boolean;
  type?: TypeOfText;
  infoModal?: {
    header?: string;
    body?: string;
    button?: string;
  };
  isHidden?: boolean;
  isCustomColor?: boolean;
  customColor?: string;
  infoIconColor?: string;
  isUnderline?: boolean;
  isPointer?: boolean;
  textAlign?: 'left' | 'center' | 'right' | 'justify';
}

export const TextBlock: React.FC<TextBlockProps> = ({
  text = '',
  direction = 'flex-start',
  color = 'default',
  type = TypeOfText.H1,
  width = '100%',
  isInfoModal = false,
  infoModal = {},
  isLinkModal = false,
  isHidden = false,
  isCustomColor = false,
  customColor = '#000000',
  infoIconColor,
  isUnderline = false,
  isPointer = false,
  textAlign = 'left',
}) => {
  const isReadEConcent = /%Read eConsent%/g.test(text);
  const [openModal, setOpenModal] = useState(false);
  const hasModal = isInfoModal || isLinkModal || isReadEConcent;
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const lenderName = templateConfig?.lenderName || 'Lender Name';
  const handleModal = () => {
    setOpenModal(true);
  };

  const interpolateText = (text: string) => {
    const lenderNameReplaced = text.replace(/%lenderName%|%%lenderName%%/g, lenderName);
    const linkReplaced = lenderNameReplaced.replace(/%Read eConsent%/g, '');
    return linkReplaced;
  };

  const interpolatedText = interpolateText(text);
  return (
    <Container $direction={direction} data-testid="text-block-container" width={width} $isHidden={isHidden} $isPointer={isPointer}>
      <Text
        color={color}
        type={type}
        data-testid="text-block-text"
        $isCustomColor={isCustomColor}
        $customColor={customColor}
        $isUnderline={isUnderline}
        $isLinkModal={isLinkModal}
        $textAlign={textAlign}
      >
        {isLinkModal ? (
          <SafeHTML tag="a" html={interpolatedText.replace(/\n/g, '<br/>')} onClick={handleModal} />
        ) : (
          <>
            <Span color={color} style={{ display: 'inline' }}>
              <SafeHTML tag="span" html={interpolatedText.replace(/\n/g, '<br/>')} />
            </Span>
            {isReadEConcent && (
              <Span color={color} onClick={(e) => e.stopPropagation()} style={{ display: 'inline' }}>
                <a onClick={handleModal}>Read eConsent</a>
              </Span>
            )}
          </>
        )}
      </Text>
      {isInfoModal && <InfoIcon data-testid="info-icon" onClick={handleModal} $infoIconColor={infoIconColor} />}
      {openModal && hasModal && (
        <div onClick={(e) => e.stopPropagation()}>
          <DefaultModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            infoModal={infoModal}
            data-testid="modal"
            typeModal="default"
            isErrorModal={false}
            flexDirectionContent={'column'}
          />
        </div>
      )}
    </Container>
  );
};
