import { makeGetCall } from '@/services/makeGetCall';
import { makePostCall } from '@/services/makePostCall';
import { makePatchCall } from '@/services/makePatchCall';
import { LoanApplicationState } from '@/reducers/LoanApplication.types';
import { Dispatch } from 'redux';
import { startLoading, stopLoading } from '@/reducers/currentPage';
import { generateGovernmentPayload, createBorrowerPersonalInformationPayload, createCoBorrowerPersonalInformationPayload } from './generatePayloads';

type Action = {
  actionProperties: {
    endpoint: string;
  };
};

export const handleGetStarted = async (isLoanDepot: boolean, auxLoanApplication: LoanApplicationState, dispatch: Dispatch, email?: string) => {
  try {
    dispatch(startLoading());
    const newAction: Action = {
      actionProperties: {
        endpoint: email ? `/resume/eligibilityCheck/${email}` : '/resume/eligibilityCheck/%%email%%',
      },
    };

    const response = await makeGetCall(newAction, auxLoanApplication, dispatch);
    auxLoanApplication.isReprice = false;

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const handleGetStartedAddress = async (
  isICE: boolean,
  auxLoanApplication: LoanApplicationState,
  loanApplication: LoanApplicationState,
  dispatch: any,
  templateConfig: any,
) => {
  try {
    if (isICE) {
      const endpoints = ['/loans/%%loanId%%/propertyAddress', '/equity/%%loanId%%/report'];

      const actions: Action[] = endpoints.map((endpoint) => ({
        actionProperties: { endpoint },
      }));

      const payloads = [
        {
          city: auxLoanApplication.borrowerProperty.propertyCity,
          county: auxLoanApplication.borrowerProperty.propertyCounty,
          postalCode: auxLoanApplication.borrowerProperty.propertyZip,
          state: auxLoanApplication.borrowerProperty.propertyState,
          streetAddress: auxLoanApplication.borrowerProperty.propertyStreetAddress,
          propertyOccupancyDate: auxLoanApplication.borrowerProperty.propertyOccupancyDate,
          unitType: '',
        },
        {
          propertyCity: auxLoanApplication.borrowerProperty.propertyCity,
          propertyCountry: 'United States',
          propertyZip: auxLoanApplication.borrowerProperty.propertyZip,
          propertyState: auxLoanApplication.borrowerProperty.propertyState,
          propertyStreetAddress: auxLoanApplication.borrowerProperty.propertyStreetAddress,
          configurationId: auxLoanApplication.configurationId,
          loanId: auxLoanApplication.loanId,
        },
      ];
      await makePatchCall(actions[0], auxLoanApplication, payloads[0], dispatch, templateConfig);
      await makePostCall(actions[1], auxLoanApplication, payloads[1], dispatch, templateConfig);
    } else {
      const newAction: Action = {
        actionProperties: {
          endpoint: '/file/%%loanId%%/heloc-equity',
        },
      };

      await makePatchCall(newAction, auxLoanApplication, loanApplication, dispatch, templateConfig);
    }
  } catch (error) {
    dispatch(stopLoading());
    console.error('Error calling /get-started page:', error);
    throw error;
  }
};

export const getEndpointAndPayload = (isICE: boolean, auxLoanApplication: LoanApplicationState, isReprice?: boolean): { endpoint: string; payload: any } => {
  const endpoint = isICE ? `/multi-rates/%%loanId%%/obtain` : `/file/%%loanId%%/rates`;
  const icePayload = isReprice ? { loanAmount: auxLoanApplication.loanAmount, programName: auxLoanApplication.selectedProgramName, programType: 'HEL' } : {};
  const payload = isICE ? icePayload : auxLoanApplication;

  return { endpoint, payload };
};

// Function to handle the /cltv call
export const handleCltvCall = async (isICE: boolean, loanApplication: LoanApplicationState, dispatch: Dispatch, templateConfig: any) => {
  const endpoint = isICE ? `/equity/%%loanId%%` : '/file/%%loanId%%/cltv';
  const payload = isICE
    ? {
        estimatedMarketValue: loanApplication?.estimatedMarketValue,
        remainingMortgageBalance: loanApplication?.remainingMortgageBalance,
      }
    : loanApplication;

  const cltvAction: Action = {
    actionProperties: { endpoint },
  };

  try {
    dispatch(startLoading());
    const response = await makePatchCall(cltvAction, loanApplication, payload, dispatch, templateConfig);
    dispatch(stopLoading());
    return { status: true, response: response?.payload };
  } catch (error) {
    dispatch(stopLoading());
    console.error('Error calling /cltv endpoint:', error);
    throw error;
  }
};

export const executeAction = async (isICE: boolean, auxLoanApplication: LoanApplicationState, dispatch: Dispatch, templateConfig: any, isReprice: boolean) => {
  if (!isReprice) {
    const cltvSuccess = await handleCltvCall(isICE, auxLoanApplication, dispatch, templateConfig);

    if (!cltvSuccess) {
      console.error('Skipping /rates call due to /cltv failure');
      return;
    }

    auxLoanApplication.estimatedHomeEquity = cltvSuccess.response?.estimatedHomeEquity ?? 0;
    auxLoanApplication.cltv = cltvSuccess.response?.cltv ?? 0;
  }

  const { endpoint, payload } = getEndpointAndPayload(isICE, auxLoanApplication, isReprice);
  const ratesAction: Action = {
    actionProperties: { endpoint },
  };

  try {
    if (!isReprice) dispatch(startLoading());
    if (isICE) {
      const response = await makePostCall(ratesAction, auxLoanApplication, payload, dispatch, templateConfig, isReprice);
      dispatch(stopLoading());
      return response;
    } else {
      const response = await makePatchCall(ratesAction, auxLoanApplication, payload, dispatch, templateConfig, isReprice);
      dispatch(stopLoading());
      return response;
    }
  } catch (error) {
    dispatch(stopLoading());
    console.error(`Error calling ${endpoint}:`, error);
    throw error;
  }
};

export const handleRequestVmo = async (isICE: boolean, loanApplication: LoanApplicationState, dispatch: Dispatch, templateConfig: any) => {
  const endpoint = isICE ? `/multi-rates/%%loanId%%/submit` : `/file/%%loanId%%/choose-loan-options`;
  try {
    const newAction = {
      actionProperties: {
        endpoint: endpoint,
      },
    };
    const payload = isICE
      ? { loanAmount: Number(loanApplication.selectedProgram.loanAmount), programName: loanApplication.selectedProgramName, programType: 'HEL' }
      : loanApplication;

    dispatch(startLoading());
    if (isICE) {
      await makePostCall(newAction, loanApplication, payload, dispatch, templateConfig);
    } else {
      await makePatchCall(newAction, loanApplication, loanApplication, dispatch, templateConfig);
    }
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());

    console.error('Error vmo endpoint:', error);
    throw error;
  }
};

export const handleRequestPersonalInformation = async (isICE: boolean, loanApplication: LoanApplicationState, dispatch: Dispatch, templateConfig: any) => {
  const endpoint = isICE ? `/loans/%%loanId%%/personalInfo` : `/file/%%loanId%%/fraud-check`;
  const [applicant, coApplicant] = loanApplication?.borrowers;

  const payloadICE = coApplicant
    ? {
        isTcpaAccepted: loanApplication.isTcpaAccepted,
        borrower: createBorrowerPersonalInformationPayload(applicant, loanApplication),
        coBorrower: createCoBorrowerPersonalInformationPayload(coApplicant),
      }
    : { borrower: createBorrowerPersonalInformationPayload(applicant, loanApplication), isTcpaAccepted: loanApplication.isTcpaAccepted };
  const payload = isICE ? payloadICE : loanApplication;
  try {
    const newAction = {
      actionProperties: {
        endpoint: endpoint,
      },
    };
    await makePatchCall(newAction, loanApplication, payload, dispatch, templateConfig);
  } catch (error) {
    console.error('Error personal info endpoint:', error);
    throw error;
  }
};

export const handleRequestEmploymentDetails = async (isICE: boolean, loanApplication: LoanApplicationState, dispatch: Dispatch, templateConfig: any) => {
  const endpoint = isICE ? `/loans/%%loanId%%/employment` : `/file/%%loanId%%/employment-details`;

  const borrower = loanApplication?.borrowers[0]?.employmentHistory[0];
  const coApplicant = loanApplication?.borrowers[1]?.employmentHistory[0] ?? null;
  const coBorrower = coApplicant && {
    currentEmployments: [
      {
        employer: coApplicant?.employerName,
        position: coApplicant?.jobTitle,
        startDate: coApplicant?.startDate,
        grossMonthlyIncome: coApplicant?.grossMonthlyIncome,
      },
    ],
    priorEmployments: [],
    otherIncomes: [],
  };
  const icePayload = {
    borrower: {
      currentEmployments: [
        {
          employer: borrower?.employerName,
          position: borrower?.jobTitle,
          startDate: borrower?.startDate,
          grossMonthlyIncome: borrower?.grossMonthlyIncome,
        },
      ],
      priorEmployments: [],
      otherIncomes: [],
    },
    coBorrower,
  };
  const payload = isICE ? icePayload : loanApplication;

  try {
    dispatch(startLoading());
    const newAction = {
      actionProperties: {
        endpoint: endpoint,
      },
    };
    if (isICE) {
      await makePostCall(newAction, loanApplication, payload, dispatch, templateConfig);
    } else {
      await makePatchCall(newAction, loanApplication, payload, dispatch, templateConfig);
    }
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    console.error('Error employment endpoint:', error);
    throw error;
  }
};

export const handleGovernmentInformation = async (isICE: boolean, loanApplication: LoanApplicationState, dispatch: Dispatch, templateConfig: any) => {
  const endpoint = isICE ? `/loans/%%loanId%%/govtInfo` : `/file/%%loanId%%/hmda`;
  const borrower = loanApplication?.borrowers[0].governmentHMDA;
  const coBorrower = loanApplication?.borrowers[1]?.governmentHMDA ?? '';

  const icePayload = [borrower, coBorrower];

  const hasCoBorrower = isICE && loanApplication?.borrowers?.length > 1 ? icePayload[1] : null;

  const payloadICE = generateGovernmentPayload(icePayload[0], hasCoBorrower || undefined);
  const payload = isICE ? payloadICE : loanApplication;
  try {
    dispatch(startLoading());
    const newAction = {
      actionProperties: {
        endpoint: endpoint,
      },
    };
    await makePatchCall(newAction, loanApplication, payload, dispatch, templateConfig);
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    console.error('Error government endpoint:', error);
    throw error;
  }
};

export const performStatedCreditPatch = async (loanApplication: LoanApplicationState, dispatch: Dispatch, templateConfig: any) => {
  const isSoftPullConsent = loanApplication.consent?.softPullConsent ?? false;
  const isResume = loanApplication?.isEligible ?? false;
  const hasLoanInfo = loanApplication.loanId !== '' && loanApplication.loanNumber !== '';

  const endpoint = hasLoanInfo ? `/file/${loanApplication.loanId}/stated-credit` : `/file/stated-credit`;

  const newAction: Action = {
    actionProperties: { endpoint },
  };

  try {
    dispatch(startLoading());

    if (hasLoanInfo) {
      await makePatchCall(newAction, loanApplication, loanApplication, dispatch, templateConfig);
    } else {
      if (isSoftPullConsent || isResume) {
        await makePatchCall(newAction, loanApplication, loanApplication, dispatch, templateConfig);
      } else {
        await makePostCall(newAction, loanApplication, loanApplication, dispatch, templateConfig);
      }
    }
  } catch (error) {
    console.error('Error performing stated credit patch:', error);
    throw error;
  } finally {
    dispatch(stopLoading());
  }
};

export const handleRequestDeclarations = async (isICE: boolean, loanApplication: LoanApplicationState, currentForm: any, dispatch: Dispatch, templateConfig: any) => {
  const endpoint = isICE ? `/loans/%%loanId%%/declarations` : `/file/%%loanId%%/declarations`;

  const payload = {
    ...loanApplication,
    borrowers: loanApplication.borrowers.map((borrower: any) => ({
      ...borrower,
      declaration: {
        ...borrower.declaration,
        ...currentForm,
      },
    })),
  };

  const declarationAction: Action = {
    actionProperties: { endpoint },
  };

  try {
    dispatch(startLoading());
    if (isICE) {
      await makePostCall(declarationAction, loanApplication, payload, dispatch, templateConfig);
    } else {
      await makePatchCall(declarationAction, loanApplication, payload, dispatch, templateConfig);
    }
    dispatch(stopLoading());
  } catch (error) {
    dispatch(stopLoading());
    console.error('Error calling declarations endpoint:', error);
    throw error;
  }
};
