import styled, { css } from 'styled-components';

export interface WrapperProps {
  $titleIsCustomColor?: boolean;
  $titleCustomColor?: string;
  width: string;
}

const AlignText = css`
  display: flex;
  align-items: center;
`;

const fontStyles = css`
  font-style: normal;
  line-height: normal;
`;

const ResponsiveFontStyle = css`
  font-size: 1rem;
  font-weight: 400;
`;

export const WrapperContainer = styled.div`
  flex-direction: column;
  width: 100%;
  ${AlignText};
  background: ${(props) => props.theme.colors.primary};
  border-radius: 0.688rem;
  padding: 1.563rem;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export const WrapperStepsContainer = styled.div`
  flex-direction: column;
  width: 100%;
  ${AlignText};
  margin-top: 1rem;
  background: ${(props) => props.theme.colors.bgWhite};
  border-radius: 0.688rem;
  padding: 1.563rem;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export const Verbiage = styled.div`
  padding: 1.25rem 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  div {
    justify-content: center;
    > :first-child {
      color: ${({ theme }) => theme.colors.textSecondary};
      font-family: Poppins;
      font-weight: 400;
      ${fontStyles};
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 0.75rem;
        line-height: 1.125rem;
        margin-top: 0.5rem;
        text-align: center;
      }
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    text-align: center;
  }
`;
export const VerbiagePreQual = styled.div`
  padding: 1.25rem 0 0 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  div:first-child {
    text-align: center;
    padding: 0 8rem;
    margin-bottom: 1rem;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 0;
    }
  }
  div:last-child {
    padding: 0 4rem;
    text-align: start;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 0;
    }
  }
  :first-child {
    color: ${({ theme }) => theme.colors.bgWhite};
    font-family: Poppins;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 0.75rem;
      line-height: 1.125rem;
      padding: 0 0.2rem;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    text-align: center;
  }
`;

export const Value = styled.p`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
    justify-content: end;
    color: ${({ theme }) => theme.colors.textDarkGrey};
  }
`;

export const Separator = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 0.063rem;
    background-color: ${({ theme }) => theme.colors.textLightGrey};
    width: 100%;
    margin: 1.25rem 0 0.938rem 0;
  }
`;

export const TitleContainer = styled.div<WrapperProps>`
  justify-content: center;
  width: ${({ width }) => width};
  padding-bottom: 0.938rem;
  font-weight: 700;
  p {
    font-size: 1.563rem;
  }
  h1 {
    color: ${({ theme }) => theme.colors.bgWhite};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
    padding-bottom: 0rem;
    p {
      font-size: 1rem;
    }
  }
`;

export const TitleStepsContainer = styled.div<WrapperProps>`
  justify-content: center;
  width: ${({ width }) => width};
  padding-bottom: 0.938rem;
  p {
    font-size: 1.563rem;
    font-weight: 500;
  }
  h1 {
    color: ${({ theme }) => theme.colors.textSecondary};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
    padding-bottom: 0rem;
    p {
      font-size: 1rem;
      font-weight: 500;
    }
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  p {
    margin-right: 1rem;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    p {
      font-size: 0.75rem;
    }
  }
`;
export const WrapperText = styled.div`
  margin-top: 1rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.bgLightLilac};
  padding: 0.75rem 8rem;
  text-align: center;
  margin: 2rem 0;
  :first-child {
    color: ${({ theme }) => theme.colors.primary};
    ${ResponsiveFontStyle};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0.75rem 0.5rem;
    :first-child {
      font-size: 0.75rem;
    }
  }
`;

export const WrapperButton = styled.div`
  :first-child {
    margin-top: 1rem;
    margin-bottom: 0;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    nav {
      margin-top: 1rem;
    }
  }
`;

export const LoanDetails = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;
  justify-content: center;
  div {
    display: flex;
    gap: 1rem;
    border: 1px solid ${({ theme }) => theme.colors.bgWhite};
    width: max-content;
    min-width: 20%;
    padding: 0.75rem;
    border-radius: 0.75rem;
    justify-content: center;
    background: ${({ theme }) => theme.colors.bgDetailsLd};
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      flex: 1 1 calc(50% - 1rem);
      box-sizing: border-box;
      flex-direction: column;
    }
  }
  p {
    ${AlignText};
    width: max-content;
    color: ${({ theme }) => theme.colors.bgWhite};
    font-size: 1.063rem;
    font-weight: 500;
    ${fontStyles};
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 0.8rem;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-wrap: wrap;
    :first-child {
      width: 100%;
      font-size: 0.8rem;
    }
  }
`;
