import React, { useEffect } from 'react';
import { Col, Button, ActiveIcon, CheckedIcon, Icon, Text, Label } from './RadioButton.styles';
import { useAppSelector } from '@/app/hooks';

export interface RadioButtonProps {
  value: string;
  isDisabled?: boolean;
  width?: string;
  checked?: boolean;
  checkIconColor?: string;
  checkIconBackground?: string;
  borderColorActive?: string;
  onChange?: (value: string, propertyType?: any, index?: number) => void;
  propertyType?: any;
  selectedOption: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  label?: string;
  beValue: string | boolean;
  index?: number;
  setCurrentIndex?: React.Dispatch<React.SetStateAction<number[]>>;
  currentIndex?: number[];
  isHidden?: boolean;
  border?: string;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  propertyType = 'text',
  value = '',
  checked = false,
  label = '',
  onChange,
  isDisabled = false,
  width = '20rem',
  checkIconColor = '#ffffff',
  checkIconBackground = '#5140E9',
  borderColorActive,
  selectedOption,
  setSelectedOption,
  beValue,
  index = 0,
  currentIndex,
  setCurrentIndex,
  isHidden = false,
  border = '0.063rem solid',
}) => {
  const currentForm = useAppSelector((state) => state.CurrentForm);

  const handleClick = () => {
    const validateCurrent =
      (value === 'Current' && propertyType === 'currentEmployment') || propertyType === 'currentEmploymentCoBorrower' ? currentIndex?.includes(index) : selectedOption === value;
    if (validateCurrent) {
      const newArrayIndex = currentIndex?.filter((current) => current !== index);
      if (setCurrentIndex && newArrayIndex) {
        setCurrentIndex(newArrayIndex);
      }
      setSelectedOption('');
    } else {
      setSelectedOption(value);

      if (setCurrentIndex && currentIndex) {
        setCurrentIndex([...currentIndex, index]);
      }
    }
    if (onChange) {
      onChange(beValue, propertyType, index);
    }
  };

  useEffect(() => {
    if (propertyType === 'currentEmployment' || propertyType === 'currentEmploymentCoBorrower') {
      const isCurrent = currentForm[propertyType] ? 'Current' : '';
      setSelectedOption(isCurrent);
    } else {
      setSelectedOption(currentForm[propertyType] || '');
    }
  }, [propertyType]);

  const isChecked = value === 'Current' || propertyType === 'currentEmploymentCoBorrower' ? currentIndex?.includes(index) : selectedOption === value || selectedOption === beValue;

  useEffect(() => {
    if (checked) {
      setSelectedOption(value || beValue);
    }
  }, [checked]);
  return (
    <Col width={width} $active={true} disabled={false} data-testid="radio-button" $isHidden={isHidden} $maxWidth={value === 'Current' ? '33%' : ''}>
      {label && <Label>{label}</Label>}
      <Button
        $active={selectedOption === value || selectedOption === beValue}
        disabled={isDisabled}
        onClick={handleClick}
        data-testid="button"
        $borderColorActive={borderColorActive}
        $border={border}
      >
        <Icon>
          {isChecked ? (
            <CheckedIcon data-testid="checked-icon" $checkIconColor={checkIconColor} $checkIconBackground={checkIconBackground} />
          ) : (
            <ActiveIcon data-testid="active-icon" />
          )}
        </Icon>
        <Text>{value}</Text>
      </Button>
    </Col>
  );
};
