import React from 'react';
import { LoanDetails, Separator, TitleContainer, WrapperContainer, Verbiage, Value } from './CompletionCongratulations.styles';
import { TextBlock, TypeOfText } from '../text/TextBlock';
import { useAppSelector } from '@app/hooks';
import { parseSummaryValues } from '@/utils/parseInputValue';
interface CompletionCongratulationsPros {
  width?: string;
  isSubtitle?: boolean;
}

const CompletionCongratulations: React.FC<CompletionCongratulationsPros> = ({ width = '100%', isSubtitle = false }) => {
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let loanApplication = useAppSelector((state) => state.LoanApplication);

  const infoCompletion = templateConfig?.pages?.completion;

  return (
    <WrapperContainer>
      <TitleContainer width={width} data-testid="completion-congratulation">
        <TextBlock text={infoCompletion?.title?.text || 'Prequalification Terms'} direction={'start'} type={TypeOfText.H1} isHidden={infoCompletion?.title?.isHidden || false} />
      </TitleContainer>
      {isSubtitle && (
        <TitleContainer width={width}>
          <TextBlock text={infoCompletion?.subTitle?.text || ''} direction={'start'} color="primary" type={TypeOfText.H1} isHidden={infoCompletion?.subTitle?.isHidden || false} />
        </TitleContainer>
      )}
      <Separator />
      <LoanDetails>
        {infoCompletion?.loanDetails.map(
          (loan, index) =>
            !loan.isHidden && (
              <div key={index}>
                <p>{loan.title}</p>
                <Value>{parseSummaryValues(loan.value, loanApplication)}</Value>
              </div>
            ),
        )}
      </LoanDetails>
      <Verbiage>
        <TextBlock text={infoCompletion?.verbiage?.text || ''} type={TypeOfText.Text} isHidden={infoCompletion?.verbiage?.isHidden || false} />
      </Verbiage>
    </WrapperContainer>
  );
};

export default CompletionCongratulations;
