import React from 'react';
import {
  LoanDetails,
  TitleContainer,
  WrapperContainer,
  Verbiage,
  Value,
  StepsContainer,
  WrapperButton,
  VerbiagePreQual,
  WrapperStepsContainer,
  TitleStepsContainer,
  WrapperText,
} from './CompletionV2.styles';
import { TextBlock, TypeOfText } from '../text/TextBlock';
import { useAppSelector } from '@app/hooks';
import { parseSummaryValues } from '@/utils/parseInputValue';
import { Button } from '../Button/DefaultButton/Button';
import SafeHTML from '@/utils/safeHTML';
import { pages } from '@/defaultVerbiages';
interface CompletionCongratulationsPros {
  width?: string;
}

const CompletionV2: React.FC<CompletionCongratulationsPros> = ({ width = '100%' }) => {
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  const infoCompletion = templateConfig?.pages?.completion || pages?.completion;

  const ldCompletionValues = {
    completion: {
      title: {
        text: 'Prequalification Terms',
        isHidden: false,
      },
      loanDetails: [
        {
          title: 'Loan number',
          value: '%%loanNumber%%',
          isHidden: false,
        },
        {
          title: 'Line Amount',
          value: '%%lineAmount%%',
          isHidden: false,
        },
        {
          title: 'Initial Disbursement',
          value: '%%drawAmount%%',
          isHidden: false,
        },
        {
          title: 'Initial Rate and APR*',
          value: '%%apr%%',
          isHidden: false,
        },
      ],
      verbiage: {
        text: '*Quoted interest rate is the iniitial interest rate for your HELOC. Please note that the interest rate does not include costs other than interest rate and will vary over the life of your loan. Rate and terms quoted here are subject to credit and collateral approval.',
        isHidden: false,
      },
      verbiageTwo: {
        text: 'To be eligible for the quoted rate, a credit union membership may be required. loanDepot will still be your HELOC lender and we or one of our service providers will service your loan. The selected credit union will fund an account in your name and will provide you membership disclosures and account statements, but we will not ask you to make any further financial commitment. If you choose to opt out of credit union membership, the interest rate may be up to 3 percentage points higher.',
        isHidden: false,
      },
      nextSteps: {
        title: {
          text: 'Thank you for applying. You are only 4 steps away from getting access to your funds!',
          isHidden: false,
        },
        subTitle: {
          text: 'Thank you for applying. You are only 4 steps away from getting access to your funds!',
          isHidden: false,
        },
        verbiage: {
          text: "<br><b><span style='color: #008000;'> &#10003; </span> STEP 1: E-sign your Disclosures </b> in the Borrower Portal.<br><br><b> <span style='color: #008000;'> &#10003; </span> STEP 2: To-Do Tasks. </b><br><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#x25CF; Finish any outstanding Tasks on your Borrower Portal.</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#x25CF; ID Verification: You will receive a text from loanDepot with a secure link that allows us to verify your identity. We take security seriously, and verifying your selfie and government-issued ID helps us confirm and protect your identity.</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#x25CF; Proxy Pics: You will receive a text from loanDepot with a secure link that allows you to take a few photos of the outside of your home. Once you download the App, you will need to allow Proxy Pics to access your location so it can locate your property. Remember all pictures must be taken in the app for security reasons.</p><br> <b> <span style='color: #008000;'> &#10003; </span> STEP 3:</b> <span style='color: #008000;'>Green Light!</span> Once you have provided the necessary information and documentation, now the loanDepot Team can review your file and determine final approval.<br><br> <b> <span style='color: #008000;'> &#10003; </span> STEP 4: Closing.</b> We are now ready to set your closing date.<br><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#x25CF; A Closing Agent will be in touch with you to determine the time and location of your closing.</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#x25CF; Your initial draw amount will be made available to you in your preferred account, along with instructions on setting up your online HELOC portal for subsequent draws and payments.</p>",
          isHidden: false,
        },
        description: {
          text: 'We sent a temporary password to the email address you provided in your application.',
          isHidden: false,
        },
        button: {
          text: 'Go To My Portal!',
          redirectTo: 'https://www.example.com',
          isHidden: true,
        },
        contact: {
          text: 'Please log into the secure portal by clicking on the link sent to the email address provided in the application. Once logged in, you can provide, review and sign documents directly from the portal.',
          isHidden: false,
        },
        verbiageLd: {
          textOne: {
            text: 'Start bringing your dreams to life…… It’s really that easy!',
            isHidden: false,
          },
          textTwo: {
            text: 'If you have any questions regarding your application, please call us at (866) 790-3940',
            isHidden: false,
          },
        },
        isButtonPortal: false,
      },
    },
  };

  return (
    <>
      <WrapperContainer>
        <TitleContainer width={width} data-testid="completion-congratulation">
          <TextBlock
            text={ldCompletionValues?.completion?.title?.text}
            direction={'center'}
            type={TypeOfText.H1}
            isHidden={ldCompletionValues?.completion?.title?.isHidden || false}
          />
        </TitleContainer>
        <LoanDetails>
          {infoCompletion?.loanDetails.map(
            (loan, index) =>
              !loan.isHidden && (
                <div key={index}>
                  <p>{`${loan.title}:`}</p>
                  <Value>{parseSummaryValues(loan.value, loanApplication)}</Value>
                </div>
              ),
          )}
        </LoanDetails>
        <VerbiagePreQual>
          <TextBlock
            text={ldCompletionValues?.completion?.verbiage?.text || pages?.completion?.verbiage?.text}
            type={TypeOfText.Text}
            direction={'center'}
            isHidden={ldCompletionValues?.completion?.verbiage?.isHidden || false}
          />
          <TextBlock
            text={ldCompletionValues?.completion?.verbiageTwo?.text || pages?.completion?.verbiageTwo?.text}
            type={TypeOfText.H3}
            direction={'start'}
            isHidden={ldCompletionValues?.completion?.verbiageTwo?.isHidden || false}
          />
        </VerbiagePreQual>
      </WrapperContainer>
      <WrapperStepsContainer>
        <TitleStepsContainer width={width} data-testid="completion-congratulation">
          <TextBlock
            text={ldCompletionValues?.completion?.nextSteps?.title?.text || pages?.completion?.nextSteps?.title?.text}
            direction={'center'}
            type={TypeOfText.H1}
            isHidden={ldCompletionValues?.completion?.nextSteps?.title?.isHidden || false}
          />
        </TitleStepsContainer>
        <StepsContainer>
          <SafeHTML tag="span" html={ldCompletionValues?.completion?.nextSteps?.verbiage?.text || pages?.completion?.nextSteps?.verbiage?.text} />
        </StepsContainer>
        {ldCompletionValues?.completion?.nextSteps?.isButtonPortal ? (
          <WrapperButton>
            <Button label={ldCompletionValues?.completion?.nextSteps?.button?.text || pages?.completion?.nextSteps?.button?.text} variant="primary" onClick={() => {}} />
          </WrapperButton>
        ) : (
          <WrapperText>
            <TextBlock
              text={ldCompletionValues?.completion?.nextSteps?.contact?.text || pages?.completion?.nextSteps?.contact?.text}
              direction={'start'}
              type={TypeOfText.Text}
              isHidden={ldCompletionValues?.completion?.nextSteps?.contact?.isHidden || false}
            />
          </WrapperText>
        )}
        <Verbiage>
          <TextBlock
            text={ldCompletionValues?.completion?.nextSteps?.verbiageLd?.textOne?.text || pages?.completion?.nextSteps?.verbiageLd?.textOne?.text}
            type={TypeOfText.Subtitle}
            isHidden={ldCompletionValues?.completion?.nextSteps?.verbiageLd?.textOne?.isHidden || false}
          />
          <TextBlock
            text={ldCompletionValues?.completion?.nextSteps?.verbiageLd?.textTwo?.text || pages?.completion?.nextSteps?.verbiageLd?.textTwo?.text}
            type={TypeOfText.Text}
            isHidden={ldCompletionValues?.completion?.nextSteps?.verbiageLd?.textTwo?.isHidden || false}
          />
        </Verbiage>
      </WrapperStepsContainer>
    </>
  );
};

export default CompletionV2;
