import React, { useState, useEffect } from 'react';
import { NewRelicWrapper, ButtonWrapper } from './NewRelicConsent.styles';
import { TextBlock } from '@/components/text/TextBlock';
import { TypeOfText } from '@/components/text/TextBlock';
import { Button } from '@components/Button/DefaultButton/Button';
import { useAppSelector } from '@/app/hooks';
import { pages } from '@/defaultVerbiages';

type NewRelicConsentProps = {
  onConsent: (value: boolean) => void;
};

const NewRelicConsent: React.FC<NewRelicConsentProps> = ({ onConsent }) => {
  const [openModal, setOpenModal] = useState(false);
  const templateConfig = useAppSelector((state) => state.TemplateConfig);

  const isHidden = templateConfig?.pages?.features?.fullStory?.isHidden ?? true;

  useEffect(() => {
    const savedConsent = localStorage.getItem('newRelicConsent');
    if (!savedConsent) {
      setOpenModal(true);
    }
  }, []);

  const updateConsentValue = (value: boolean) => {
    localStorage.setItem('newRelicConsent', value.toString());
    onConsent(value);
    setOpenModal(false);
  };

  if (isHidden || !openModal) return null;

  const { title = pages.features.fullStory.title, body = pages.features.fullStory.body } = templateConfig?.pages?.features?.fullStory || {};
  const declineLabel = templateConfig?.pages?.features?.fullStory?.buttonDecline || pages.features.fullStory.buttonDecline;
  const acceptLabel = templateConfig?.pages?.features?.fullStory?.buttonAccept || pages.features.fullStory.buttonAccept;

  return (
    <NewRelicWrapper>
      <TextBlock text={title} direction="center" type={TypeOfText.H2} />
      <TextBlock text={body} type={TypeOfText.Subtitle} />
      <ButtonWrapper>
        <Button label={declineLabel} onClick={() => updateConsentValue(false)} variant="secondary" buttonSize="l" />
        <Button label={acceptLabel} onClick={() => updateConsentValue(true)} variant="primary" buttonSize="l" />
      </ButtonWrapper>
    </NewRelicWrapper>
  );
};

export default NewRelicConsent;
