import React, { useState, useEffect } from 'react';
import { Wrapper, FormGroup, TitleContainer } from '../wrapper.styles';
import { TextInput } from '../../components/input/TextInput/TextInput';
import { TextBlock } from '@components/text/TextBlock';
import { NavigationButton, PageOrigin } from '@components/Button/NavigationButton/NavigationButton';
import { DropDownInput } from '@components/input/DropDownInput/DropDownInput';
import { pages } from '../../defaultVerbiages';
import { RadioButton } from '@components/input/RadioButton/RadioButton';
import { AddressAutoComplete } from '@components/input/AddressAutoComplete/AddressAutoComplete';
import DataPicker from '@components/input/DatePicker/DatePicker';
import { CheckConsent } from '@components/input/CheckConsent/CheckConsent';
import { useAppSelector, useAppDispatch } from '@app/hooks';
import { FormGroupComponent } from '@components/input/FormGroup/FormGroup';
import { PhoneInput } from '@/components/input/PhoneInput/PhoneInput';
import { TypeOfText } from '@components/text/TextBlock';
import { ModalSubmit } from '@/components/Modals/ModalSubmit/ModalSubmit';
import { PropertyType } from '@/types';

interface GetStartedProps {
  onAccess: (page: string) => void;
}

const GetStarted: React.FC<GetStartedProps> = ({ onAccess }) => {
  const dispatch = useAppDispatch();
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const { openSoftPull, isResume } = useAppSelector((state) => state.currentPage);
  const {
    firstName,
    eConsent,
    middleName,
    lastName,
    suffix,
    email,
    purposeOfLoan,
    suit,
    city,
    primaryUse,
    state,
    zip,
    dataOccupancyDate,
    propertyType,
    mailingCity,
    mailingState,
    mailingZip,
    address,
    mobile,
    home,
    work,
  } = useAppSelector((state) => state.CurrentForm);

  const [isMailingAddressSame, setIsMailingAddressSame] = useState(true);
  const [selectedOption, setSelectedOption] = React.useState('');
  const [selectedPrimaryUse, setSelectedPrimaryUse] = React.useState('');
  const [formError, setFormError] = React.useState(false);
  const { propertyStreetAddress, propertyStreetAddress2 } = loanApplication?.borrowerProperty || {};
  const [formValues, setFormValues] = useState({
    firstName: firstName || '',
    middleName: middleName || '',
    lastName: lastName || '',
    suffix: suffix || '',
    email: email || '',
    purposeOfLoan: purposeOfLoan || '',
    address: propertyStreetAddress || address || '',
    suit: propertyStreetAddress2 || '',
    city: city || '',
    state: state || '',
    zip: zip || '',
    mailingSuit: propertyStreetAddress2 || '',
    mailingCity: mailingCity || '',
    mailingState: mailingState || '',
    mailingZip: mailingZip || '',
    primaryUse: primaryUse || '',
    propertyType: propertyType || '',
    dataOccupancyDate: dataOccupancyDate || '',
    mobile: mobile || '',
    home: home || '',
    work: work || '',
  });
  const [isErrorForm, setIsErrorForm] = useState(false);
  const isICE = templateConfig.isICE ?? false;

  useEffect(() => {
    if (templateConfig && Object.keys(templateConfig).length && templateConfig?.lenderId) {
      const vmoRedesign = templateConfig.features?.vmoRedesign.isEnabled;
      const payload = {
        lenderId: templateConfig?.lenderId,
        configurationId: templateConfig?.configurationId,
        ...(vmoRedesign && { features: { vmoRedesign: true } }),
      };
      dispatch({
        type: 'LoanApplication/updateLoanApplication',
        payload: payload,
      });
    }
  }, [templateConfig, dispatch]);

  useEffect(() => {
    const requiredValues = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      purposeOfLoan: formValues.purposeOfLoan,
      PrimaryUse: formValues.primaryUse,
      PropertyType: formValues.propertyType,
      address: formValues.address,
      city: formValues.city,
      state: formValues.state,
      zip: formValues.zip,
      dataOccupancyDate: formValues.dataOccupancyDate,
    };

    const allFieldsFilled = Object.values(requiredValues).every((value) => (typeof value === 'boolean' ? value : value.trim() !== ''));

    setFormError(allFieldsFilled);
  }, [formValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const isUnitType = templateConfig?.pages?.LetsGetStarted?.consumerInformation?.unitType?.isHidden ?? false;

  const handleChange = (value: string | number | boolean, name: string) => {
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
    let obj: any = {};
    obj[name] = value;
    dispatch({ type: 'CurrentForm/addField', payload: { ...formValues, [name]: value } });
  };

  useEffect(() => {
    const { propertyCity, propertyState, propertyStreetAddress, propertyZip, propertyStreetAddress2 } = loanApplication?.borrowerProperty || {};
    setFormValues((prevValues) => ({
      ...prevValues,
      city: propertyCity || prevValues.city,
      state: propertyState || prevValues.state,
      address: propertyStreetAddress || prevValues.address,
      zip: propertyZip || prevValues.zip,
      suit: propertyStreetAddress2 || prevValues.suit,
    }));
  }, [loanApplication]);

  const handleMailingAddressCheckbox = (checked: boolean, type: string) => {
    if (type !== 'mailing') {
      handleChange(checked, type);
    } else {
      setIsMailingAddressSame(checked);
    }
  };

  const isNavigationButtonDisabled = !formError || !eConsent || !isErrorForm;

  useEffect(() => {
    setFormValues({
      firstName: firstName || '',
      middleName: middleName || '',
      lastName: lastName || '',
      suffix: suffix || '',
      email: email || '',
      purposeOfLoan: purposeOfLoan || '',
      address: propertyStreetAddress || address || '',
      suit: suit || '',
      city: city || '',
      state: state || '',
      zip: zip || '',
      mailingSuit: propertyStreetAddress2 || '',
      mailingCity: mailingCity || '',
      mailingState: mailingState || '',
      mailingZip: mailingZip || '',
      primaryUse: primaryUse ? primaryUse : '',
      propertyType: propertyType || '',
      dataOccupancyDate: dataOccupancyDate || '',
      mobile: mobile || '',
      home: home || '',
      work: work || '',
    });
    setSelectedPrimaryUse(propertyType);
    setSelectedOption(primaryUse);
  }, [isResume]);

  return (
    <>
      <TitleContainer data-testid="title-container">
        <TextBlock
          text={templateConfig?.pages?.LetsGetStarted?.pageContainer?.title?.title || pages.LetsGetStarted.pageContainer.title.title}
          direction={'center'}
          color="primary"
          type={TypeOfText.H1}
          isHidden={templateConfig?.pages?.LetsGetStarted?.pageContainer?.title?.isHidden ?? false}
        />
        <TextBlock
          text={templateConfig?.pages?.LetsGetStarted?.pageContainer?.subtitle?.title || pages.LetsGetStarted.pageContainer.subtitle.title}
          direction={'center'}
          color="default"
          type={TypeOfText.Subtitle}
          isHidden={templateConfig?.pages?.LetsGetStarted?.pageContainer?.subtitle?.isHidden ?? false}
        />
      </TitleContainer>
      <Wrapper $gap="0.6rem">
        <TextBlock
          text={templateConfig?.pages?.LetsGetStarted?.typeOfInformation?.consumerInformation?.text || pages.LetsGetStarted.typeOfInformation.consumerInformation.text}
          direction={'flex-start'}
          color="default"
          type={TypeOfText.H2}
          isHidden={templateConfig?.pages?.LetsGetStarted?.typeOfInformation?.consumerInformation?.isHidden ?? false}
        />
        <FormGroupComponent onChange={handleChange}>
          <TextBlock
            text={templateConfig?.pages?.LetsGetStarted?.question?.basicInformation?.text || pages.LetsGetStarted.question.basicInformation.text}
            direction={'flex-start'}
            color="default"
            type={TypeOfText.H3}
            isHidden={templateConfig?.pages?.LetsGetStarted?.question?.basicInformation?.isHidden ?? false}
          />
          <TextInput
            propertyType={PropertyType.FirstName}
            placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.firstName?.text || pages.LetsGetStarted.consumerInformation.firstName.text}
            typeOfIcon="text"
            dataTestId="firstName"
            size={'32%'}
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.firstName?.isHidden ?? false}
            value={firstName || ''}
          />
          <TextInput
            propertyType={PropertyType.MiddleName}
            placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.middleName?.text || pages.LetsGetStarted.consumerInformation.middleName.text}
            typeOfIcon="text"
            size={'20%'}
            dataTestId="middleName"
            isRequired={false}
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.middleName?.isHidden ?? false}
            value={middleName || ''}
          />
          <TextInput
            propertyType={PropertyType.LastName}
            placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.lastName?.text || pages.LetsGetStarted.consumerInformation.lastName.text}
            typeOfIcon="text"
            size={'32%'}
            dataTestId="lastName"
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.lastName?.isHidden ?? false}
            value={lastName || ''}
          />
          <DropDownInput
            dataTestId="suffix"
            propertyType={PropertyType.Suffix}
            placeHolder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.suffix?.title || pages.LetsGetStarted.consumerInformation.suffix.title}
            options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.suffix?.options}
            isRequired={false}
            size={'10%'}
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.suffix?.isHidden ?? false}
            capitalizeWord={false}
          />
          <TextInput
            propertyType={PropertyType.Email}
            placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.email?.text || pages.LetsGetStarted.consumerInformation.email.text}
            size={'50%'}
            dataTestId="email"
            typeOfIcon="email"
            type="email"
            setIsErrorForm={setIsErrorForm}
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.email?.isHidden ?? false}
            value={email || ''}
          />
          <PhoneInput
            propertyType={PropertyType.Phone}
            placeHolder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.titleInput || pages.LetsGetStarted.consumerInformation.phone.titleInput}
            dataTestId="phone"
            label={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.titleDropDown || pages.LetsGetStarted.consumerInformation.phone.titleDropDown}
            value={loanApplication?.borrowers[0].borrowerPrimaryPhone || ''}
            size={'30%'}
            type="phone"
            options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.options}
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.isHidden ?? false}
          />
          <DropDownInput
            propertyType={PropertyType.PurposeOfLoan}
            placeHolder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.purposeOfLoan?.text || pages.LetsGetStarted.consumerInformation.purposeOfLoan.text}
            dataTestId="purposeOfLoan"
            options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.purposeOfLoan?.options}
            value={loanApplication?.borrowers[0].borrowerLoanPurpose || ''}
            size={'30%'}
            typeOfIcon="dollar"
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.purposeOfLoan?.isHidden ?? false}
          />
        </FormGroupComponent>
        <TextBlock
          text={templateConfig?.pages?.LetsGetStarted?.protectInformation?.text || pages.LetsGetStarted.protectInformation.text}
          direction={'center'}
          color="primary"
          type={TypeOfText.H4}
          isLinkModal={true}
          infoModal={templateConfig?.pages?.LetsGetStarted?.modals?.protectInformation || pages.LetsGetStarted.modals.protectInformation}
          isHidden={templateConfig?.pages?.LetsGetStarted?.protectInformation?.isHidden ?? false}
        />
      </Wrapper>
      <Wrapper $gap="0.6rem">
        <TextBlock
          text={templateConfig?.pages?.LetsGetStarted?.typeOfInformation?.PropertyInformation?.text || pages.LetsGetStarted.typeOfInformation.PropertyInformation.text}
          direction={'flex-start'}
          color="default"
          type={TypeOfText.H2}
          isHidden={templateConfig?.pages?.LetsGetStarted?.typeOfInformation?.PropertyInformation?.isHidden ?? false}
        />
        <FormGroupComponent onChange={handleChange} data-testid="address-form">
          <TextBlock
            text={templateConfig?.pages?.LetsGetStarted?.question?.addressProperty?.text || pages.LetsGetStarted.question.addressProperty.text}
            direction={'flex-start'}
            color="default"
            type={TypeOfText.H3}
            isInfoModal={!!templateConfig?.pages?.LetsGetStarted?.question?.addressProperty?.infoModal}
            infoModal={templateConfig?.pages?.LetsGetStarted?.question?.addressProperty?.infoModal || {}}
            isHidden={templateConfig?.pages?.LetsGetStarted?.question?.addressProperty?.isHidden ?? false}
          />
          <AddressAutoComplete size="60%" onChange={handleChange} typeOfIcon="address" propertyType={PropertyType.Address} />
          <TextInput
            propertyType={PropertyType.Suit}
            placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.suit?.text || pages.LetsGetStarted.consumerInformation.suit.text}
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.suit?.isHidden ?? false}
            size="20%"
            dataTestId="suit"
            value={loanApplication?.borrowerProperty?.propertyStreetAddress2}
            isRequired={false}
          />
          <TextInput
            propertyType={PropertyType.City}
            placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.city?.text || pages.LetsGetStarted.consumerInformation.city.text}
            value={loanApplication?.borrowerProperty?.propertyCity}
            size="40%"
            dataTestId="city"
            setIsErrorForm={setIsErrorForm}
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.city?.isHidden ?? false}
          />
          <DropDownInput
            propertyType={PropertyType.State}
            placeHolder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.state?.text || pages.LetsGetStarted.consumerInformation.state.text}
            options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.state?.options}
            value={loanApplication?.borrowerProperty?.propertyState}
            size="5%"
            dataTestId="state"
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.state?.isHidden ?? false}
            capitalizeWord={false}
          />
          <TextInput
            propertyType={PropertyType.Zip}
            value={loanApplication?.borrowerProperty?.propertyZip}
            placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.zip?.text || pages.LetsGetStarted.consumerInformation.zip.text}
            size="20%"
            dataTestId="zip"
            setIsErrorForm={setIsErrorForm}
            isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.zip?.isHidden ?? false}
          />
        </FormGroupComponent>
        <CheckConsent
          type="mailing"
          text={templateConfig?.pages?.LetsGetStarted?.propertyMailingAddress?.checkText || pages.LetsGetStarted.propertyMailingAddress.checkText}
          direction="center"
          checked={true}
          onChange={handleMailingAddressCheckbox}
          isHidden={templateConfig?.pages?.LetsGetStarted?.propertyMailingAddress?.isHidden ?? false}
        />
        {!isMailingAddressSame && (
          <>
            <TextBlock
              text={templateConfig?.pages?.LetsGetStarted?.propertyMailingAddress?.information || pages.LetsGetStarted.propertyMailingAddress.information}
              direction={'flex-start'}
              color="default"
              type={TypeOfText.H3}
            />
            <FormGroupComponent onChange={handleChange} data-testid="address-form">
              <AddressAutoComplete size="60%" typeOfIcon="address" isMailingAddress={true} />
              <TextInput
                propertyType={PropertyType.Suit}
                placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.suit?.text || pages.LetsGetStarted.consumerInformation.suit.text}
                size="20%"
                dataTestId="mailingSuit"
              />
              {!isUnitType ? (
                <DropDownInput
                  propertyType={PropertyType.MailingSuit}
                  placeHolder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.unitType?.text || pages?.LetsGetStarted?.consumerInformation?.unitType?.text}
                  options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.unitType?.options}
                  size="12%"
                  dataTestId="mailingUnitType"
                  isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.unitType?.isHidden ?? false}
                />
              ) : (
                <></>
              )}
              <TextInput
                propertyType={PropertyType.MailingCity}
                placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.city?.text || pages?.LetsGetStarted?.consumerInformation?.city?.text}
                value={loanApplication?.mailingAddress?.mailingCity}
                size="40%"
                dataTestId="mailingCity"
                isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.city?.isHidden ?? false}
              />
              <DropDownInput
                propertyType={PropertyType.MailingState}
                placeHolder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.state?.text || pages?.LetsGetStarted?.consumerInformation?.state?.text}
                options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.state?.options}
                value={loanApplication?.mailingAddress?.mailingState}
                size="12%"
                dataTestId="mailingState"
                isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.state?.isHidden ?? false}
              />
              <TextInput
                propertyType={PropertyType.MailingZip}
                value={loanApplication?.mailingAddress?.mailingZip}
                placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.zip?.text || pages?.LetsGetStarted?.consumerInformation?.zip?.text}
                size="20%"
                dataTestId="mailingZip"
                isHidden={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.zip?.isHidden ?? false}
              />
            </FormGroupComponent>
          </>
        )}
        <FormGroup>
          <TextBlock
            text={templateConfig?.pages?.LetsGetStarted?.question?.useOfProperty?.text || pages?.LetsGetStarted?.question?.useOfProperty?.text}
            direction={'flex-start'}
            color="default"
            type={TypeOfText.H3}
            isHidden={templateConfig?.pages?.LetsGetStarted?.question?.useOfProperty?.isHidden ?? false}
          />
          {templateConfig?.pages?.LetsGetStarted?.consumerInformation?.radioButton?.propertyUse.map(
            (propertyUseValue, index) =>
              !propertyUseValue.isHidden && (
                <RadioButton
                  onChange={handleChange}
                  propertyType={PropertyType.PrimaryUse}
                  key={index}
                  value={propertyUseValue?.text}
                  beValue={isICE ? propertyUseValue.beValue : propertyUseValue.beValue}
                  width={propertyUseValue?.width}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  checked={selectedOption === propertyUseValue.text}
                />
              ),
          )}
          <TextBlock
            text={templateConfig?.pages?.LetsGetStarted?.question?.propertyType?.text || pages?.LetsGetStarted?.question?.propertyType?.text}
            direction={'flex-start'}
            color="default"
            type={TypeOfText.H3}
            isInfoModal={!!templateConfig?.pages?.LetsGetStarted?.question?.propertyType?.infoModal}
            infoModal={templateConfig?.pages?.LetsGetStarted?.question?.propertyType?.infoModal || {}}
            isHidden={templateConfig?.pages?.LetsGetStarted?.question?.propertyType?.isHidden ?? false}
          />
        </FormGroup>
        <FormGroup>
          {templateConfig?.pages?.LetsGetStarted?.consumerInformation?.radioButton?.propertyType.map(
            (propertyTypeValue, index) =>
              !propertyTypeValue.isHidden && (
                <RadioButton
                  onChange={handleChange}
                  propertyType={PropertyType.PropertyType}
                  key={index}
                  value={propertyTypeValue.text}
                  beValue={isICE ? propertyTypeValue.beValue : propertyTypeValue.text}
                  width={propertyTypeValue.width}
                  selectedOption={selectedPrimaryUse}
                  setSelectedOption={setSelectedPrimaryUse}
                  checked={selectedPrimaryUse === propertyTypeValue.text}
                />
              ),
          )}
        </FormGroup>
        <FormGroup>
          <TextBlock
            text={templateConfig?.pages?.LetsGetStarted?.question?.purchaseDate?.text || pages?.LetsGetStarted?.question?.purchaseDate?.text}
            direction={'flex-start'}
            color="default"
            type={TypeOfText.H3}
            isInfoModal={!!templateConfig?.pages?.LetsGetStarted?.question?.purchaseDate?.infoModal}
            infoModal={templateConfig?.pages?.LetsGetStarted?.question?.purchaseDate?.infoModal || {}}
            isHidden={templateConfig?.pages?.LetsGetStarted?.question?.purchaseDate?.isHidden ?? false}
          />
        </FormGroup>
        <FormGroup>
          <DataPicker
            propertyType={PropertyType.DataOccupancyDate}
            onChange={handleChange}
            dataTestId="occupancyDate"
            propertyName={'propertyOccupancyDate'}
            placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.purchaseDate?.text || pages?.LetsGetStarted?.consumerInformation?.purchaseDate?.text}
            typeOfIcon="date"
            setIsErrorForm={setIsErrorForm}
            isHidden={templateConfig?.pages?.LetsGetStarted?.question?.purchaseDate?.isHidden ?? false}
          />
        </FormGroup>
      </Wrapper>
      <Wrapper $gap="1rem">
        <TextBlock
          text={templateConfig?.pages?.LetsGetStarted?.typeOfInformation?.consent?.text || pages?.LetsGetStarted?.typeOfInformation?.consent?.text}
          isHidden={templateConfig?.pages?.LetsGetStarted?.typeOfInformation?.consent?.isHidden ?? false}
          direction={'flex-start'}
          color="default"
          type={TypeOfText.H2}
        />
        <CheckConsent
          type={'softPullConsent'}
          text={templateConfig?.pages?.LetsGetStarted?.softPull?.text || pages?.LetsGetStarted?.softPull?.text}
          isInfoModal={!templateConfig?.pages?.LetsGetStarted?.softPull?.isHidden}
          infoModal={templateConfig?.pages?.LetsGetStarted?.modals?.infoSoftPull || pages?.LetsGetStarted?.modals?.infoSoftPull}
          direction={'center'}
          onChange={handleMailingAddressCheckbox}
          isHidden={templateConfig?.pages?.LetsGetStarted?.softPull?.isHidden ?? false}
          isPointer
        />
        <CheckConsent
          type={'eConsent'}
          text={templateConfig?.pages?.LetsGetStarted?.eConsent?.text || pages?.LetsGetStarted?.eConsent?.text}
          direction={'center'}
          isInfoModal={templateConfig?.pages?.LetsGetStarted?.modals?.infoEconsent?.isInfoModal || false}
          infoModal={templateConfig?.pages?.LetsGetStarted?.modals?.infoEconsent || pages?.LetsGetStarted?.modals?.infoEconsent}
          onChange={handleMailingAddressCheckbox}
          isHidden={templateConfig?.pages?.LetsGetStarted?.eConsent?.isHidden ?? false}
          isPointer
        />
      </Wrapper>
      <NavigationButton
        variant="primary"
        nextButton={templateConfig?.pages?.LetsGetStarted?.NavigationButton?.nextButton || 'Next'}
        backButton={templateConfig?.pages?.LetsGetStarted?.NavigationButton?.backButton?.text || 'Back'}
        isBackButton={templateConfig?.pages?.LetsGetStarted?.NavigationButton?.backButton?.isHidden ?? false}
        disabled={isNavigationButtonDisabled}
        onAccess={onAccess}
        currentPage={PageOrigin.GET_STARTED}
        type="submit"
      />
      {openSoftPull && (
        <ModalSubmit
          openModal={openSoftPull}
          setOpenModal={() => {}}
          infoSubmitModal={templateConfig?.pages?.LetsGetStarted?.modals?.infoSubmitModal || pages?.LetsGetStarted?.modals?.infoSubmitModal}
          onAccess={onAccess}
        />
      )}
    </>
  );
};

export default GetStarted;
