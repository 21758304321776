import React, { Fragment, useState, useCallback } from 'react';
import {
  CardBody,
  WrapperContainer,
  TitleContainer,
  CardHeader,
  CardSection,
  WrapperCard,
  Col,
  ContainerInfoIcon,
  InfoIcon,
  BodyTitle,
  InformationContainer,
  CardDrawLine,
  BodySubTitle,
} from './AmountCalculator.styles';
import { HandleModal } from '@/utils/handleInfoIcon';
import { getSeparator } from '@/utils/getSeparator';
import { useIsMobile } from '@/hooks/useIsMobile';
import RefreshableCurrencyInput from '@/components/RefreshableCurrencyInput/RefreshableCurrencyInput';
import SafeHTML from '@/utils/safeHTML';
import { DefaultModal } from '@/components/Modals/DefaultModal/DefaultModal';
import { TextBlock } from '@/components/text/TextBlock';
import { TypeOfText } from '@/components/text/TextBlock';
import { useAppSelector } from '@app/hooks';
import { handleParseDisplay } from '@/utils/handleParseLimitAmount';
import { handleShowValue } from '@utils/handleShowVmoValues';
import { formatNumber } from '@/utils/formatValues';
import { pages } from '@/defaultVerbiages';

export interface TermsSection {
  titleContainer: {
    isHidden: boolean;
    text: string;
  };
  title: {
    isHidden: boolean;
    text: string;
  };
  subtitle: {
    isHidden: boolean;
    text: string;
  };
  amountTerms: AmountTerm[];
  rangeAmount: {
    isHidden: boolean;
    text: string;
  };
  footer: {
    isHidden: boolean;
    text: string;
  };
}

interface Section {
  barCustomBackground?: string;
  isBarCustomBackground?: boolean;
  titleIsCustomColor?: boolean;
  titleCustomColor?: string;
  barTitleIsCustomColor?: boolean;
  barTitleCustomColor?: string;
  info: TermsSection;
  setIsReprice: React.Dispatch<React.SetStateAction<boolean>>;
  isReprice?: boolean;
}

interface AmountTerm {
  [key: string]: {
    title: string;
    value: string;
    isHidden: boolean;
  };
}

const AmountCalculator: React.FC<Section> = ({
  info,
  barCustomBackground = '#3A149E',
  isBarCustomBackground = false,
  titleIsCustomColor = false,
  titleCustomColor = '#3A149E',
  barTitleIsCustomColor = false,
  barTitleCustomColor = '#FFFFFF',
  setIsReprice,
  isReprice,
}) => {
  const [infoIcon, setInfoIcon] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const isMobile = useIsMobile(690);
  const terms: AmountTerm[] = info?.amountTerms || [];
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let LoanApplication = useAppSelector((state) => state.LoanApplication);
  const isICE = templateConfig.isICE ?? false;
  const containDrawLineTitles = ['INITIAL DRAW', 'LINE AMOUNT', 'LOAN AMOUNT'];
  const amountParseDisplay = handleParseDisplay(info?.rangeAmount?.text, LoanApplication);
  const isLineDraw = (title: string) => containDrawLineTitles.includes(title);

  const handleModal = useCallback((label: string) => {
    const handle = HandleModal(label, templateConfig);
    const infoModal = {
      body: LoanApplication.minLineAmount > 49000 ? handle.bodyHigh : handle.bodyLow,
      button: handle.button,
    };
    setInfoIcon(infoModal);
    setOpenModal(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getFormattedValue = (title: string, valueAmount?: number) => {
    switch (title) {
      case 'ESTIMATED APR':
        return `${valueAmount}%`;

      case 'MINIMUM DRAW':
        return formatNumber(Number(LoanApplication.selectedProgram.minLineAmount));

      case 'MAXIMUM DRAW':
        return formatNumber(Number(LoanApplication.selectedProgram.maxDrawAmount));

      case 'EST MONTHLY PMT':
        return formatNumber(Number(LoanApplication.selectedProgram.estimatedMonthlyPayment));

      case 'LINE AMOUNT':
        return `$${formatNumber(Number(LoanApplication?.minLineAmount))}`;

      case 'LOAN AMOUNT':
        return isICE ? `$${formatNumber(Number(LoanApplication.selectedProgram.minLoanAmount))}` : `$${Math.round(valueAmount ?? 0)}`;

      default:
        return `$${Math.round(valueAmount ?? 0)}`;
    }
  };

  return (
    <>
      <WrapperContainer>
        <TitleContainer $titleIsCustomColor={titleIsCustomColor} $titleCustomColor={titleCustomColor}>
          <TextBlock
            text={templateConfig?.pages?.vmo?.vmoCalculate?.title?.text || pages.vmo.vmoCalculate.title.text}
            type={TypeOfText.Subtitle}
            isHidden={templateConfig?.pages?.vmo?.vmoCalculate?.title?.isHidden ?? false}
          />
        </TitleContainer>
        <WrapperCard data-testid="amount-card-container">
          <CardHeader
            $isBarCustomBackground={isBarCustomBackground}
            $barCustomBackground={barCustomBackground}
            $barTitleIsCustomColor={barTitleIsCustomColor}
            $barTitleCustomColor={barTitleCustomColor}
            $isHidden={info?.titleContainer?.isHidden ?? false}
          >
            <p>{isICE ? LoanApplication?.selectedProgram?.programName : info?.titleContainer?.text || 'Initial Draw Amount at Closing'}</p>
          </CardHeader>
          <BodyTitle $isHidden={info?.title?.isHidden ?? false}>
            <SafeHTML tag="p" html={info?.title?.text || 'Enter your desired Initial Draw amount (funds disbursed to you immediately at closing.)'} />
          </BodyTitle>
          <BodySubTitle $isHidden={info?.subtitle?.isHidden ?? true}>
            <SafeHTML tag="p" html={info?.subtitle?.text || 'Enter your desired Initial Draw amount (funds disbursed to you immediately at closing.)'} />
          </BodySubTitle>
          <CardBody>
            {terms.map((section, index) => {
              return (
                <Fragment key={index}>
                  {Object.entries(section).map(([key, value], entryIndex) => {
                    const isLineDrawAmount = isLineDraw(value.title);
                    const valueAmount = (LoanApplication.selectedProgram as any)[handleShowValue(value)];
                    const isDrawAmount = value.title === 'INITIAL DRAW';

                    return (
                      !value.isHidden && (
                        <Fragment key={key}>
                          {isLineDrawAmount ? (
                            <CardDrawLine>
                              <Col $isLineDrawAmount={isLineDrawAmount} $isDrawAmount={isDrawAmount}>
                                <ContainerInfoIcon $isLineDrawAmount={isLineDrawAmount} $isDrawAmount={isDrawAmount}>
                                  <p>{value.title}</p>
                                  <InfoIcon data-testid="info-icon" onClick={() => handleModal(value.title || '')} />
                                </ContainerInfoIcon>
                                <RefreshableCurrencyInput
                                  limitAmount={amountParseDisplay}
                                  typeOfAmount={value.title}
                                  auxMinValue={Number(LoanApplication?.selectedProgram?.minLineAmount)}
                                  auxMaxValue={Number(LoanApplication?.selectedProgram?.maxLineAmount)}
                                  setIsReprice={setIsReprice}
                                  isReprice={isReprice}
                                />
                              </Col>
                            </CardDrawLine>
                          ) : (
                            <CardSection>
                              <Col>
                                <ContainerInfoIcon>
                                  <p>{value.title}</p>
                                  <InfoIcon data-testid="info-icon" onClick={() => handleModal(value.title || '')} />
                                </ContainerInfoIcon>
                                <h5>{isReprice && getFormattedValue(value.title, valueAmount)}</h5>
                              </Col>
                              {isMobile && getSeparator(entryIndex, Object.keys(section).length, false)}
                            </CardSection>
                          )}
                          {!isMobile && getSeparator(entryIndex, Object.keys(section).length, false)}
                        </Fragment>
                      )
                    );
                  })}
                </Fragment>
              );
            })}
          </CardBody>
          {openModal && <DefaultModal openModal={openModal} setOpenModal={setOpenModal} infoModal={infoIcon} />}
        </WrapperCard>
        <InformationContainer $isHidden={info?.footer?.isHidden ?? false}>
          <SafeHTML tag="p" html={info?.footer?.text}></SafeHTML>
        </InformationContainer>
      </WrapperContainer>
    </>
  );
};
export default AmountCalculator;
