import React, { useState, ChangeEvent, useCallback, useEffect } from 'react';
import { Container, Label, Input, ErrorIcon, ContainerInfoIcon, InfoIcon, ErrorMessage, InputContainer, IconContainer, IconEyeContainer } from './TextInput.styled';
import * as yup from 'yup';
import { formatCurrency, formatCodeInput } from '@/utils/formatValues';
import { DefaultModal } from '@/components/Modals/DefaultModal/DefaultModal';
import { useAppSelector } from '@app/hooks';
import { formatSSNInput } from '@/utils/formatValues';
import { handleIcons } from '@/utils/handleIcons';
import { useIsMobile } from '@/hooks/useIsMobile';

type IconType = 'text' | 'email' | 'employment' | 'dollar';

export interface TextInputProps {
  dataTestId?: string;
  label?: string;
  placeholder?: string;
  value?: string | number;
  isRequired?: boolean;
  type?: 'text' | 'password' | 'email' | 'phone' | 'number';
  isDisabled?: boolean;
  errorMessage?: string;
  size?: string;
  isCentered?: boolean;
  getInputProps?: (props: any) => any;
  min?: number;
  max?: number;
  step?: number;
  onChange?: (value: string | number, propertyType?: any, index?: number) => void;
  propertyType?: any;
  typeOfIcon?: IconType;
  iconType?: IconType;
  isAddressIcon?: boolean;
  isHidden?: boolean;
  iconColor?: string;
  isInfoModal?: boolean;
  hasAsterisk?: boolean;
  maxAmount?: number;
  minAmount?: number;
  handleValidation?: (value: ChangeEvent<HTMLInputElement>, propertyType?: any) => void;
  propertyName?: string;
  handleChangeText?: (value: string, propertyType?: any) => void;
  parseDisplayValue?: (value: string, propertyType?: any) => void;
  setIsErrorForm?: React.Dispatch<React.SetStateAction<boolean>>;
  index?: number;
  padding?: string;
  validationMessage?: string;
}
export const TextInput: React.FC<TextInputProps> = ({
  dataTestId,
  propertyType = 'text',
  min,
  max,
  step = 1,
  onChange,
  label = '',
  placeholder = '',
  value = '',
  isRequired = true,
  type = 'text',
  isCentered = false,
  isDisabled = false,
  errorMessage,
  size = '15rem',
  getInputProps,
  typeOfIcon,
  isAddressIcon = false,
  isHidden = false,
  iconColor = '#5140E9',
  isInfoModal = false,
  hasAsterisk = false,
  maxAmount = 0,
  minAmount = 0,
  handleValidation,
  propertyName,
  handleChangeText,
  parseDisplayValue,
  setIsErrorForm,
  index,
  padding,
  validationMessage = '',
}) => {
  const currentForm = useAppSelector((state) => state.CurrentForm);
  const currentPage = useAppSelector((state) => state.currentPage);
  const [focused, setFocused] = useState(false);
  const [inputError, setInputError] = useState(errorMessage);
  const [displayValue, setDisplayValue] = useState(value);
  const [, setInputDisabled] = useState(isDisabled);
  const [maxLength, setMaxLength] = useState<number | undefined>(undefined);
  const [openModal, setOpenModal] = useState(false);
  const isSSN = propertyType === 'SSN' || propertyType === 'SSNCoBorrower';
  const isCodeInput = propertyType === 'code-input';
  const validPropertyTypes = ['estimate', 'type', 'remaining', 'equity', 'amount', 'Line Amount', 'Draw Amount'];
  const isEstimatedEquity = validPropertyTypes.includes(propertyType);
  const isMonthlyIncome = propertyType === 'monthly' || propertyType === 'monthlyCoBorrower' || propertyType === 'monthlyIncome';
  const onBlur = useCallback(() => {}, []);
  const IconComponent = typeOfIcon ? handleIcons(typeOfIcon) : null;
  const hasIcon = isAddressIcon || IconComponent !== null;
  const isAmountType = propertyType === 'Line Amount' || propertyType === 'Draw Amount';
  const isEmployment = currentPage?.value === '/employment-information';
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let loanApplication = useAppSelector((state) => state.LoanApplication);

  const formSchema = yup.object({
    email: yup
      .string()
      .email()
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),
    amount: yup.number().min(minAmount).max(maxAmount),
  });
  const [typeInput, setTypeInput] = useState(type);
  const IconPassword = handleIcons(typeInput === 'text' ? 'eyeSlash' : 'eye');

  const isMobile = useIsMobile();
  const handleFocus = () => {
    setFocused(true);
    setInputError('');
    if (setIsErrorForm) setIsErrorForm(false);
  };

  useEffect(() => {
    const inputValue = isAmountType || isEmployment ? value : currentForm[propertyType] || value;
    setDisplayValue(inputValue);
  }, [propertyType, value]);

  const handleBlur = async (e: ChangeEvent<HTMLInputElement>) => {
    const event = e;
    if (!event || !event.target) {
      return;
    }
    if (propertyType === 'estimate') setInputDisabled(true);
    if (handleValidation) handleValidation(event, propertyType);
    setFocused(false);
    if (type === 'email' && event.target.value) {
      const isValid = await formSchema.isValid({ [type]: event.target.value.toString() });
      if (setIsErrorForm) {
        setIsErrorForm(isValid);
      }

      let errorMessage = isValid ? undefined : 'You must provide a valid email address';
      if (propertyType === 'emailCoborrower') {
        const isEqualToBorrower = event.target.value === loanApplication?.borrowers[0]?.borrowerEmailAddress;
        errorMessage = isEqualToBorrower ? `The email must be different from the borrower's` : errorMessage;
      }
      setInputError(errorMessage);
    } else if (isRequired && !event.target.value && !isCodeInput) {
      const invalidInput = type === 'email' ? 'email address' : isSSN ? 'SSN' : placeholder;
      if (setIsErrorForm && type !== 'email') {
        setIsErrorForm(false);
      }
      setInputError(validationMessage || `You must provide a ${invalidInput}`);
    } else if (isSSN && propertyType === 'SSNCoBorrower') {
      const isEqualToBorrower = event.target.value === loanApplication?.borrowers[0]?.borrowerSSN;
      isEqualToBorrower && setInputError(`The SSN must be different from the borrower's`);
    } else if (propertyType === 'amount') {
      const isAmountValid = parseInt(event.target.value.toString().replace(/[^0-9]/g, ''), 10);
      const isValid = await formSchema.isValid({ amount: isAmountValid });
      const message = isMobile ? 'not valid' : `the amount must be between ${formatCurrency(minAmount)} and ${formatCurrency(maxAmount)}.`;
      const errorMessage = isValid ? undefined : message;
      setInputError(errorMessage);
    } else if (isMonthlyIncome) {
      if (value === '$0') {
        const errorMessage = validationMessage || `You must provide a ${propertyType}`;
        setInputError(errorMessage);
      }
    } else {
      setInputError(undefined);
      if (setIsErrorForm && type !== 'email') {
        setIsErrorForm(true);
      }
    }
    if (onBlur) onBlur();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const event = e;
    const { value } = event.target;

    let valueToDisplay = value;
    if (isEstimatedEquity || isMonthlyIncome) {
      const inputValue = event.target.value.replace(/[\$,]/g, '');
      const numericValue = parseFloat(inputValue) || 0;
      valueToDisplay = formatCurrency(numericValue);
    }
    if (isCodeInput) {
      setMaxLength(6);
      valueToDisplay = formatCodeInput(valueToDisplay);
    }
    if (isSSN) {
      const show = true;
      setMaxLength(11);
      const inputValue = event.target.value.replace(/[^0-9]/g, '');
      const convertSSN = !show ? inputValue.replace(/\d/g, 'X') : inputValue;
      valueToDisplay = formatSSNInput(convertSSN);
    }
    if (onChange) {
      onChange(value, propertyType, index);
    }
    if (handleChangeText) {
      handleChangeText(value, propertyName);
    }
    if (parseDisplayValue) {
      parseDisplayValue(value, propertyName);
    }
    setDisplayValue(valueToDisplay);
  };

  const handleModal = () => {
    setOpenModal(true);
  };
  return (
    <Container size={size} $isHidden={isHidden} $hasAsterisk={hasAsterisk}>
      {label && (
        <Label htmlFor={dataTestId} $hasAsterisk={hasAsterisk}>
          {label}
          {isInfoModal && (
            <ContainerInfoIcon>
              <InfoIcon data-testid="info-icon" onClick={handleModal} />
            </ContainerInfoIcon>
          )}
        </Label>
      )}
      <InputContainer $hasError={!!inputError} $focused={focused} size={size} $isEstimatedEquity={isEstimatedEquity} $isCentered={isCentered}>
        {typeOfIcon && <IconContainer $iconColor={iconColor}>{IconComponent}</IconContainer>}
        <Input
          data-testid={dataTestId}
          type={typeInput}
          placeholder={placeholder}
          value={displayValue}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={isDisabled}
          size={size}
          $hasIcon={hasIcon}
          maxLength={maxLength}
          $isCentered={isCentered}
          $padding={padding}
          step={step}
          min={min}
          max={max}
          {...(getInputProps ? getInputProps({}) : {})}
        />
        {type === 'password' && (
          <IconEyeContainer
            onClick={() => {
              if (typeInput === 'password') {
                setTypeInput('text');
              } else {
                setTypeInput('password');
              }
            }}
            $iconColor={'iconColor'}
          >
            {IconPassword}
          </IconEyeContainer>
        )}
      </InputContainer>
      {inputError && (
        <ErrorMessage data-testid="error-input">
          <ErrorIcon /> {inputError}
        </ErrorMessage>
      )}
      {openModal && (
        <DefaultModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          infoModal={templateConfig?.pages?.LetsGetStarted?.modals?.exampleModal}
          data-testid="modal"
          typeModal="default"
        />
      )}
    </Container>
  );
};
