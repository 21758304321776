import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

export interface CurrentPageState {
  value: string;
  step: number;
  isGoingBack: boolean;
  isLoading: boolean;
  completedSteps: number[];
  isResume: boolean;
  openSoftPull: boolean;
  isOpenDebtConsolidation: boolean;
}

const initialState = {
  value: '',
  step: 0,
  isGoingBack: false,
  isLoading: false,
  completedSteps: [],
  isResume: true,
  openSoftPull: false,
  isOpenDebtConsolidation: false,
} as CurrentPageState;

export const CurrentPageSlice = createSlice({
  name: 'CurrentPage',
  initialState,
  reducers: {
    addCurrentPage: (state, action) => {
      state.value = action.payload;
    },
    incrementStep: (state) => {
      if (!state.isGoingBack) {
        state.step += 1;
      }
      state.isGoingBack = false;
    },
    decrementStep: (state) => {
      state.step -= 1;
      state.isGoingBack = true;
    },
    resetGoingBack: (state) => {
      state.isGoingBack = false;
    },
    resetCompletedSteps: (state) => {
      state.completedSteps = [];
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setIsResume: (state, action) => {
      state.isResume = action.payload;
    },
    setOpenSoftPull: (state, action) => {
      state.openSoftPull = action.payload;
    },
    setIsOpenDebtConsolidation: (state, action) => {
      state.isOpenDebtConsolidation = action.payload;
    },
  },
});

export const { addCurrentPage, incrementStep, decrementStep, resetGoingBack, startLoading, stopLoading, setIsResume, setOpenSoftPull, setIsOpenDebtConsolidation } =
  CurrentPageSlice.actions;
export const selectCurrentPage = (state: RootState) => state.currentPage.value;
export const selectCurrentStep = (state: RootState) => state.currentPage.step;
export const selectIsGoingBack = (state: RootState) => state.currentPage.isGoingBack;
export const selectIsLoading = (state: RootState) => state.currentPage.isLoading;
export default CurrentPageSlice.reducer;
