import React, { useState, useEffect } from 'react';
import { Button } from '@components/Button/DefaultButton/Button';
import { Modal, ModalTitle, ModalBody, ModalContent, ModalFooter, BackDrop } from './ConfirmModal.style';
import { handleNext } from '@helper/navigationHelpers';
import { useAppSelector, useAppDispatch } from '@/app/hooks';
import { makeGetSummaryCall } from '@/services/makeGetSummaryCall';
import { makeGetCall } from '@/services/makeGetCall';
import { makePostCall } from '@/services/makePostCall';
import { startLoading, stopLoading } from '@/reducers/currentPage';
import { DefaultModal } from '../DefaultModal/DefaultModal';
import { handleErrors } from '@/utils/handleErrors';
import SafeHTML from '@/utils/safeHTML';
import { InfoIcon } from '../DefaultModal/DefaultModal.styles';

export interface ConfirmationModalProps {
  openModal: boolean;
  setOpenModal: (isOpen: boolean) => void;
  isLoading?: boolean;
  onAccess?: (page: string) => void;
  navigation?: (page: any) => void;
  isSummaryPage?: boolean;
  handleModalClose?: (showDebtConsolidation: boolean) => void;
  infoModal?: {
    type?: string;
    header?: string;
    body?: string;
    button?: string;
    acceptButton?: string;
    link?: string;
  };
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  openModal,
  setOpenModal,
  isLoading = false,
  infoModal,
  onAccess,
  isSummaryPage = false,
  navigation,
  handleModalClose,
}) => {
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const [error, setError] = useState<string | unknown>('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  //TODO: refactor the values when we have all the integrated data
  const isICE = templateConfig?.isICE ?? false;
  const dispatch = useAppDispatch();
  const isCreditUnion = infoModal?.type === 'credit union';
  const endpoints = {
    summaryHasCoborrower: `/file/decision/%%loanId%%/hasCoborrower`,

    summaryCreditDecision: `/file/decision/%%loanId%%/creditDecision`,

    summaryCreditPull: `/file/decision/%%loanId%%/creditPull`,

    summaryValidateDecision: `/file/decision/%%loanId%%/validateDecision`,

    submitApplication: `/loans/%%loanId%%/submitApplication`,

    submitStatus: `/loans/%%loanId%%/application-submittal-status`,
  };

  useEffect(() => {
    if (error) {
      setError(error);
      setOpenErrorModal(true);
    }
  }, [error, setError]);

  const handleRequestSummary = async () => {
    const createAction = (endpoint: string) => ({
      actionProperties: { endpoint },
    });
    let hasCoBorrower = loanApplication?.borrowers.length > 1;
    try {
      const hasCoborrowerAction = createAction(endpoints.summaryHasCoborrower);
      await makeGetCall(hasCoborrowerAction, loanApplication, dispatch);
    } catch (error) {
      handleErrors(error);
      throw error;
    }
    let updatedPayload;
    try {
      dispatch(startLoading());
      const endpoint = hasCoBorrower ? endpoints.summaryCreditPull : endpoints.summaryCreditDecision;
      const creditPullAction = createAction(endpoint);
      updatedPayload = await makeGetCall(creditPullAction, loanApplication, dispatch);
    } catch (error) {
      handleErrors(error);
      throw error;
    }
    if (hasCoBorrower) {
      try {
        dispatch(startLoading());
        const endpoint = endpoints.summaryCreditDecision;
        const creditPullAction = createAction(endpoint);
        updatedPayload = await makeGetCall(creditPullAction, loanApplication, dispatch);
      } catch (error) {
        handleErrors(error);
        throw error;
      }
    }

    try {
      dispatch(startLoading());
      const softPullAction = createAction(endpoints.summaryValidateDecision);
      await makePostCall(softPullAction, loanApplication, { ...updatedPayload.data }, dispatch, templateConfig);
    } catch (error) {
      handleErrors(error);
      throw error;
    }
  };

  const handleRequestSummaryNoICE = async () => {
    const createAction = (endpoint: string) => ({
      actionProperties: { endpoint },
    });

    const executeAction = async (actionName: string, endpoint: string) => {
      try {
        const payload = { hasCoborrower: loanApplication?.borrowers.length > 1 };
        const action = createAction(endpoint);

        if (actionName === 'submitApplicationAction') {
          await makePostCall(action, loanApplication, payload, dispatch, templateConfig, false);
        } else {
          await makeGetSummaryCall(action, loanApplication, templateConfig);
        }
      } catch (error) {
        handleErrors(error);
        throw error;
      }
    };
    await executeAction('submitApplicationAction', endpoints.submitApplication);
    await executeAction('submitStatusAction', endpoints.submitStatus);
  };

  const closeModal = () => {
    setOpenModal(false);
    if (handleModalClose) {
      handleModalClose(false);
    }
  };
  const onClick = async () => {
    if (infoModal?.link) {
      window.open(infoModal.link, '_blank');
    }
    if (handleModalClose) {
      handleModalClose(true);
    }
    setOpenModal(false);

    if (!isSummaryPage || !onAccess || !navigation) {
      return;
    }

    try {
      dispatch(startLoading());
      if (!isICE) {
        await handleRequestSummary();
      } else {
        await handleRequestSummaryNoICE();
      }
      dispatch(stopLoading());
    } catch (error) {
      dispatch(stopLoading());
      setError(error);
      console.error('Error during request summary:', error);
      throw error;
    }

    if (!error) handleNext(navigation, onAccess, 'inquiry-summary', '/completion');
  };

  return (
    <>
      <BackDrop $isOpen={openModal} onClick={closeModal} data-testid="modal-backdrop" />
      {openModal && (
        <Modal $isLoading={isLoading} $isOpen={openModal}>
          <ModalBody>
            {isCreditUnion && <InfoIcon />}
            <ModalTitle>{infoModal?.header}</ModalTitle>
            <ModalContent>
              <SafeHTML tag="p" html={infoModal?.body || ''} />
            </ModalContent>
            <ModalFooter>
              <Button label={infoModal?.acceptButton || 'Accept'} onClick={onClick} variant={isCreditUnion ? 'secondary' : 'primary'} buttonSize="l" />
              <Button label={infoModal?.button || 'Decline'} onClick={closeModal} variant="secondary" buttonSize="l" />
            </ModalFooter>
          </ModalBody>
        </Modal>
      )}
      {openErrorModal && error && (
        <DefaultModal openModal={openErrorModal} setOpenModal={setOpenErrorModal} isErrorModal={true} infoModal={{ body: error, button: 'Return to %%lenderName%%' }} />
      )}
    </>
  );
};
