import styled, { css } from 'styled-components';
import ActiveSvg from '../../assets/images/circle-default.svg?react';
import ArrowDownIcon from '../../assets/images/arrow-down.svg?react';
import CheckedSvg from '../../assets/images/circle-checked.svg?react';
import PointSvg from '../../assets/images/point.svg?react';
import DivisorSvg from '../../assets/images/divisor.svg?react';
import InfoIcons from '../../assets/images/info-icons.svg?react';

interface AvailableProgramsProps {
  $backgroundColor?: string;
  $textColorProgramName?: string;
  $textColorVerbiage?: string;
  $isOpen?: boolean;
  $isBackgroundCustomColor?: boolean;
  $isVerbiageCustomColor?: boolean;
  $isTextCustomColor?: boolean;
  type?: string;
  name?: string;
  $line_amount?: string;
  $estimated_apr?: string;
  $min_line_amount?: string;
  $max_line_amount?: string;
  $verbiage?: string;
  $isVerbiageCustomBackground?: boolean;
  $backgroundColorVerbiage?: string;
  $titleIsCustomColor?: boolean;
  $titleCustomColor?: string;
  $isHidden?: boolean;
}

const IconStyles = css`
  width: 1.094rem;
  margin-right: 1rem;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 0.563rem;
    height: 0.625rem;
    margin-right: 0;
    margin-left: 0.5rem;
  }
`;
const ResponsiveFont = css`
  font-family: Poppins;
  font-style: normal;
  font-feature-settings:
    'liga' off,
    'clig' off;
`;

const FlexContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BaseContainer = css`
  width: 100%;
`;

const PointDivisorIcon = css`
  margin: 0 0.5rem;
  path {
    width: 1.25rem;
  }
  .circle-color {
    fill: ${({ theme }) => theme.colors.borderColor};
  }
`;
export const PointIcon = styled(PointSvg)`
  ${PointDivisorIcon};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 0.2rem;
    width: 0.2rem;
  }
`;

export const DivisorIcon = styled(DivisorSvg)`
  ${PointDivisorIcon};
  height: 3rem;
`;

const RadioButton = css`
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-right: 0.5rem;
    width: 0.7rem;
    height: 0.8rem;
  }
`;

export const TitleContainer = styled.div<AvailableProgramsProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  padding: 1.563rem 0;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 1.25rem 0 1.563rem 0;
  }
  :first-child {
    ${ResponsiveFont};
    font-size: 1.313rem;
    font-weight: 400;
    line-height: normal;
    color: ${({ theme, $titleIsCustomColor, $titleCustomColor }) => ($titleIsCustomColor ? $titleCustomColor : theme.colors.bgSecondary)};
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 1rem;
      letter-spacing: -0.01rem;
    }
  }
`;

export const CheckedIcon = styled(CheckedSvg)`
  ${RadioButton};
  fill: ${({ theme }) => theme.colors.bgGreen};
  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
  .circle-color {
    fill: ${({ theme }) => theme.colors.bgWhite};
  }
`;

export const ActiveIcon = styled(ActiveSvg)`
  ${RadioButton};
  path {
    width: 1.25rem;
  }
  .circle-color {
    fill: ${({ theme }) => theme.colors.borderColor};
  }
`;
export const DownIcon = styled(ArrowDownIcon)<AvailableProgramsProps>`
  ${IconStyles};
  height: 1.25rem;
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  margin-left: 1rem;
  path {
    stroke: ${({ theme }) => theme.colors.bgWhite};
  }
`;

export const InfoIcon = styled(InfoIcons)<AvailableProgramsProps>`
  width: 1.5rem;
  height: 1.313rem;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-left: 0.3rem;
    width: 0.7rem;
    height: 0.7rem;
  }
`;

export const ContainerInfoIcon = styled.div<AvailableProgramsProps>`
  height: 50%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    gap: 0rem;
  }
`;

const Containers = css`
  ${BaseContainer};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const WrapperContainer = styled.div`
  ${Containers};
`;

export const ContainerPrograms = styled.div`
  ${Containers};
  margin-bottom: 2.813rem;
`;
export const SelectorContainer = styled.div<AvailableProgramsProps>`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: 2.813rem;
  background-color: ${({ theme, $backgroundColor, $isBackgroundCustomColor }) => ($isBackgroundCustomColor ? $backgroundColor : theme.colors.bgSecondary)};
  border-radius: 0.356rem 0.356rem 0 0;
  padding: 0.688rem 1rem;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: 90%;
    width: 96%;
    padding: 0.356rem 0.518rem;
  }
`;

export const Program = styled.div<AvailableProgramsProps>`
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
  margin: 0;
  color: ${({ theme }) => theme.colors.bgWhite};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 0.68rem;
    line-height: 0.571rem;
    width: 70%;
  }
`;

export const ProgramName = styled.div<AvailableProgramsProps>`
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.563rem;
  margin: 0;
  padding-left: 0.5rem;
  color: ${({ theme, $isTextCustomColor, $textColorProgramName }) => ($isTextCustomColor ? $textColorProgramName : theme.colors.bgWhite)};
  @media (max-width: 500px) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Verbiage = styled.div<AvailableProgramsProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 1rem;
  font-family: Poppins;
  font-size: 0.938rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: ${({ theme, $isVerbiageCustomColor, $textColorVerbiage }) => ($isVerbiageCustomColor ? $textColorVerbiage : theme.colors.bgWhite)};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 0.486rem;
    line-height: 0.571rem;
    width: 30%;
  }
`;

export const InitialProgramName = styled.span<AvailableProgramsProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  font-size: 1.313rem;
  font-weight: 600;
  line-height: 1.563rem;
  margin-right: 0.5rem;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-size: 0.65rem;
    line-height: 0.571rem;
    padding-left: 0rem;
    margin-right: 0.3rem;
    height: max-content;
  }
`;

export const Footer = styled.div<AvailableProgramsProps>`
  ${FlexContainer};
  background-color: ${({ theme }) => theme.colors.bgMediumGrey};
  border-radius: 0 0 0.356rem 0.356rem;
  padding: 0.8rem 1rem;
  margin: 0;
  border: 0.063rem solid rgba(15, 27, 47, 0.15);
  border-top: none;
  ${BaseContainer};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0 0.05rem;
  }
`;

export const FooterNote = styled.div<AvailableProgramsProps>`
  ${FlexContainer};
  border-radius: 0 0 0.356rem 0.356rem;
  padding: 0.8rem 1rem;
  margin: 0;
  ${BaseContainer};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0 0.05rem;
    h1 {
      font-size: 0.5rem;
    }
  }
`;

export const FooterDescription = styled.div<AvailableProgramsProps>`
  ${ResponsiveFont};
  font-size: 1.313rem;
  font-weight: 300;
  line-height: 1.563rem;
  display: ${({ $isHidden }) => ($isHidden ? 'none' : '')};
  margin: 0;
  color: ${({ theme }) => theme.colors.bgGrey};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 0.55rem;
    line-height: 0.81rem;
  }
`;

export const DescriptionContainer = styled.div`
  padding: 0.625rem 0;
  ${FlexContainer};
  flex-direction: column;
  border: 0.063rem solid rgba(15, 27, 47, 0.15);
  border-top: none;
  gap: 0.625rem;
  ${BaseContainer};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0.75rem 0.5rem;
  }
  h1 {
    ${ResponsiveFont};
    font-size: 1.313rem;
    font-weight: 300;
    line-height: 1.563rem;
    text-align: center;
    display: flex;
    width: 90%;
    justify-content: center;
    flex-shrink: 0;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 0.68rem;
      line-height: 0.714rem;
      width: 100%;
    }
  }
`;

export const ContainerIcon = styled.div`
  height: 80%;
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    align-items: center;
  }
`;

export const VerbiageContainer = styled.div<AvailableProgramsProps>`
  background: ${({ theme, $isVerbiageCustomBackground, $backgroundColorVerbiage }) => ($isVerbiageCustomBackground ? $backgroundColorVerbiage : theme.colors.bgSecondary)};
  border-radius: 0 0 0.356rem 0.356rem;
  position: relative;
  overflow: hidden;
  transition: 0.5s ease-in-out;
  width: 100%;
  h1 {
    padding: 2rem 4rem;
    color: ${({ theme }) => theme.colors.bgWhite};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    h1 {
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.127rem;
      padding: 1rem 2rem;
    }
  }
`;

export const DetailsContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 96%;
  }
`;

const FontStyles = css`
  line-height: normal;
  font-style: normal;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
    justify-content: center;
    gap: 0rem;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.875rem;
  h5 {
    ${FontStyles};
    text-align: center;
    font-family: Poppins;
    font-size: 1.875rem;
    font-weight: 500;
    margin: 0;
    color: ${({ theme }) => theme.colors.textDarkGrey};
    width: 100%;
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      font-size: 0.972rem;
      padding: 0;
    }
  }
  p {
    font-family: 'Nunito';
    ${FontStyles};
    font-size: 0.813rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.textGrey};
    width: 100%;
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      font-size: 0.421rem;
    }
  }
`;
