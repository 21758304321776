import React, { useState, useRef, useEffect, useCallback } from 'react';
import { WrapperContentCard, CarouselWrapper, NavButton, CarouselContainer, ArrowLeftIcon, ArrowRightIcon } from './ProgramCard.styles';

import { useAppDispatch, useAppSelector } from '@app/hooks';
import { DefaultModal } from '@/components/Modals/DefaultModal/DefaultModal';
import { ProgramCardItem } from './ProgramCardItem';
import { handleProgramSelection } from '@/utils/handleVmo';

interface ProgramCardProps {
  isReprice: boolean;
  setIsCreditUnion?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedProgram: React.Dispatch<React.SetStateAction<number[]>>;
  selectedProgram: number[];
  acceptCreditUnion: boolean;
  isCreditUnion: boolean;
}

interface IModalInfoType {
  header?: string;
  bodyLow?: string;
  bodyHigh?: string;
  body?: string;
  button: string;
}

interface Rates {
  programName: string;
  programType: string;
  description: string;
  customVerbiage: string;
  apr: number;
  lineAmount: string;
  minLineAmount: string;
  maxLineAmount: string;
  term: string;
  estimatedMonthlyPayment: string;
  maxAmountApproved: number;
  type: string;
  name: string;
  estimatedApr: string;
  estimateMonthlyPmt: string;
  originationFee: string;
  verbiage: string;
  interest: string;
  labelEstimatedAPR: string;
  subLabelEstimatedApr: string;
  isInfoIconEstimatedApr: boolean;
  labelEstimatedMonth: string;
  subBLabelEstimatedMonth: string;
  isInfoIconEstimatedMonth: boolean;
  labelOriginalFee: string;
  subLabelOriginalFee: string;
  isInfoIconOriginalFee: boolean;
  labelInterest: string;
  subLabelInterest: string;
  isInfoIconInterest: boolean;
}

const ProgramCard: React.FC<ProgramCardProps> = ({ isReprice, setIsCreditUnion, setSelectedProgram, selectedProgram, acceptCreditUnion, isCreditUnion }) => {
  const [modalInfo, setModalInfo] = useState<null | IModalInfoType>(null);
  const [disableLeftButton, setDisableLeftButton] = useState(true);
  const [disableRightButton, setDisableRightButton] = useState(false);
  const carouselRef = useRef<HTMLDivElement>(null);
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  const typeOfProgram = loanApplication?.selectedProgramType === 'Line of Credit' && loanApplication?.availablePrograms.helocData !== undefined ? 'helocData' : 'helData';
  const programsHel = loanApplication?.availablePrograms?.[typeOfProgram]?.rates;
  const totalProgram = programsHel.length;
  const dispatch = useAppDispatch();
  const triggerDeselectProgram = useAppSelector((state) => state.LoanApplication.triggerDeselectProgram);
  const handleModal = useCallback((info: IModalInfoType) => {
    const infoModal = {
      body: loanApplication.minLineAmount > 49000 ? info.bodyHigh : info.bodyLow,
      button: info.button,
    };
    setModalInfo(infoModal);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkScrollPosition = useCallback(() => {
    const { current } = carouselRef;
    if (current) {
      const maxScrollLeft = current.scrollWidth - current.clientWidth;
      setDisableLeftButton(current.scrollLeft === 0);
      setDisableRightButton(current.scrollLeft >= maxScrollLeft);
    }
  }, [programsHel]); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollCarousel = useCallback(
    (direction: 'left' | 'right') => {
      const { current } = carouselRef;
      if (current) {
        const scrollAmount = direction === 'left' ? -current.clientWidth : current.clientWidth;
        current.scrollBy({ left: scrollAmount, behavior: 'smooth' });

        setTimeout(checkScrollPosition, 300);
      }
    },
    [checkScrollPosition],
  );

  const handleSelect = (prog: any, id: number) => {
    setSelectedProgram((prevSelected) => (prevSelected.includes(id) ? prevSelected.filter((progIndex) => progIndex !== id) : [id]));
    const isDeselectProgram = selectedProgram.includes(id);
    const updatedLoanApplication = { ...loanApplication };
    const payload = isDeselectProgram
      ? { ...updatedLoanApplication, programName: '', selectedProgram: {}, triggerDeselectProgram: false }
      : handleProgramSelection(prog, updatedLoanApplication);
    !isDeselectProgram && setIsCreditUnion && setIsCreditUnion(prog?.programName?.includes('Credit Union'));
    dispatch({
      type: 'LoanApplication/updateValue',
      payload,
    });
    isDeselectProgram && setIsCreditUnion && setIsCreditUnion(false);
  };

  useEffect(() => {
    checkScrollPosition();
  }, [checkScrollPosition]);

  useEffect(() => {
    if (triggerDeselectProgram && selectedProgram.length > 0) {
      handleSelect(0, selectedProgram?.[0]); //unselect program
    }
  }, [triggerDeselectProgram]);

  return (
    <WrapperContentCard data-testid="program-card">
      <CarouselWrapper>
        <NavButton direction="left" onClick={() => scrollCarousel('left')} aria-label="Scroll left" disabled={disableLeftButton}>
          <ArrowLeftIcon />
        </NavButton>
        <CarouselContainer ref={carouselRef}>
          {programsHel?.map((program: Rates, index: number) => (
            <ProgramCardItem
              key={index}
              index={index}
              program={program}
              totalProgram={totalProgram}
              handleModal={handleModal}
              typeOfProgram={typeOfProgram}
              isReprice={isReprice}
              handleSelect={handleSelect}
              selectedProgram={selectedProgram}
              acceptCreditUnion={acceptCreditUnion}
              isCreditUnion={isCreditUnion}
              setSelectedProgram={setSelectedProgram}
            />
          ))}
          <NavButton direction="right" onClick={() => scrollCarousel('right')} aria-label="Scroll right" disabled={disableRightButton}>
            <ArrowRightIcon />
          </NavButton>
        </CarouselContainer>
      </CarouselWrapper>
      {modalInfo && <DefaultModal openModal={!!modalInfo} setOpenModal={() => setModalInfo(null)} infoModal={modalInfo} data-testid="modal" typeModal="default" />}
    </WrapperContentCard>
  );
};

export default ProgramCard;
