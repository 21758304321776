import React from 'react';
import { Button } from '@components/Button/DefaultButton/Button';
import { Modal, ModalTitle, ModalBody, ModalContent, ModalFooter, BackDrop, Spinner, InfoIcon } from './DefaultModal.styles';
import { useAppSelector } from '@/app/hooks';
import { selectIsLoading } from '@/reducers/currentPage';
import { useNavigate } from 'react-router-dom';
import SafeHTML from '@/utils/safeHTML';

export interface DefaultModalProps {
  openModal: boolean;
  setOpenModal: (isOpen: boolean) => void;
  typeModal?: 'submit' | 'default';
  infoModal?: { header?: string; body?: string; bodyLow?: string; bodyHigh?: string; button?: string; textOne?: string; textTwo?: string };
  isErrorModal?: boolean;
  flexDirectionContent?: 'row' | 'column';
}

export const DefaultModal: React.FC<DefaultModalProps> = ({ openModal, setOpenModal, infoModal, isErrorModal = false, flexDirectionContent }) => {
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const navigate = useNavigate();
  const closeModal = () => {
    if (isErrorModal) templateConfig?.pages?.returnPage ? (window.location.href = templateConfig?.pages?.returnPage) : navigate('/');
    setOpenModal(false);
  };
  let buttonWithLender;
  if (infoModal?.button && templateConfig?.lenderName) {
    buttonWithLender = infoModal?.button.replace('%%lenderName%%', templateConfig?.lenderName) ?? infoModal?.button;
  }
  const isLoading = useAppSelector(selectIsLoading);
  const isSubmit = true;
  return (
    <>
      <BackDrop $isOpen={openModal} onClick={closeModal} data-testid="modal-backdrop" />
      {openModal && (
        <Modal $isLoading={isLoading} $isOpen={openModal}>
          {isLoading && isSubmit ? (
            <ModalBody>
              <Spinner /> <ModalTitle $isLoading={isLoading}>{infoModal?.textOne}</ModalTitle> <ModalContent $isLoading={isLoading}>{infoModal?.textTwo}</ModalContent>
            </ModalBody>
          ) : (
            <ModalBody>
              <InfoIcon />
              <ModalTitle $isLoading={isLoading}>{infoModal?.header}</ModalTitle>{' '}
              <ModalContent $isLoading={isLoading} $flexDirectionContent={flexDirectionContent}>
                <SafeHTML tag="p" html={infoModal?.body || ''} />
              </ModalContent>
              {infoModal?.button && (
                <ModalFooter>
                  <Button label={buttonWithLender} onClick={closeModal} variant="secondary" buttonSize="l" />{' '}
                </ModalFooter>
              )}
            </ModalBody>
          )}
        </Modal>
      )}
    </>
  );
};
