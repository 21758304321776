import React from 'react';
import { TextBlock, TypeOfText } from '../text/TextBlock';
import AmountCard from '../Layout/AmountCard/AmountCard';
import { TitleContainer } from './TermsAmounts.styles';
import { useAppSelector } from '@app/hooks';
import { formatNumber } from '@/utils/formatValues';
import { ProgressbarWrapper, Progressbar, ProgressbarItem, Line } from '@pages/LineAmountCalculator/LineAmountCalculator.styles';
import { pages } from '@/defaultVerbiages';

interface TermAmountsProps {
  width?: string;
  vmoPage: string;
  hasDrawAmount?: boolean;
}

const TermsAmounts: React.FC<TermAmountsProps> = ({ width = '80%', vmoPage, hasDrawAmount = false }) => {
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  const isICE = templateConfig.isICE ?? false;
  const titleMaxAmount = templateConfig?.pages?.vmo?.terms?.title?.text
    ?.replace('%%maxLineAmount%%', formatNumber(isICE ? loanApplication?.maxLoanAmount : loanApplication.maxLineAmount))
    ?.replace('%%maxLoanAmount%%', formatNumber(loanApplication?.availablePrograms?.helRates?.[0]?.maxLoanAmount || loanApplication?.minLoanAmount));

  return (
    <>
      <ProgressbarWrapper>
        <Progressbar $isHidden={!hasDrawAmount}>
          <ProgressbarItem $isActive={vmoPage === 'Line Amount'}>
            1<p>Line Amount</p>
          </ProgressbarItem>
          <Line />
          <ProgressbarItem $isActive={vmoPage === 'Draw Amount'}>
            2<p>Draw Amount</p>
          </ProgressbarItem>
        </Progressbar>
      </ProgressbarWrapper>
      <TitleContainer width={width}>
        <TextBlock
          text={titleMaxAmount || pages?.vmo?.terms?.title?.text}
          direction={'center'}
          color="primary"
          type={TypeOfText.H1}
          isHidden={templateConfig?.pages?.vmo?.terms?.title?.isHidden ?? false}
        />
      </TitleContainer>
      <AmountCard title={templateConfig?.pages?.vmo?.terms?.cardAmount?.title} cardSectionInfo={templateConfig?.pages?.vmo?.terms?.cardAmount?.infoSection} />
    </>
  );
};

export default TermsAmounts;
