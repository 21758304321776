import { LoanApplicationState } from '@/reducers/LoanApplication.types';
import { formatNumber } from './formatValues';
export const handleParseDisplay = (range: string, loanApplication: LoanApplicationState): string => {
  const { minLineAmount, maxLineAmount, minLoanAmount, maxLoanAmount, minDrawAmount, maxDrawAmount, lineAmount } = loanApplication?.selectedProgram || {};

  return range
    .replace(/%minLineAmount%|%%minLineAmount%%/g, formatNumber(Number(minLineAmount) || Number(minLoanAmount)))
    .replace(/%maxLineAmount%|%%maxLineAmount%%/g, formatNumber(Number(maxLineAmount) || Number(maxLoanAmount)))
    .replace(/%minDrawAmount%|%%maxDrawAmount%%/g, formatNumber(Number(minDrawAmount) || Number(maxDrawAmount)))
    .replace(/%lineAmount%/g, formatNumber(Number(lineAmount)));
};
