import { LoanApplicationState } from '@/reducers/LoanApplication.types';
import { formatCurrency } from './formatValues';
import { transformGovernmentText } from './formatToLabelCase';

export const parseInputValue = (value: string): number => Number(value.replace(/[\$,]/g, ''));

export const parseSummaryValues = (value: string, loanApplication: LoanApplicationState): string => {
  const borrower = loanApplication.borrowers[0];
  const coBorrower = loanApplication?.borrowers[1];
  const employment = borrower.employmentHistory[0];
  const employmentCoBorrower = coBorrower?.employmentHistory[0];
  const coBorrowerGovernment = coBorrower?.governmentHMDA;
  const governmentHMDA = borrower.governmentHMDA;
  const placeholders: Record<string, string> = {
    '%%email%%': borrower.borrowerEmailAddress,
    '%%name%%': `${borrower.borrowerFirstName} ${borrower.borrowerLastName}`,
    '%%birthday%%': borrower.borrowerDOB || '',
    '%%maritalStatus%%': borrower.borrowerMaritalStatus,
    '%%contactMethod%%': borrower.borrowerPreferredContactMethod,
    '%%homePhone%%': borrower.borrowerAlternatePhone,
    '%%cellPhone%%': borrower.borrowerPrimaryPhone,
    '%%workPhone%%': borrower.borrowerWorkPhone,
    '%%address%%': loanApplication.borrowerProperty.propertyStreetAddress,
    '%%propertyType%%': loanApplication.propertyType,
    '%%propertyUse%%': loanApplication.propertyPrimaryUse,
    '%%estimatedMarket%%': formatCurrency(loanApplication.estimatedMarketValue),
    '%%remainingMortgage%%': formatCurrency(loanApplication.remainingMortgageBalance),
    '%%lineAmount%%': formatCurrency(loanApplication.lineAmount),
    '%%drawAmount%%': formatCurrency(loanApplication.drawAmount),
    '%%employer%%': employment.employerName,
    '%%income%%': formatCurrency(employment.grossMonthlyIncome),
    '%%startDate%%': employment.startDate,
    '%%totalMonths%%': employment?.totalMonths?.toString() ?? '0',
    '%%ethnicity%%': transformGovernmentText(governmentHMDA.applicantEthnicity),
    '%%race%%': transformGovernmentText(governmentHMDA.applicantRace),
    '%%gender%%': transformGovernmentText(governmentHMDA.applicantSex),
    '%%loanNumber%%': loanApplication.loanNumber,
    '%%apr%%': loanApplication.apr.toString() + '%',
    '%%nameCoBorrower%%': coBorrower ? `${coBorrower?.borrowerFirstName} ${coBorrower?.borrowerLastName}` : '',
    '%%emailCoBorrower%%': coBorrower?.borrowerEmailAddress || '',
    '%%birthdayCoBorrower%%': coBorrower?.borrowerDOB || '',
    '%%homePhoneCoBorrower%%': coBorrower?.borrowerAlternatePhone || '',
    '%%maritalStatusCoBorrower%%': coBorrower?.borrowerMaritalStatus || '',
    '%%cellPhoneCoBorrower%%': coBorrower?.borrowerPrimaryPhone || '',
    '%%contactMethodCoBorrower%%': coBorrower?.borrowerPreferredContactMethod || '',
    '%%workPhoneCoBorrower%%': coBorrower?.borrowerWorkPhone || '',
    '%%employerCoBorrower%%': employmentCoBorrower?.employerName || '',
    '%%incomeCoBorrower%%': employmentCoBorrower ? formatCurrency(employmentCoBorrower?.grossMonthlyIncome) : '0',
    '%%startDateCoBorrower%%': employmentCoBorrower?.startDate || '',
    '%%totalMonthsCoBorrower%%': employmentCoBorrower ? employmentCoBorrower?.totalMonths?.toString() || '0' : '0',
    '%%ethnicityCoBorrower%%': coBorrowerGovernment ? transformGovernmentText(coBorrowerGovernment?.applicantEthnicity) : '',
    '%%raceCoBorrower%%': coBorrowerGovernment ? transformGovernmentText(coBorrowerGovernment?.applicantRace) : '',
    '%%genderCoBorrower%%': coBorrowerGovernment ? transformGovernmentText(coBorrowerGovernment?.applicantSex) : '',
    '%%loanAmount%%': formatCurrency(loanApplication.loanAmount),
  };

  return Object.entries(placeholders).reduce((result, [placeholder, replacement]) => {
    return result.replaceAll(placeholder, replacement || '');
  }, value);
};
