type InfoIcon = {
  bodyHigh?: string;
  bodyLow?: string;
  button?: string;
};

type TemplateConfig = {
  pages?: {
    vmo?: {
      [key: string]: {
        infoIcon?: InfoIcon;
      };
    };
  };
};

export const HandleModal = (label: string, templateConfig: TemplateConfig): InfoIcon => {
  const labelMapping: Record<string, string> = {
    'LINE AMOUNT': 'lineAmount',
    'LOAN AMOUNT': 'loanAmount',
    'ESTIMATED APR': 'estimatedApr',
    'EST MONTHLY PMT': 'estimateMonthlyPmt',
    'ORIGINATION FEE': 'originationFee',
    'INITIAL DRAW': 'initialDraw',
    'MINIMUM DRAW': 'minimumDraw',
    'MAXIMUM DRAW': 'maximumDraw',
  };

  const key = labelMapping[label];
  const infoIcon = key ? templateConfig?.pages?.vmo?.[key]?.infoIcon : null;

  return infoIcon ?? { bodyHigh: 'Default High Text', bodyLow: 'Default Low Text', button: 'Close' };
};
