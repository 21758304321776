import React, { useState, useEffect } from 'react';
import ProgramCard from '@/components/Layout/ProgramCard/ProgramCard';
import { DebtConsolidation } from '@/components/Layout/DebtConsolidation/DebtConsolidation';
import ProgramSelector from '@/components/ProgramSelector/ProgramSelector';
import CalculateAmount from '@/components/CalculateAmount/CalculateAmount';
import { CardsContainer, SubtitleContainer } from './VmoV2.styles';
import { Wrapper } from '../wrapper.styles';
import { TextBlock, TypeOfText } from '@/components/text/TextBlock';
import { useAppSelector } from '@app/hooks';
import { NavigationButton, PageOrigin } from '@components/Button/NavigationButton/NavigationButton';
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '@/hooks/useIsMobile';
import { FooterNewVmo } from '@/components/FooterNewVmo/FooterNewVmo';
import { ConfirmationModal } from '@/components/Modals/ConfirmModal/ConfirmModal';
import { pages } from '@/defaultVerbiages';
import { useDispatch } from 'react-redux';
import { setIsOpenDebtConsolidation } from '@/reducers/currentPage';

interface VmoV2Props {
  onAccess: (page: string) => void;
}
const VmoV2: React.FC<VmoV2Props> = ({ onAccess }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let LoanApplication = useAppSelector((state) => state.LoanApplication);
  const { isOpenDebtConsolidation } = useAppSelector((state) => state.currentPage);
  const [isReprice, setIsReprice] = useState<boolean>(false);
  const [isCreditUnion, setIsCreditUnion] = useState(false);
  const [isCreditUnionRequired, setIsCreditUnionRequired] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState<number[]>([]);
  const [acceptCreditUnion, setAcceptCreditUnion] = useState(false);
  const [openCreditUnion, setOpenCreditUnion] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (LoanApplication?.selectedProgram?.programName?.includes('Credit Union')) {
      setIsCreditUnion(true);
      setOpenCreditUnion(true);
    } else {
      setIsCreditUnion(false);
    }
    if (selectedProgram.length > 0) setAcceptCreditUnion(false);
  }, [selectedProgram]); // eslint-disable-line react-hooks/exhaustive-deps

  const creditUnion = isCreditUnion && !openCreditUnion && acceptCreditUnion;
  const openDebtConsolidation = creditUnion || !isCreditUnion;

  const isDisabledNext =
    Object.keys(LoanApplication?.selectedProgram).length === 0 ||
    LoanApplication?.selectedProgram?.programName === '' ||
    !!(LoanApplication?.selectedProgram?.programName?.includes('Credit Union') && !isCreditUnionRequired);

  // const dispatch = useAppDispatch();

  useEffect(() => {
    if (openCreditUnion) {
      dispatch(setIsOpenDebtConsolidation(true));
    }
  }, [isCreditUnionRequired]);

  return (
    <>
      <Wrapper $isMobile={isMobile} $padding="0rem">
        {isCreditUnion && !isOpenDebtConsolidation && (
          <ConfirmationModal
            openModal={openCreditUnion}
            handleModalClose={setAcceptCreditUnion}
            setOpenModal={setOpenCreditUnion}
            infoModal={templateConfig?.pages?.vmo?.vmoV2?.modal?.helocCreditUnionVerbiage}
          />
        )}
        <ProgramSelector />
        <SubtitleContainer>
          <TextBlock
            text={templateConfig?.pages?.vmo?.vmoV2?.titleProgramSection?.text || pages?.vmo?.vmoV2?.titleProgramSection?.text}
            direction={'center'}
            type={TypeOfText.Subtitle}
            isHidden={templateConfig?.pages?.vmo?.vmoV2?.titleProgramSection?.isHidden || false}
          />
          <TextBlock
            text={templateConfig?.pages?.vmo?.vmoV2?.subtitleProgramSection?.text || pages?.vmo?.vmoV2?.subtitleProgramSection?.text}
            direction={'center'}
            type={TypeOfText.Subtitle}
            isHidden={templateConfig?.pages?.vmo?.vmoV2?.subtitleProgramSection?.isHidden || false}
          />
        </SubtitleContainer>
        <CardsContainer>
          <CalculateAmount setIsReprice={setIsReprice} selectedProgram={selectedProgram} />
          <ProgramCard
            isReprice={isReprice}
            setSelectedProgram={setSelectedProgram}
            selectedProgram={selectedProgram}
            acceptCreditUnion={acceptCreditUnion}
            isCreditUnion={openCreditUnion}
            setIsCreditUnion={setIsCreditUnionRequired}
          />
        </CardsContainer>
      </Wrapper>
      {(openDebtConsolidation || isOpenDebtConsolidation) && (
        <DebtConsolidation isReprice={isReprice} acceptCreditUnion={acceptCreditUnion} isCreditUnion={openCreditUnion} isOpenDebtConsolidation={isOpenDebtConsolidation} />
      )}
      <NavigationButton
        variant="primary"
        nextButton={templateConfig?.pages?.vmo?.vmoV2?.NavigationButton?.nextButton || 'Next'}
        isBackButton={templateConfig?.pages?.vmo?.vmoV2?.NavigationButton?.backButton?.isHidden || false}
        backButton={templateConfig?.pages?.vmo?.vmoV2?.NavigationButton?.backButton?.text || 'Back'}
        disabled={isDisabledNext}
        type="next"
        onAccess={onAccess}
        navigation={navigate}
        pageOrigin={PageOrigin.ESTIMATED_AVAILABLE_EQUITY}
        currentPage={PageOrigin.VMO}
        destinationPage={PageOrigin.PERSONAL_INFORMATION}
      />
      <FooterNewVmo></FooterNewVmo>
    </>
  );
};

export default VmoV2;
