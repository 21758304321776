import React from 'react';
import { Counter, InfoIcon, SectionCard } from './ProgramCard.styles';

interface IProgramInfoProps {
  value: number | string | JSX.Element;
  label: string;
  subLabel: string;
  isInfoIcon: boolean;
  onInfoClick: (info: any) => void;
  counter?: string;
}

const ProgramInfoComponent = ({ value = 0, label = '', subLabel = '', counter = '', onInfoClick, isInfoIcon = false }: IProgramInfoProps) => {
  const isOriginationFee = subLabel === 'Origination Fee';

  return (
    <SectionCard $isOriginationFee={isOriginationFee}>
      <div>
        <p>{value}</p>
      </div>
      <p>{label}</p>
      <p>
        {subLabel} <Counter>{counter}</Counter>
      </p>
      {isInfoIcon && <InfoIcon data-testid="info-icon" onClick={onInfoClick} aria-label={`More information about ${label}`} color={''} />}
    </SectionCard>
  );
};

export const ProgramInfo = React.memo(ProgramInfoComponent);
