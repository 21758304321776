import styled from 'styled-components';

export const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 1.688rem;
  width: 90%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    flex-direction: column;
    width: 96%;
    gap: 3rem;
  }
`;

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    gap: 0.5rem;
  }

  :last-child {
    h1 {
      font-size: 1.063rem;
      line-height: 207.5%;
      color: ${({ theme }) => theme.colors.bgSecondary};
      font-weight: 500;
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 0.628rem;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  :first-child {
    display: flex;
    flex-direction: column;
    gap: 8px;
    h1 {
      text-align: center;
      font-family: Poppins;
      font-style: normal;
      justify-content: center;
      font-size: 1.264rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.bgSecondary};
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 0.628rem;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
`;
