import { SideBarSteps, SideBarIcon, ActiveIcon, CheckedIcon, SideBarSteppers } from './SideBarSteppers.styles';
import { useAppSelector } from '@app/hooks';
import { listPageOrder } from '@/helper/pageOrder';
import React, { useState, useEffect } from 'react';
import { handleCurrentVmoPage } from '@/utils/handleCurrentPage';
import { selectIsGoingBack } from '@/reducers/currentPage';
export interface SideBarSteppersProps {
  variant: 'primary' | 'secondary' | 'default';
}

const BarSteppersComponent: React.FC<SideBarSteppersProps> = ({ variant }) => {
  const currentStep = useAppSelector((state) => state.currentPage);
  const [currentPage, setCurrentPage] = useState(0);
  const [backPage, setBackPage] = useState(0);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const { isEnabled: isVmoRedesignEnabled = false } = templateConfig?.features?.vmoRedesign ?? {};
  const hasDrawPage = templateConfig?.pages?.hasDrawAmountPage ?? false;
  const hasDeclarations = !!templateConfig?.pages?.declarations;
  const pagesWithVmoVersion = handleCurrentVmoPage(isVmoRedesignEnabled, hasDrawPage, hasDeclarations);
  const pageName = pagesWithVmoVersion && pagesWithVmoVersion[currentStep.step];
  const isCompleted = pageName === 'Completion';
  const isGoingBack = useAppSelector(selectIsGoingBack);
  const isVmoV1 = pageName === 'View My Offer!' && currentStep.step !== 3;
  const currentUrlPage = window.location.pathname;
  const isVmoV2 = templateConfig?.features?.vmoRedesign?.isEnabled && currentStep.step === 3;
  const currentAmountPage = hasDrawPage ? '/vmo-draw-amount' : '/vmo-line-amount';
  const totalPageList = hasDeclarations ? listPageOrder : listPageOrder.filter((page) => page !== 'Declarations');
  useEffect(() => {
    const nextStep = currentStep.step === 0 || (currentStep.step === 1 && currentStep.step > backPage) || isVmoV1 ? 0 : 1;
    setCurrentPage((prevPage) =>
      Math.max(0, isGoingBack ? (currentUrlPage === currentAmountPage || (!isVmoV1 && currentStep.step !== 3) || isVmoV2 ? prevPage - 1 : prevPage) : prevPage + nextStep),
    );

    setBackPage(Math.max(0, currentStep.step));
  }, [currentStep.step]);

  return (
    <SideBarSteppers>
      {totalPageList.map((steps, index) => (
        <SideBarSteps
          key={index}
          $variant={variant}
          $isCurrent={currentPage === index && !isCompleted}
          $isChecked={currentPage > index || isCompleted}
          data-testid="side-bar-steps"
        >
          <SideBarIcon>
            {currentPage <= index && !isCompleted ? (
              <ActiveIcon data-testid="active-icon" $isCurrent={currentPage === index && !isCompleted} />
            ) : (
              <CheckedIcon data-testid="checked-icon" $isCurrent={currentPage === index && !isCompleted} />
            )}
          </SideBarIcon>
          {steps}
        </SideBarSteps>
      ))}
    </SideBarSteppers>
  );
};
export const BarSteppers = React.memo(BarSteppersComponent);
