import styled, { css } from 'styled-components';
import estimatedAvailable from '../../assets/images/available-equity-icon.svg?react';
import estimatedValue from '../../assets/images/estimated-value-icon.svg?react';
import estimatedHome from '../../assets/images/estimated-home-icon.svg?react';
import remainingMortgage from '../../assets/images/remaining-mortgage-icon.svg?react';

interface EstimatedAvailableEquityProps {
  $customColor?: string;
  $isCustomColor?: boolean;
  $isHidden?: boolean;
}
export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const Icons = css`
  width: 8.315rem;
  margin-right: 1.5rem;
  cursor: pointer;
  height: 3.6rem;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-right: 0;
  }
`;

export const EstimatedAvailableIcon = styled(estimatedAvailable)`
  ${Icons};
`;

export const EstimatedValueIcon = styled(estimatedValue)`
  ${Icons};
  height: 3.8rem;
`;

export const EstimatedHomeIcon = styled(estimatedHome)`
  ${Icons};
  height: 3.4rem;
`;

export const RemainingMortgageIcon = styled(remainingMortgage)`
  ${Icons};
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-right: 3rem;
  color: ${(props) => props.theme.colors.bgGrey};
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    gap: 0.938rem;
    text-align: center;
    padding: 1rem 0;
    margin-right: 0;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 2rem;
  margin-bottom: 0.3rem;
  :first-child {
    height: auto;
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
      font-size: 0.938rem;
      font-weight: 500;
    }
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      justify-content: center;
    }
  }
`;

export const ConfirmMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 2rem;
  margin-bottom: 0.3rem;
  border-radius: 0.5rem;
  padding: 1.563rem;
  background: ${({ theme }) => theme.colors.bgMediumGrey};
  gap: 1.563rem;
  button {
    font-family: Poppins;
    font-size: 1.063rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      font-size: 0.75rem;
    }
  }
  nav {
    margin-bottom: 0;
  }
  h1 {
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: auto;
    text-align: center;
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
      font-weight: 500;
    }
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      justify-content: center;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  :first-child {
    width: auto;
    min-width: 11rem;
    max-width: 15rem;
    flex-grow: 0;

    input {
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: ${(props) => props.theme.colors.textDarkGrey};
    }

    @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      min-width: 12rem;
      max-width: 15rem;
      flex-direction: column;
      justify-content: center;

      input {
        width: 100%;
      }
    }
  }
`;

export const TextContainer = styled.div<EstimatedAvailableEquityProps>`
  display: flex;
  width: 100%;
  p {
    color: ${(props) => (props.$isCustomColor ? props.$customColor : props.theme.colors.textGrey)};
  }
`;

export const EquityGroup = styled.div<EstimatedAvailableEquityProps>`
  display: ${(props) => (props.$isHidden ? 'none' : 'flex')};
  align-items: center;
  width: 100%;
  min-height: 6.563rem;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column;
    height: auto;
    margin: 1.5rem 0;
    display: ${(props) => (props.$isHidden ? 'none' : 'flex')};
  }
`;

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  background: white;
  border-radius: 0.688rem 0.688rem 0.688rem 0.688rem;
  gap: 1.563rem;
  padding: 1.563rem 1.188rem 1.25rem 1.938rem;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
    height: 100%;
    margin-top: 1rem;
    padding: 1.375rem 1.563rem;
  }
`;
