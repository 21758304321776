import React, { useEffect, useState } from 'react';
import { Button } from '@/components/Button/DefaultButton/Button';
import { Modal, ModalTitle, ModalBody, ModalContent, ModalFooter, BackDrop } from './EditModal.styles';
import { AddressAutoComplete } from '@components/input/AddressAutoComplete/AddressAutoComplete';
import { DropDownInput } from '@components/input/DropDownInput/DropDownInput';
import { TextInput } from '@components/input/TextInput/TextInput';
import { useAppSelector, useAppDispatch } from '@app/hooks';
import { FormGroupComponent } from '@components/input/FormGroup/FormGroup';
import _ from 'lodash';
import { handleGetStartedAddress } from '@/utils/handleEndpoints';
import { startLoading } from '@/reducers/currentPage';

export interface EditModalProps {
  openModal: boolean;
  setOpenModal: (isOpen: boolean) => void;
  size?: string;
  infoModal?: {
    header?: string;
    subtitle?: string;
    body?: string;
    button?: string;
  };
  isEstimatedAvailableEquity?: boolean;
}

export enum PropertyType {
  Address = 'address',
  Suit = 'suit',
  City = 'city',
  State = 'state',
  Zip = 'zip',
  County = 'county',
}

export const EditModal: React.FC<EditModalProps> = ({ openModal, setOpenModal, infoModal, size = '100%', isEstimatedAvailableEquity }) => {
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const currentValues = loanApplication.borrowerProperty;

  useEffect(() => {
    if (openModal) {
      const { propertyStreetAddress, propertyStreetAddress2, propertyCity, propertyState, propertyZip, propertyCounty } = loanApplication.borrowerProperty || {};

      setFormValues((prevValues) => ({
        ...prevValues,
        address: propertyStreetAddress || '',
        suit: propertyStreetAddress2 || '',
        city: propertyCity || '',
        state: propertyState || '',
        zip: propertyZip || '',
        county: propertyCounty || '',
        propertyOccupancyDate: currentValues.propertyOccupancyDate || '',
      }));
    }
  }, [openModal, loanApplication]);

  const dispatch = useAppDispatch();
  const [formValues, setFormValues] = useState({
    address: currentValues.propertyStreetAddress || '',
    suit: currentValues.propertyStreetAddress2 || '',
    city: currentValues.propertyCity || '',
    state: currentValues.propertyState || '',
    zip: currentValues.propertyZip || currentValues.zip || '',
    county: currentValues.propertyCounty || '',
    propertyOccupancyDate: currentValues.propertyOccupancyDate || loanApplication.borrowerProperty.propertyOccupancyDate,
  });

  const closeModal = () => {
    let auxLoanApplication = _.cloneDeep(loanApplication);
    auxLoanApplication.borrowerProperty = {
      ...auxLoanApplication.borrowerProperty,
      propertyStreetAddress: formValues.address || '',
      propertyStreetAddress2: formValues.suit || '',
      propertyCity: formValues.city || '',
      propertyState: formValues.state || '',
      propertyZip: formValues.zip || '',
      propertyCounty: formValues.county || '',
      propertyOccupancyDate: formValues.propertyOccupancyDate,
    };

    dispatch({
      type: 'LoanApplication/updateValue',
      payload: auxLoanApplication,
    });

    if (isEstimatedAvailableEquity) {
      dispatch(startLoading());
      handleGetStartedAddress(false, auxLoanApplication, loanApplication, dispatch, templateConfig);
    }

    setOpenModal(false);
  };

  const parseAddress = (address: string) => {
    const regex = /^(.*?),\s*([\w\s]+),\s*([A-Z]{2})\s*(\d{5})?$/;
    const match = address.match(regex);

    return match
      ? {
          street: match[1].trim(),
          city: match[2].trim(),
          state: match[3].trim(),
          zip: match[4]?.trim() || '',
          county: '',
        }
      : { street: address, city: '', state: '', zip: '', county: '' };
  };

  const handleChange = (value: string | number | boolean, name: string) => {
    setFormValues((prevValues) => {
      let updatedValues = { ...prevValues, [name]: value };

      if (name === 'address' || (name === 'text' && typeof value === 'string')) {
        const parsedAddress = parseAddress(value as string);

        updatedValues = {
          ...updatedValues,
          address: parsedAddress.street || prevValues.address,
          city: parsedAddress.city || prevValues.city,
          state: parsedAddress.state || prevValues.state,
          zip: parsedAddress.zip || prevValues.zip,
          county: parsedAddress.county || prevValues.county,
        };
      }

      dispatch({ type: 'CurrentForm/addField', payload: updatedValues });
      return updatedValues;
    });
  };

  return (
    <>
      <BackDrop $isOpen={openModal} onClick={closeModal} data-testid="modal-backdrop" />
      <Modal $isOpen={openModal}>
        <ModalBody data-testid="modal-edit">
          <ModalTitle>{infoModal?.header}</ModalTitle>
          <ModalTitle>{infoModal?.subtitle}</ModalTitle>
          <ModalContent>
            <FormGroupComponent onChange={handleChange} data-testid="address-form">
              <AddressAutoComplete isDirection={true} size={size} value={loanApplication?.borrowerProperty?.propertyStreetAddress} />
              <TextInput
                dataTestId="suit"
                placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.suit?.text || 'Suit'}
                value={formValues.suit}
                size={size}
                propertyType={PropertyType.Suit}
              />
              <TextInput
                dataTestId="city"
                placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.city?.text || 'City'}
                value={loanApplication?.borrowerProperty?.propertyCity}
                size={size}
                propertyType={PropertyType.City}
              />
              <DropDownInput
                dataTestId="state"
                placeHolder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.state?.text || 'State'}
                options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.state?.options}
                value={loanApplication?.borrowerProperty?.propertyState}
                size={size}
                propertyType={PropertyType.State}
                capitalizeWord={false}
              />
              <TextInput
                dataTestId="zip"
                value={loanApplication?.borrowerProperty?.propertyZip || 'Zip'}
                placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.zip?.text}
                size={size}
                propertyType={PropertyType.Zip}
              />
              <TextInput
                dataTestId="county"
                value={loanApplication?.borrowerProperty?.propertyCounty || 'County'}
                placeholder={templateConfig?.pages?.LetsGetStartedAddress?.PropertyCard?.county}
                size={size}
                propertyType={PropertyType.County}
              />
            </FormGroupComponent>
          </ModalContent>
          {infoModal?.button && (
            <ModalFooter>
              <Button label={infoModal.button} onClick={closeModal} variant="primary" buttonSize="s" />
            </ModalFooter>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
