import styled, { css } from 'styled-components';
interface NavBarProps {
  $variant?: 'primary' | 'secondary' | 'default';
  color?: string;
  $backgroundColor?: string;
  $isCustomColor?: boolean;
  $isGetStarted?: boolean;
  $isHidden?: boolean;
}
const getTextColor = (props: NavBarProps & { theme: any }) => {
  if (props.$isCustomColor && props.color) return props.color;
  if (props.$variant === 'primary') return props.theme.colors.textPrimary;
  if (props.$variant === 'secondary') return props.theme.colors.textSecondary;
  return props.theme.colors.textPrimary;
};
const getBackgroundColor = (props: NavBarProps & { theme: any }) => {
  if (props.$isCustomColor && props.$backgroundColor) return props.$backgroundColor;
  if (props.$backgroundColor) return props.$backgroundColor;
  if (props.$variant === 'primary') return props.theme.colors.primary;
  if (props.$variant === 'secondary') return props.theme.colors.secondary;
  return props.theme.colors.default;
};
export const NavBarWrapper = styled.nav<NavBarProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.563rem;
  position: relative;
  height: 5rem;
  width: 100%;
  background: ${(props) => getBackgroundColor(props)};
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    height: 3.5rem;
    padding: 1.563rem 0.625rem;
  }
`;
export const Brand = styled.div<NavBarProps>`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 1.313rem;
  letter-spacing: -0.18px;
  color: ${(props) => getTextColor(props)};
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: -0.007rem;
  }
`;

export const NavBarLogo = styled.nav`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3rem;
  img {
    width: 10rem;
    margin-bottom: 3rem;
  }
`;

export const Col = styled.li<NavBarProps>`
  font-family: ${(props) => props.theme.fonts.main};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.$isGetStarted ? 'flex-end' : 'center')};
  text-align: center;
  color: ${(props) => getTextColor(props)};
  background: ${(props) => props.theme.colors.transparent};
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding-right: 1rem;
    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: -0.11px;
  }
`;

const NavTexts = css`
  font-family: Poppins;
  font-size: 0.938rem;
  font-style: normal;
  line-height: normal;
  list-style: none;
  flex-direction: row;
  align-items: center;
`;

export const NavLoanInfoText = styled.ul<NavBarProps>`
  ${NavTexts};
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  justify-content: flex-end;
  flex-direction: column;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: ${(props) => (props.$isGetStarted ? 'none' : 'flex')};
  }
`;

export const SupportPhone = styled.ul<NavBarProps>`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: ${(props) => (props.$isGetStarted ? 'none' : 'flex')};
  }
`;
