import axios from 'axios';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import _ from 'lodash';
import { handleErrors } from '../utils/handleErrors';
import { LoanApplication } from '@/reducers/LoanApplication';

interface ConfigProps {
  losIntegration: string;
  ui: {
    isICE: boolean;
    features: {
      vmoRedesign?: object;
      resume?: object;
      employmentVerification?: object;
      vmoDebtConsolidation?: object;
      preventTests?: object;
    };
    fixedValues: Record<string, any>;
  };
  features: {
    vmoRedesign?: object;
    resume?: object;
    employmentVerification?: object;
    vmoDebtConsolidation?: object;
    preventTests?: object;
  };
}

export const endpointFront = {
  experienceApiConfig: process.env.VITE_EXPERIENCE_API || '',
  experienceApi: process.env.VITE_EXPERIENCE_API || '',
  mocked: process.env.VITE_MOCKED_ORIGIN,
  apiKey: process.env.VITE_API_KEY,
};

export default class ExperienceService {
  dispatch = useAppDispatch();
  templateConfig = useAppSelector((state) => state.TemplateConfig);
  isICE = this.templateConfig.isICE === true;

  getApiBaseUrl = () => {
    return this.isICE ? endpointFront.experienceApi.replace('equityiq', 'equity-iq-ice') : endpointFront.experienceApi;
  };

  getApiBaseGlobalConfig = () => {
    return endpointFront.experienceApiConfig.replace('equity-iq-ice', 'equityiq');
  };

  updateGlobalConfig = (response: ConfigProps) => {
    const { ui, features, losIntegration } = response;
    const cloneResponse = { ...ui };
    const clonedLocalLoanApplication = _.cloneDeep(LoanApplication);

    this.isICE = losIntegration === 'encompass';
    cloneResponse.isICE = this.isICE;
    cloneResponse.features = { ...cloneResponse.features, ...features };

    endpointFront.experienceApi = this.getApiBaseUrl();
    endpointFront.experienceApiConfig = this.getApiBaseUrl();

    if (ui.fixedValues && Object.keys(ui.fixedValues).length > 0) {
      Object.entries(ui.fixedValues).forEach(([key, value]) => {
        if (clonedLocalLoanApplication.getInitialState().hasOwnProperty(key)) {
          this.dispatch({
            type: 'LoanApplication/updateSpecificField',
            payload: {
              depth: [key],
              value,
            },
          });
        }
      });
    }

    this.dispatch({
      type: 'TemplateConfig/updateTemplateConfig',
      payload: cloneResponse,
    });
  };

  getGlobalConfig = async () => {
    endpointFront.experienceApiConfig = this.getApiBaseGlobalConfig();
    try {
      const response = await axios.get(`${endpointFront.experienceApiConfig}/api/v1/config`, this.config);
      this.updateGlobalConfig(response.data);
    } catch (error) {
      const parseError = handleErrors(error);
      throw parseError;
    }
  };

  config = {
    headers: {
      'api-key': endpointFront.apiKey || 'none',
      ...(endpointFront.mocked ? { 'mocked-origin': endpointFront.mocked } : {}),
    },
  };
}
