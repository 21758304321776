import { WrapperAmountContainer, Row, Col, SeparatorTotal, TotalRow, ColPercent } from './WrapperAmount.styles';
import SafeHTML from '@/utils/safeHTML';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { useAppSelector } from '@app/hooks';
import React, { useEffect, useState } from 'react';
import { formatNumber } from '@/utils/formatValues';
import { calculateTotalTradeLineBalance, calculatePercent, cashBack } from '@/utils/debtConsolidationCalculator';

interface WrapperAmountProps {
  barColor?: string;
  totalDrawColor?: string;
}

export const WrapperAmount: React.FC<WrapperAmountProps> = ({ barColor = '#3A149E', totalDrawColor = '#3A149E' }) => {
  const templateConfig = useAppSelector((state) => state.TemplateConfig);
  const loanApplication = useAppSelector((state) => state.LoanApplication);
  const tradeLines = loanApplication?.borrowers[0]?.tradelines;

  const [amountUsed, setAmountUsed] = useState(loanApplication.selectedProgram.drawAmount || loanApplication.drawAmount || loanApplication.lineAmount);
  const [totalUsed, setTotalUsed] = useState(calculateTotalTradeLineBalance(tradeLines || []));
  const [percent, setPercent] = useState(calculatePercent(totalUsed, amountUsed));

  useEffect(() => {
    const newAmountUsed = loanApplication.selectedProgram.drawAmount || loanApplication.drawAmount || loanApplication.lineAmount;
    setAmountUsed(newAmountUsed);
    const newTotalUsed = calculateTotalTradeLineBalance(tradeLines || []);
    setTotalUsed(newTotalUsed);
    setPercent(calculatePercent(newTotalUsed, newAmountUsed));
  }, [loanApplication.drawAmount, loanApplication.lineAmount, tradeLines, loanApplication.selectedProgram.drawAmount]);

  return (
    <WrapperAmountContainer data-testid="wrapper-amount" $isHidden={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.wrapperAmount?.isHidden ?? false}>
      <Row $isHidden={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.wrapperAmount?.lineAmount?.isHidden ?? false}>
        <Col>
          <SafeHTML tag="h4" html={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.wrapperAmount?.lineAmount?.text || 'Line Amount Used'} />
        </Col>
        <ColPercent>
          <p>{percent}%</p>
        </ColPercent>
      </Row>
      <ProgressBar percent={percent} barColor={barColor} />
      <Row $isHidden={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.wrapperAmount?.totalUsed?.isHidden ?? false}>
        <Col>
          <SafeHTML tag="span" html={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.wrapperAmount?.totalUsed?.text || 'Total used'} />
        </Col>
        <Col>
          <p>{formatNumber(totalUsed)}</p>
        </Col>
      </Row>
      <Row $isHidden={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.wrapperAmount?.cashBack?.isHidden ?? false}>
        <Col>
          <SafeHTML tag="span" html={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.wrapperAmount?.cashBack?.text || 'Cash back to you'} />
        </Col>
        <Col>
          <p>{formatNumber(cashBack(loanApplication, totalUsed))}</p>
        </Col>
      </Row>
      <SeparatorTotal $totalDrawColor={totalDrawColor} />
      <TotalRow $totalDrawColor={totalDrawColor} $isHidden={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.wrapperAmount?.totalDraw?.isHidden ?? false}>
        <Col>
          <SafeHTML tag="span" html={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.wrapperAmount?.totalDraw?.text || 'Total draw amount'} />
        </Col>
        <Col>
          <p>{formatNumber(amountUsed)}</p>
        </Col>
      </TotalRow>
    </WrapperAmountContainer>
  );
};
