import axios from 'axios';
import { LoanApplicationState } from '@/reducers/LoanApplication.types';
import { handleErrors } from '../utils/handleErrors';
import { ActionProps } from './services.types';
import { handleResponseInformation } from './handleResponseInformation';
import { Dispatch } from 'redux';
import { stopLoading } from '@/reducers/currentPage';
import { endpointFront } from './ExperienceService';

export const makePostCall = async (action: ActionProps, loanApplication: LoanApplicationState, payload: any, dispatch: Dispatch, templateConfig: any, isReprice?: boolean) => {
  try {
    const endpoint = action?.actionProperties?.endpoint?.replace('%%loanId%%', loanApplication.loanId);
    if (!endpoint) {
      throw new Error('Endpoint is not defined');
    }
    const isICE = templateConfig?.isICE ?? false;

    const apiURL = endpointFront;
    const response = await axios.post(`${apiURL.experienceApi}/api/v1${endpoint}`, payload, {
      headers: {
        ...(!isICE && { 'api-key': apiURL.apiKey }),
        ...(apiURL.mocked ? { 'mocked-origin': apiURL.mocked } : {}),
      },
    });
    handleResponseInformation(response.data, loanApplication, dispatch, false, templateConfig, isReprice);
    return response;
  } catch (error) {
    const detailsError = await handleErrors(error);
    throw detailsError;
  } finally {
    dispatch(stopLoading());
  }
};
