export enum PropertyType {
  FirstName = 'firstName',
  MiddleName = 'middleName',
  LastName = 'lastName',
  Suffix = 'suffix',
  Email = 'email',
  PurposeOfLoan = 'purposeOfLoan',
  Address = 'address',
  Suit = 'suit',
  City = 'city',
  State = 'state',
  Zip = 'zip',
  DataOccupancyDate = 'dataOccupancyDate',
  DateBirthday = 'dateBirthday',
  Phone = 'phone',
  MailingSuit = 'mailingSuit',
  MailingCity = 'mailingCity',
  MailingState = 'mailingState',
  MailingZip = 'mailingZip',
  PrimaryUse = 'primaryUse',
  PropertyType = 'propertyType',
}
