import { formatBirthDate, formatPhoneNumber } from './handleDate';
import { LoanApplicationState } from '@/reducers/LoanApplication.types';
interface ApplicantProps {
  applicantEthnicity: string[] | null;
  applicantRace: string[] | null;
  applicantSex: string[] | null;
}

interface CoApplicantProps {
  applicantEthnicity: string[] | null;
  applicantRace: string[] | null;
  applicantSex: string[] | null;
}

interface BorrowerProps {
  appendValue: number;
  borrowerLoanPurpose: string;
  borrowerFirstName: string;
  borrowerMiddleName: string;
  borrowerLastName: string;
  borrowerSuffixName?: string;
  isPrimary: boolean;
  isSpouse: boolean;
  borrowerEmailAddress: string;
  borrowerPrimaryPhone: string;
  borrowerAlternatePhone: string;
  borrowerCitizenship: string;
  borrowerWorkPhone: string;
  borrowerSSN: string;
  creditScore: number;
  creditScoreStated: boolean;
  statedCreditRequired: boolean;
  borrowerPreferredContactMethod: string;
  borrowerMaritalStatus: string;
  borrowerDOB?: string;
  creditInformation?: null;
  employmentHistory: {
    appendValue: number;
    type: string;
    employerName: string;
    jobTitle: string;
    startDate: string;
    endDate: string;
    grossMonthlyIncome: number;
    currentEmployment: boolean | null;
    totalMonths: number;
  }[];
  otherIncome: any[];
  governmentHMDA: {
    applicantRace: string[];
    applicantEthnicity: string[];
    applicantSex: string[];
  };
  tradelines?: [
    {
      include?: boolean;
      tradelineName: string;
      tradelineType: string;
      accountNumber: string;
      tradelineMonthlyPayment: string;
      tradelineBalance: string;
      isSecured: boolean;
      isChecked: boolean;
    },
  ];
}

interface CoBorrowerProps {
  borrowerDOB?: string;
  borrowerMaritalStatus: string;
  borrowerPrimaryPhone: string;
  borrowerWorkPhone: string;
  borrowerSSN: string;
  borrowerPreferredContactMethod: string;
  borrowerCitizenship: string;
  borrowerFirstName: string;
  borrowerLastName: string;
  borrowerSuffixName?: string;
  borrowerMiddleName: string;
  borrowerEmailAddress: string;
  creditInformation?: null;
}
type MappedData = {
  ethnicity: {
    doNotWishToFurnish: boolean;
    isNotHispanicOrLatino: boolean;
    isHispanicOrLatino: boolean;
    isMexican: boolean;
    isPuertoRican: boolean;
    isCuban: boolean;
    isOtherHispanicOrLatino: boolean | null;
    otherHispanicOrLatinoDescription: string | null;
  };
  race: {
    doNotWishToFurnish: boolean;
    isAsian: boolean;
    isAsianIndian: boolean;
    isChinese: boolean;
    isFilipino: boolean;
    isJapanese: boolean;
    isKorean: boolean;
    isVietnamese: boolean;
    isOtherAsian: boolean | null;
    otherAsianDescription: string | null;
    isNativeHawaiianOrPacificIslander: boolean;
    isNativeHawaiian: boolean;
    isGuamanianOrChamorror: boolean;
    isSamoan: boolean;
    isOtherPacificIslander: boolean | null;
    otherPacificIslanderDescription: string | null;
    isBlackOrAfricanAmerican: boolean;
    isWhite: boolean;
    isAmericanIndianOrAlaskaNative: boolean;
    enrolledPrincipal: string | null;
  };
  sex: {
    doNotWishToFurnish: boolean;
    isFemale: boolean;
    isMale: boolean;
  };
};

export function generateGovernmentPayload(applicantData: ApplicantProps, coApplicantData?: CoApplicantProps) {
  function mapData(data: ApplicantProps) {
    const mappedData: MappedData = {
      ethnicity: {
        doNotWishToFurnish: false,
        isNotHispanicOrLatino: false,
        isHispanicOrLatino: false,
        isMexican: false,
        isPuertoRican: false,
        isCuban: false,
        isOtherHispanicOrLatino: null,
        otherHispanicOrLatinoDescription: null,
      },
      race: {
        doNotWishToFurnish: false,
        isAsian: false,
        isAsianIndian: false,
        isChinese: false,
        isFilipino: false,
        isJapanese: false,
        isKorean: false,
        isVietnamese: false,
        isOtherAsian: false,
        otherAsianDescription: null,
        isNativeHawaiianOrPacificIslander: false,
        isNativeHawaiian: false,
        isGuamanianOrChamorror: false,
        isSamoan: false,
        isOtherPacificIslander: false,
        otherPacificIslanderDescription: null,
        isBlackOrAfricanAmerican: false,
        isWhite: false,
        isAmericanIndianOrAlaskaNative: false,
        enrolledPrincipal: null,
      },
      sex: {
        doNotWishToFurnish: false,
        isFemale: false,
        isMale: false,
      },
    };
    const mapping = {
      applicantEthnicity: {
        doNotWishToFurnish: ['ethnicity', 'doNotWishToFurnish'],
        notHispanicOrLatino: ['ethnicity', 'isNotHispanicOrLatino'],
        hispanicOrLatino: ['ethnicity', 'isHispanicOrLatino'],
        hispanicMexican: ['ethnicity', 'isMexican'],
        hispanicPuertoRican: ['ethnicity', 'isPuertoRican'],
        hispanicCuban: ['ethnicity', 'isCuban'],
        hispanicOther: ['ethnicity', 'isOtherHispanicOrLatino', 'otherHispanicOrLatinoDescription'],
      },
      applicantRace: {
        asian: ['race', 'isAsian'],
        asianOther: ['race', 'isOtherAsian', 'otherAsianDescription'],
        asianJapanese: ['race', 'isJapanese'],
        doNotWishToFurnish: ['race', 'doNotWishToFurnish'],
        asianIndian: ['race', 'isAsianIndian'],
        asianChinese: ['race', 'isChinese'],
        asianFilipino: ['race', 'isFilipino'],
        asianKorean: ['race', 'isKorean'],
        asianVietnamese: ['race', 'isVietnamese'],
        nativeHawaiianOrPacificIslander: ['race', 'isNativeHawaiianOrPacificIslander'],
        pacificIslanderHawaiian: ['race', 'isNativeHawaiian'],
        pacificIslanderGuamanian: ['race', 'isGuamanianOrChamorror'],
        pacificIslanderSamoan: ['race', 'isSamoan'],
        pacificIslanderOther: ['race', 'isOtherPacificIslander', 'otherPacificIslanderDescription'],
        black: ['race', 'isBlackOrAfricanAmerican'],
        white: ['race', 'isWhite'],
        americanIndianOrAlaskaNative: ['race', 'isAmericanIndianOrAlaskaNative'],
        enrolledPrincipal: ['race', 'enrolledPrincipal'],
      },
      applicantSex: {
        Male: ['sex', 'isMale'],
        Female: ['sex', 'isFemale'],
        doNotWishToFurnish: ['sex', 'doNotWishToFurnish'],
      },
    };

    Object.entries(data).forEach(([key, values]) => {
      if (Array.isArray(values)) {
        values.forEach((value) => {
          const [mainValue, description] = value.split('|');

          if (mainValue === 'I do not wish to furnish this information') {
            const mappingEntry = mapping[key].doNotWishToFurnish;
            if (mappingEntry) {
              const [category, property] = mappingEntry;
              mappedData[category][property] = true;
            }
          } else {
            const mappingEntry = mapping[key][mainValue];
            if (mappingEntry) {
              const [category, property, descriptionProperty] = mappingEntry;
              if (descriptionProperty) {
                mappedData[category][property] = true;
                mappedData[category][descriptionProperty] = description || null;
              } else {
                mappedData[category][property] = true;
              }
            }
          }
        });
      }
    });

    return mappedData;
  }

  const borrower = mapData(applicantData);
  const coBorrower = coApplicantData ? mapData(coApplicantData) : null;
  return coBorrower ? { borrower, coBorrower } : { borrower };
}

export const createBorrowerPersonalInformationPayload = (borrower: BorrowerProps, loanApplication: LoanApplicationState) => ({
  dateofBirth: formatBirthDate(borrower?.borrowerDOB || ''),
  maritalStatus: borrower?.borrowerMaritalStatus,
  primaryPhone: formatPhoneNumber(borrower?.borrowerPrimaryPhone),
  alternatePhone: borrower?.borrowerWorkPhone || null,
  ssn: borrower?.borrowerSSN,
  preferredContactMethod: borrower?.borrowerPreferredContactMethod,
  citizenship: borrower?.borrowerCitizenship,
  propertyOccupancyDate: loanApplication?.borrowerProperty?.propertyOccupancyDate,
});

export const createCoBorrowerPersonalInformationPayload = (coBorrower: CoBorrowerProps) => ({
  dateofBirth: coBorrower?.borrowerDOB === '' ? coBorrower?.borrowerDOB : formatBirthDate(coBorrower?.borrowerDOB || ''),
  maritalStatus: coBorrower?.borrowerMaritalStatus,
  primaryPhone: formatPhoneNumber(coBorrower?.borrowerPrimaryPhone),
  alternatePhone: coBorrower?.borrowerWorkPhone || null,
  ssn: coBorrower?.borrowerSSN,
  preferredContactMethod: coBorrower?.borrowerPreferredContactMethod,
  citizenship: coBorrower?.borrowerCitizenship,
  firstName: coBorrower?.borrowerFirstName,
  lastName: coBorrower?.borrowerLastName,
  suffixName: coBorrower?.borrowerSuffixName,
  middleName: coBorrower?.borrowerMiddleName,
  email: coBorrower?.borrowerEmailAddress,
  creditInformation: null,
});
