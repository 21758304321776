interface GetTextColorParams {
  $isSelected?: boolean;
  $isCustomColor?: boolean;
  $activeTextBottomColor?: string;
  $disabledTextBottomColor?: string;
  $isSingleProgram?: boolean;
  theme?: {
    colors?: {
      textSecondary: string;
      textLightLilac: string;
    };
  };
}

interface GetBackgroundColorParams {
  $isSelected?: boolean;
  $isCustomColor?: boolean;
  $selectedProgramBackground?: string;
  $disabledProgramBackground?: string;
  $isSingleProgram?: boolean;
  theme: { colors: { bgWhite: string; bgLightBlue: string; bgSecondary: string } };
}

export const getTextColor = ({ $isSelected, $isCustomColor, $activeTextBottomColor, $disabledTextBottomColor, $isSingleProgram }: GetTextColorParams, { theme }: any) => {
  if ($isSelected && !$isSingleProgram) return $isCustomColor ? $activeTextBottomColor : theme.colors.textSecondary;
  if ($isSingleProgram) return theme.colors.bgWhite;
  return $isCustomColor ? $disabledTextBottomColor : theme.colors.textSecondary;
};

export const getBackgroundColor = (
  { $isSelected, $isCustomColor, $selectedProgramBackground, $disabledProgramBackground, $isSingleProgram }: GetBackgroundColorParams,
  { theme }: any,
) => {
  if ($isSelected && !$isSingleProgram) return $isCustomColor ? $selectedProgramBackground : theme.colors.bgWhite;
  if ($isSingleProgram) return $isCustomColor ? $selectedProgramBackground : theme.colors.bgSecondary;
  return $isCustomColor ? $disabledProgramBackground : '#BFC4CD';
};
