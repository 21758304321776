import styled, { css } from 'styled-components';
import ArrowDownIcon from '../../../assets/images/arrow-down.svg?react';
import PhoneInputIcon from '../../../assets/images/phone-input.svg?react';

export type PhoneInputProps = {
  size?: string;
  $hasError?: boolean;
  $isIconUp?: boolean;
  $hasIcon?: boolean;
  $isHidden?: boolean;
  $focused?: boolean;
  $type_of_icon?: string;
  $isAddressIcon?: boolean;
  $iconColor?: string;
  $isPersonalInformation?: boolean;
  $hasAsterisk?: boolean;
};

const IconPathWidth = css`
  path {
    width: 1.25rem;
  }

  .circle-color {
    fill: ${({ theme }) => theme.colors.borderColor};
  }
`;

const AlignText = css`
  display: flex;
  align-items: center;
`;

export const Container = styled.div<PhoneInputProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  flex-direction: column;
  min-width: ${({ size }) => size || 'auto'};
  flex-grow: 1;
  flex-shrink: 0;
  ${({ $hasAsterisk, theme }) =>
    $hasAsterisk &&
    css`
      label::after {
        content: '*';
        color: ${theme.colors.errorColor || 'red'};
        visibility: visible;
      }
    `}
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-shrink: revert;
  }
`;

export const Label = styled.label<PhoneInputProps>`
  display: block;
  font-weight: bold;
  font-family: Poppins;
  font-size: 1.063rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.colors.bgGrey};
  width: ${({ $hasAsterisk }) => ($hasAsterisk ? 'max-content' : '100%')};
`;

export const InputGroup = styled.div`
  ${AlignText};
  border: 0.063rem solid #ced4da;
  border-radius: 1.875rem;
  background: ${({ theme }) => theme.colors.bgWhite};
  transition: border-color 0.2s ease-in-out;
  height: 3.125rem;
  overflow: hidden;

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.borderColorFocus};
  }
`;

export const Input = styled.input<PhoneInputProps>`
  border: none;
  padding: 0.5rem;
  font-family: Poppins, sans-serif;
  font-size: 1.188rem;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.textGrey};
  margin-left: ${({ $isPersonalInformation }) => ($isPersonalInformation ? '0rem' : '0.5rem')};
  min-width: 1rem;
  box-sizing: border-box;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: white;
    `}
`;

export const DropDownHeader = styled.div<PhoneInputProps>`
  ${AlignText};
  gap: 0.625rem;
  min-width: 1rem;
  border-right: 0.063rem solid ${({ theme, $hasError }) => ($hasError ? theme.colors.errorColor : theme.colors.borderColor)};
  border-radius: 1.875rem 0 0 1.875rem;

  div {
    font-family: Poppins, sans-serif;
    font-size: 1.188rem;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.textGrey};
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: none;
    }
  }
`;

export const DropDownListContainer = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
`;

export const DropDownList = styled.ul<PhoneInputProps>`
  padding: 0;
  margin-top: 13rem;
  background: ${({ theme }) => theme.colors.bgWhite};
  border: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
  box-shadow: 0 0.625rem 3.125rem rgba(0, 0, 0, 0.2);
  box-sizing: border-box;

  &:first-child {
    padding: 1rem;
    max-width: 15rem;
    width: 10rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  margin-bottom: 0.5em;
  width: 100%;
  padding: 0.2rem;
  font-size: 1rem;

  &:hover {
    background: ${({ theme }) => theme.colors.bgLightGrey};
  }
`;

export const IconContainer = styled.div<PhoneInputProps>`
  position: relative;
  margin: ${({ $isPersonalInformation }) => ($isPersonalInformation ? '0 0 0 0.7rem' : '0 0.7rem')};
  ${AlignText};
  pointer-events: none;

  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const ErrorMessage = styled.span`
  display: block;
  font-family: Poppins, sans-serif;
  font-size: 0.625rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.errorColor};
`;

export const DownIcon = styled(ArrowDownIcon)<PhoneInputProps>`
  width: 1.25rem;
  height: 1.75rem;
  margin-right: 1rem;
  transform: ${({ $isIconUp }) => ($isIconUp ? 'rotate(180deg)' : 'none')};
  ${IconPathWidth};
`;

export const PhoneIcon = styled(PhoneInputIcon)`
  width: 2rem;
  height: 3rem;
  ${IconPathWidth};
`;
