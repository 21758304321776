import React, { useEffect, useState, useMemo } from 'react';
import { NavBar } from '@components/NavBar/NavBar';
import { SideBar } from '@components/SideBar/SideBar';
import { AppContainer, ContentContainer, Spinner, WrapperLoading, WrapperResume } from './App.styles';
import { Footer } from '@components/Footer/Footer';
import { MainContent } from '@components/Main/Main';

import { useAppSelector } from '@app/hooks';
import Resume from '@/components/Resume/Resume';
import { Helmet } from 'react-helmet';
import useInjectScripts from '@/hooks/useInjectScripts';
import NewRelicConsent from '@/components/Layout/NewRelicConsent/NewRelicConsent';
import { useDispatch } from 'react-redux';

type GenericAttributes = {
  [key: string]: string | boolean | undefined;
};

type Script = {
  inlineContent?: string;
  src?: string;
  isEnabled: boolean;
  async?: boolean;
  defer?: boolean;
  variables?: { [key: string]: string | null };
  attributes?: GenericAttributes;
};

const App: React.FC = () => {
  const TemplateConfig = useAppSelector((state) => state.TemplateConfig);
  const { isResume, value } = useAppSelector((state) => state.currentPage);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const isTemplateConfig = Boolean(TemplateConfig && Object.keys(TemplateConfig).length > 0);
  const isResumeV2 = TemplateConfig?.features?.resume?.isEnabled ?? true;
  const isTemplateResume = isResume && isResumeV2;
  const favicon = TemplateConfig?.globalTheme?.favicon || '';
  const isLoanDepot = TemplateConfig?.lenderName === 'loanDepot';
  const htmlScripts = useMemo(() => TemplateConfig?.pages?.features?.htmlScripts || { isEnabled: false, scripts: [] }, [TemplateConfig]);
  const [htmlScriptsConsent, setHtmlScriptsConsent] = useState(htmlScripts);
  const { renderScripts } = useInjectScripts(htmlScriptsConsent);
  const isCompletionPage = value === 'Completion' && isLoanDepot;
  const [consentGiven, setConsentGiven] = useState(() => {
    const savedConsent = localStorage.getItem('newRelicConsent');
    return savedConsent === 'true';
  });

  useEffect(() => {
    setHtmlScriptsConsent(htmlScripts);
  }, [htmlScripts]);

  useEffect(() => {
    if (consentGiven && TemplateConfig?.features?.htmlScripts?.isEnabled) {
      const scriptIndex = TemplateConfig?.features?.htmlScripts.scripts.findIndex((script) => script.type === 'New Relic Monitoring');

      if (scriptIndex !== -1) {
        const scriptNewRelic = TemplateConfig?.features?.htmlScripts?.scripts?.[scriptIndex];
        const updatedScript: Script = {
          ...scriptNewRelic,
          inlineContent: scriptNewRelic.inlineContent
            ?.replace(/mask_text_selector:\s*'\*'/g, 'mask_text_selector: ""')
            ?.replace(/mask_all_inputs:\s*true/g, 'mask_all_inputs: false'),
          attributes: scriptNewRelic.attributes ? { ...scriptNewRelic.attributes } : undefined,
        };

        if (scriptNewRelic.inlineContent !== updatedScript.inlineContent) {
          setHtmlScriptsConsent((prevScripts) => ({
            ...prevScripts,
            scripts: [updatedScript],
            isEnabled: prevScripts?.isEnabled ?? true,
          }));

          dispatch({
            type: 'TemplateConfig/updateTemplateConfig',
            payload: updatedScript,
          });
        }
      }
    }
  }, [consentGiven, TemplateConfig?.features?.htmlScripts]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (TemplateConfig?.lenderId && isTemplateConfig) {
      setLoading(false);
    }
  }, [TemplateConfig, isTemplateConfig]);

  const handleConsent = (value: boolean) => {
    console.log('User consented:', value);
    setConsentGiven(value);
    localStorage.setItem('newRelicConsent', value.toString());
  };

  return (
    <>
      <Helmet>
        {favicon && <link rel="icon" href={favicon} />}
        {renderScripts()}
      </Helmet>
      {!consentGiven && <NewRelicConsent onConsent={handleConsent} />}
      <WrapperLoading $loading={loading} $isResume={isTemplateResume}>
        <Spinner $loading={loading} $isResume={isTemplateResume} />
      </WrapperLoading>
      <WrapperResume $loading={loading} $isResume={isTemplateResume}>
        <Resume />
        <Footer variant="primary" />
      </WrapperResume>
      <AppContainer $loading={loading} $isResume={isTemplateResume}>
        {!isCompletionPage && <SideBar variant="primary" />}
        <ContentContainer $isCompletionPage={isCompletionPage}>
          <NavBar variant="primary" backgroundColor={TemplateConfig?.globalTheme?.backgroundColor} />
          <MainContent variant="primary" />
          <Footer variant="primary" />
        </ContentContainer>
      </AppContainer>
    </>
  );
};

export default App;
