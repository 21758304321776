import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  TextContainer,
  EquityGroup,
  Wrapper,
  EstimatedAvailableIcon,
  EstimatedValueIcon,
  EstimatedHomeIcon,
  RemainingMortgageIcon,
  TitleContainer,
  InfoContainer,
  InputContainer,
  ConfirmMessageContainer,
} from './EstimatedAvailableEquity.styles';
import { PropertyCard } from '@/components/Layout/PropertyCard/PropertyCard';
import { NavigationButton, PageOrigin } from '@components/Button/NavigationButton/NavigationButton';
import { TextBlock } from '@/components/text/TextBlock';
import { TextInput } from '@/components/input/TextInput/TextInput';
import { useAppSelector, useAppDispatch } from '@/app/hooks';
import { get } from 'lodash';
import { formatNumber } from '@/utils/formatValues';
import { TypeOfText } from '@/components/text/TextBlock';
import { Button } from '@/components/Button/DefaultButton/Button';
import { pages } from '@/defaultVerbiages';

interface EstimatedAvailableEquityProps {
  onAccess: (page: string) => void;
  pageOrigin: PageOrigin;
  customColor?: string;
  isCustomColor?: boolean;
  isDisabled?: boolean;
}

const EstimatedAvailableEquity: React.FC<EstimatedAvailableEquityProps> = ({ onAccess, customColor = '#5E6572', isCustomColor = false }) => {
  const [continueInquiry, setContinueInquiry] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  useEffect(() => {
    if (loanApplication.estimatedMarketValue === 0) {
      setError(true);
    } else {
      setError(false);
    }
  }, [loanApplication.remainingMortgageBalance, loanApplication.estimatedMarketValue]);

  const cltvPercentage = loanApplication?.cltv || 0;
  const calculateEstimatedHomeEquity = useCallback((cltvPercentage: number, estimatedMarketValue: number, estimatedMortgageBalance: number) => {
    const currentLTVMaxAmount = (cltvPercentage / 100) * estimatedMarketValue;
    return Math.trunc(currentLTVMaxAmount - estimatedMortgageBalance);
  }, []);

  const calculateRawHomeEquity = useCallback((estimatedMarketValue: number, estimatedMortgageBalance: number) => {
    return Math.trunc(estimatedMarketValue - estimatedMortgageBalance);
  }, []);

  const calculateAdjustedLineAmount = useCallback((estimatedHomeEquity: number, maxLineAmount: number, minLineAmount: number) => {
    const adjustedAmount = estimatedHomeEquity > maxLineAmount ? maxLineAmount : estimatedHomeEquity < minLineAmount ? minLineAmount : estimatedHomeEquity;
    return Math.trunc(adjustedAmount / 100) * 100;
  }, []);

  const handleContinueInquiry = useCallback(async () => {
    if (loanApplication?.estimatedMarketValue) setContinueInquiry(true);
  }, [loanApplication?.estimatedMarketValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = useCallback(
    (value: string, propertyType: 'estimate' | 'remaining') => {
      const inputValue = value.replace(/[\$,]/g, '');
      const numericValue = Number(inputValue);
      let maxLineAmount = get(loanApplication, ['maxLineAmount']);
      let estimate = propertyType === 'estimate' ? numericValue : get(loanApplication, ['estimatedMarketValue']);
      let remaining = propertyType === 'remaining' ? numericValue : get(loanApplication, ['remainingMortgageBalance']);

      let calculatedEstimatedHomeEquity = calculateEstimatedHomeEquity(cltvPercentage, estimate, remaining);
      const updatedLoanApplication = {
        ...loanApplication,
        estimatedMarketValue: estimate,
        remainingMortgageBalance: remaining,
        estimatedRawEquity: calculateRawHomeEquity(estimate, remaining),
        estimatedHomeEquity: calculateRawHomeEquity(estimate, remaining),
        lineAmount: calculateAdjustedLineAmount(calculatedEstimatedHomeEquity, maxLineAmount, 0),
      };
      dispatch({
        type: 'LoanApplication/updateValue',
        payload: updatedLoanApplication,
      });
    },
    [calculateAdjustedLineAmount, calculateEstimatedHomeEquity, calculateRawHomeEquity, cltvPercentage, dispatch, loanApplication],
  );

  useEffect(() => {
    if (loanApplication.estimatedHomeEquity <= 0 && (loanApplication.estimatedRawEquity ?? 0) <= 0) {
      setError(true);
    } else {
      setError(false);
    }
  }, [loanApplication.estimatedHomeEquity, loanApplication.remainingMortgageBalance, loanApplication.estimatedMarketValue]);

  useEffect(() => {
    if (!loanApplication?.statedEquityRequired) {
      handleContinueInquiry();
    }
  }, []);

  return (
    <>
      <Container>
        <PropertyCard isEstimatedAvailableEquity />
        <Wrapper>
          <EquityGroup $isHidden={templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.estimatedMarket?.isHidden ?? false}>
            <EstimatedValueIcon />
            <InfoContainer>
              <TitleContainer>
                <TextBlock
                  text={
                    templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.estimatedMarket?.title ||
                    pages.EstimatedAvailableEquity.EquityCalculator.estimatedMarket.title
                  }
                  type={TypeOfText.H2}
                  isCustomColor={isCustomColor}
                  customColor={customColor}
                />
              </TitleContainer>
              <TextContainer $isCustomColor={isCustomColor} $customColor={customColor}>
                <TextBlock
                  text={
                    templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.estimatedMarket?.text || pages.EstimatedAvailableEquity.EquityCalculator.estimatedMarket.text
                  }
                  type={TypeOfText.Text}
                />
              </TextContainer>
            </InfoContainer>
            <InputContainer>
              <TextInput
                size="10%"
                placeholder="$0"
                isCentered
                dataTestId="estimatedMarketValue"
                onChange={handleChange}
                value={formatNumber(loanApplication?.estimatedMarketValue)}
                propertyType={'estimate'}
                isRequired={false}
                isDisabled={!loanApplication?.statedEquityRequired}
              />
            </InputContainer>
          </EquityGroup>
          <EquityGroup $isHidden={templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.remainingMortgage?.isHidden ?? false}>
            <RemainingMortgageIcon />
            <InfoContainer>
              <TitleContainer data-testid="title-container">
                <TextBlock
                  text={
                    templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.remainingMortgage?.title ||
                    pages.EstimatedAvailableEquity.EquityCalculator.remainingMortgage.title
                  }
                  type={TypeOfText.H2}
                  isCustomColor={isCustomColor}
                  customColor={customColor}
                />
              </TitleContainer>
              <TextContainer $isCustomColor={isCustomColor} $customColor={customColor}>
                <TextBlock
                  text={
                    templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.remainingMortgage?.text ||
                    pages.EstimatedAvailableEquity.EquityCalculator.remainingMortgage.text
                  }
                  type={TypeOfText.Text}
                  isCustomColor={isCustomColor}
                  customColor={customColor}
                />
              </TextContainer>
            </InfoContainer>
            <InputContainer>
              <TextInput
                size="10%"
                placeholder="$0"
                onChange={handleChange}
                isCentered
                dataTestId="remainingMortgageBalance"
                propertyType={'remaining'}
                isRequired={false}
                value={formatNumber(loanApplication?.remainingMortgageBalance) ?? 0}
              />
            </InputContainer>
          </EquityGroup>
          {!continueInquiry && (
            <ConfirmMessageContainer data-testid="title-container">
              <TextBlock
                text={
                  templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.configureMessage?.text || pages.EstimatedAvailableEquity.EquityCalculator.configureMessage.text
                }
                type={TypeOfText.Text}
                isCustomColor={isCustomColor}
                customColor={customColor}
                isHidden={templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.configureMessage?.isHidden ?? false}
              />
              <Button
                variant="primary"
                label={
                  templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.configureMessage?.button ||
                  pages.EstimatedAvailableEquity.EquityCalculator.configureMessage.button
                }
                onClick={handleContinueInquiry}
                buttonSize="l"
              />
            </ConfirmMessageContainer>
          )}
          {continueInquiry && (
            <>
              <EquityGroup $isHidden={templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.estimatedHome?.isHidden ?? false}>
                <EstimatedHomeIcon />
                <InfoContainer>
                  <TitleContainer>
                    <TextBlock
                      text={
                        templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.estimatedHome?.title ||
                        pages.EstimatedAvailableEquity.EquityCalculator.estimatedHome.title
                      }
                      type={TypeOfText.H2}
                      isCustomColor={isCustomColor}
                      customColor={customColor}
                    />
                  </TitleContainer>
                  <TextContainer $isCustomColor={isCustomColor} $customColor={customColor}>
                    <TextBlock
                      text={
                        templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.estimatedHome?.text || pages.EstimatedAvailableEquity.EquityCalculator.estimatedHome.text
                      }
                      type={TypeOfText.Text}
                      isCustomColor={isCustomColor}
                      customColor={customColor}
                    />
                  </TextContainer>
                </InfoContainer>
                <InputContainer>
                  <TextInput
                    size="10%"
                    dataTestId="estimatedHomeEquity"
                    placeholder="$0"
                    propertyType={'estimated'}
                    value={formatNumber(loanApplication?.estimatedRawEquity)}
                    isRequired={false}
                    isCentered
                    isDisabled
                  />
                </InputContainer>
              </EquityGroup>
              <EquityGroup $isHidden={templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.availableEquity?.isHidden ?? false}>
                <EstimatedAvailableIcon />
                <InfoContainer>
                  <TitleContainer>
                    <TextBlock
                      text={
                        templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.availableEquity?.title ||
                        pages.EstimatedAvailableEquity.EquityCalculator.availableEquity.title
                      }
                      type={TypeOfText.H2}
                      isCustomColor={isCustomColor}
                      customColor={customColor}
                    />
                  </TitleContainer>
                  <TextContainer $isCustomColor={isCustomColor} $customColor={customColor}>
                    <TextBlock
                      text={
                        templateConfig?.pages?.EstimatedAvailableEquity?.EquityCalculator?.availableEquity?.text ||
                        pages.EstimatedAvailableEquity.EquityCalculator.availableEquity.text
                      }
                      type={TypeOfText.Text}
                      isCustomColor={isCustomColor}
                      customColor={customColor}
                    />
                  </TextContainer>
                </InfoContainer>
                <InputContainer>
                  <TextInput size="10%" placeholder="$0" value={formatNumber((cltvPercentage / 100) * (loanApplication?.estimatedRawEquity ?? 0))} isRequired={false} />
                </InputContainer>
              </EquityGroup>
            </>
          )}
        </Wrapper>
      </Container>
      <NavigationButton
        variant="primary"
        nextButton={templateConfig?.pages?.EstimatedAvailableEquity?.NavigationButton?.nextButton || 'Next'}
        isBackButton={templateConfig?.pages?.EstimatedAvailableEquity?.NavigationButton?.backButton?.isHidden || false}
        backButton={templateConfig?.pages?.EstimatedAvailableEquity?.NavigationButton?.backButton?.text || 'Back'}
        disabled={error || !continueInquiry}
        onAccess={onAccess}
        navigation={navigate}
        pageOrigin={PageOrigin.GET_STARTED_ADDRESS}
        currentPage={PageOrigin.ESTIMATED_AVAILABLE_EQUITY}
        destinationPage={PageOrigin.VMO}
      />
    </>
  );
};

export default EstimatedAvailableEquity;
