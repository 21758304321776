import React, { useEffect } from 'react';
import { BodyCard, Card, FooterCard, HeaderCard, WrapperBody, WrapperButton } from './ProgramCard.styles';
import { Button } from '@/components/Button/DefaultButton/Button';
import { ProgramInfo } from './ProgramInfo';
import { useAppSelector } from '@app/hooks';

import { pages } from '@/defaultVerbiages';

interface IProgram {
  type: string;
  name: string;
  lineAmount: string;
  estimatedApr: string;
  estimateMonthlyPmt: string;
  minLineAmount: string;
  originationFee: string;
  maxLineAmount: string;
  verbiage: string;
  interest: string;
  labelEstimatedAPR: string;
  subLabelEstimatedApr: string;
  isInfoIconEstimatedApr: boolean;
  labelEstimatedMonth: string;
  subBLabelEstimatedMonth: string;
  isInfoIconEstimatedMonth: boolean;
  labelOriginalFee: string;
  subLabelOriginalFee: string;
  isInfoIconOriginalFee: boolean;
  labelInterest: string;
  subLabelInterest: string;
  isInfoIconInterest: boolean;
}

interface IProgramCardItemProps {
  index: number;
  program: IProgram;
  totalProgram: number;
  handleModal: (info: { header?: string; bodyLow?: string; bodyHigh?: string; button: string; typeOfProgram?: string }) => void;
  typeOfProgram: string;
  isReprice: boolean;
  handleSelect: (prog: any, id: number) => void;
  selectedProgram: number[];
  acceptCreditUnion: boolean;
  isCreditUnion: boolean;
  setSelectedProgram: React.Dispatch<React.SetStateAction<number[]>>;
}

const ProgramCardItemComponent = ({
  index,
  totalProgram,
  handleModal,
  typeOfProgram,
  isReprice,
  handleSelect,
  selectedProgram,
  acceptCreditUnion,
  isCreditUnion,
  setSelectedProgram,
}: IProgramCardItemProps) => {
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const isHelocProgram = typeOfProgram === 'helocData';

  useEffect(() => {
    if (isReprice) {
      handleSelect(loanApplication.selectedProgram, selectedProgram[0]);
      if (selectedProgram.length > 0) {
        setSelectedProgram([]);
      }
    }
  }, [isReprice]); // eslint-disable-line react-hooks/exhaustive-deps

  const extractYear = (text: string) => {
    const match = text.match(/^\d{1,2}/);
    return match ? match[0] : null;
  };

  const formattedOriginationFeeValue = (
    <>
      ${loanApplication?.availablePrograms?.[typeOfProgram].rates[index].fees[0].feeAmount}
      <span>({loanApplication?.availablePrograms?.[typeOfProgram].rates[index].fees[0].feePercentage}%)</span>
    </>
  );

  useEffect(() => {
    if (!acceptCreditUnion && !isCreditUnion) {
      handleSelect(loanApplication.selectedProgram, selectedProgram[0]);
      setSelectedProgram([]);
    }
  }, [isCreditUnion]);

  return (
    <Card key={index} $isActive={selectedProgram.includes(index)}>
      <HeaderCard>
        <p>{loanApplication?.availablePrograms?.[typeOfProgram].rates[index].programName}</p>
        <p>
          {index + 1} of {totalProgram}
        </p>
      </HeaderCard>
      <WrapperBody>
        <BodyCard>
          <ProgramInfo
            value={loanApplication?.availablePrograms?.[typeOfProgram].rates[index].apr + '%'}
            label={templateConfig?.pages?.vmo?.vmoV2?.programCard?.estimatedAPR?.labelEstimatedAPR || 'Estimated'}
            subLabel={templateConfig?.pages?.vmo?.vmoV2?.programCard?.estimatedAPR?.subLabelEstimatedApr || 'APR'}
            onInfoClick={() => handleModal(templateConfig?.pages?.vmo?.vmoV2?.programCard?.estimatedAPR?.infoIcon || pages?.vmo?.vmoV2?.programCard?.estimatedAPR?.infoIcon)}
            isInfoIcon={
              templateConfig?.pages?.vmo?.vmoV2?.programCard?.estimatedAPR?.isInfoIconEstimatedApr || pages?.vmo?.vmoV2?.programCard?.estimatedAPR?.isInfoIconEstimatedApr
            }
          />
          <ProgramInfo
            value={'$' + Math.round(loanApplication?.availablePrograms?.[typeOfProgram].rates[index].estimatedMonthlyPayment) || '$5,008'}
            label={templateConfig?.pages?.vmo?.vmoV2?.programCard?.interestOnlyPayment?.labelEstimatedMonth || 'Estimated'}
            subLabel={templateConfig?.pages?.vmo?.vmoV2?.programCard?.interestOnlyPayment?.subBLabelEstimatedMonth || 'Interest Only Payment'}
            onInfoClick={() =>
              handleModal(templateConfig?.pages?.vmo?.vmoV2?.programCard?.interestOnlyPayment?.infoIcon || pages?.vmo?.vmoV2?.programCard?.interestOnlyPayment?.infoIcon)
            }
            counter="(3)"
            isInfoIcon={
              templateConfig?.pages?.vmo?.vmoV2?.programCard?.interestOnlyPayment?.isInfoIconEstimatedMonth ||
              pages?.vmo?.vmoV2?.programCard?.interestOnlyPayment?.isInfoIconEstimatedMonth
            }
          />
          {isHelocProgram && (
            <ProgramInfo
              value={formattedOriginationFeeValue}
              label={templateConfig?.pages?.vmo?.vmoV2?.programCard?.originationFee?.labelOriginalFee || ''}
              subLabel={templateConfig?.pages?.vmo?.vmoV2?.programCard?.originationFee?.subLabelOriginalFee || 'Origination Fee'}
              onInfoClick={() => handleModal(templateConfig?.pages?.vmo?.vmoV2?.programCard?.originationFee?.infoIcon || pages?.vmo?.vmoV2?.programCard?.originationFee?.infoIcon)}
              counter="(2)"
              isInfoIcon={
                templateConfig?.pages?.vmo?.vmoV2?.programCard?.originationFee?.isInfoIconOriginalFee || pages?.vmo?.vmoV2?.programCard?.originationFee?.isInfoIconOriginalFee
              }
            />
          )}

          <ProgramInfo
            value={
              extractYear(loanApplication?.availablePrograms?.[typeOfProgram].rates[index].description) + ' yr' ||
              Number(loanApplication?.availablePrograms?.[typeOfProgram].rates[index].term) / 12 + ' yr' ||
              ''
            }
            label={templateConfig?.pages?.vmo?.vmoV2?.programCard?.interestOnlyPeriod?.labelInterest || ''}
            subLabel={templateConfig?.pages?.vmo?.vmoV2?.programCard?.interestOnlyPeriod?.subLabelInterest || 'Interest Only Period'}
            onInfoClick={() =>
              handleModal(templateConfig?.pages?.vmo?.vmoV2?.programCard?.interestOnlyPeriod?.infoIcon || pages?.vmo?.vmoV2?.programCard?.interestOnlyPeriod?.infoIcon)
            }
            isInfoIcon={
              templateConfig?.pages?.vmo?.vmoV2?.programCard?.interestOnlyPeriod?.isInfoIconInterest || pages?.vmo?.vmoV2?.programCard?.interestOnlyPeriod?.isInfoIconInterest
            }
          />
        </BodyCard>
      </WrapperBody>
      <WrapperButton>
        <Button
          isDisabled={isReprice || (selectedProgram.length > 0 && !selectedProgram.includes(index))}
          label={selectedProgram.includes(index) && !isReprice ? 'Deselect' : 'Select'}
          variant="primary"
          buttonSize="s"
          onClick={() => handleSelect(loanApplication?.availablePrograms?.[typeOfProgram].rates[index], index)}
        />
      </WrapperButton>
      <FooterCard />
    </Card>
  );
};
export const ProgramCardItem = React.memo(ProgramCardItemComponent);
