import styled from 'styled-components';
import EditIconSvg from '../../../assets/images/edit-icon.svg?react';

interface PropertyCard {
  $isHidden?: boolean;
  $isHiddenMap?: boolean;
}
export const Card = styled.section<PropertyCard>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  padding: 1.563rem;
  flex-direction: column;
  align-self: stretch;
  border-radius: 0 0 0.688rem 0.688rem;
  background: #fff;
  height: 70%;
  gap: 1.563rem;
`;

export const EditIcon = styled(EditIconSvg)`
  width: 1.25rem;
  height: 1.11rem;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  height: 1.938rem;
  h5 {
    font-family: Poppins, sans-serif;
    font-size: 1.313rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${(props) => props.theme.colors.textDarkGrey};
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      font-size: 1.275rem;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 20%;
  h5 {
    font-family: Poppins, sans-serif;
    font-size: 1.188rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    color: ${(props) => props.theme.colors.textDarkGrey};
    width: 100%;
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      font-size: 1.153rem;
    }
  }
  p {
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: ${(props) => props.theme.colors.textGrey};
    width: 100%;
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      font-size: 0.728rem;
    }
  }
`;

export const CardBody = styled.section<PropertyCard>`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  width: 32%;
  margin: 0 1rem;
  img {
    display: ${({ $isHiddenMap }) => ($isHiddenMap ? 'none' : '')};
    height: 12.75rem;
    width: 100%;
    border-radius: 0.688rem 0.688rem 0 0;
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
    margin-bottom: 2rem;
    margin: 0;
  }
`;
