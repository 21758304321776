import styled, { css } from 'styled-components';

interface SectionBodyProps {
  $fullWidth?: boolean;
  $isBasicInformation?: boolean;
  $isTotalMonths?: boolean;
}

const FlexColumn = css`
  display: flex;
  flex-direction: column;
`;

const WrapperStyles = css`
  width: 100%;
  ${FlexColumn};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    p {
      text-align: start;
      font-size: 1.313rem;
    }
  }
`;

export const WrapperTitle = styled.div<SectionBodyProps>`
  ${WrapperStyles};
  p {
    font-weight: 500;
  }
`;

export const WrapperApplicant = styled.div<SectionBodyProps>`
  ${WrapperStyles};
  p {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.011rem;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding-bottom: 1rem;
  }
`;

export const WrapperSubtitle = styled.div`
  ${WrapperStyles};
  gap: 0.938rem;
  p {
    font-size: 0.938rem;
    font-weight: 400;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding-bottom: 1rem;
  }
`;

export const Separator = styled.div<SectionBodyProps>`
  height: ${({ $isTotalMonths }) => ($isTotalMonths ? '0.02rem' : '0.063rem')};
  margin-top: ${({ $isTotalMonths }) => ($isTotalMonths ? '0rem' : '0.2rem')};
  background-color: ${(props) => props.theme.colors.textLightGrey};
  width: 100%;
`;

export const Section = styled.div`
  width: 100%;
  ${FlexColumn};
`;

export const SectionHeader = styled.div`
  margin-left: 0.5rem;
  margin-bottom: 1rem;
  p {
    font-weight: 600;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    p {
      font-size: 1.063rem;
    }
  }
`;

export const SectionBody = styled.div<SectionBodyProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1 1 auto;
  padding: 0 0.5rem;

  & > * {
    width: 100%;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 0.3rem;
  }
`;

export const ItemBody = styled.div<SectionBodyProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0 0.5rem;
  p {
    text-align: right;
  }

  > :first-child {
    flex: 0 1 40%;
    max-width: 45%;
    word-break: break-word;
    overflow-wrap: break-word;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.textSecondLightGrey};

    p {
      font-size: 0.938rem;
      text-align: start;
      white-space: normal;
    }
  }

  > :last-child {
    flex: 1;
    text-align: right;
    word-break: break-word;
    overflow-wrap: break-word;

    p {
      font-size: 0.938rem;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-wrap: wrap;

    > :first-child {
      flex: 0 1 50%;
      max-width: 50%;
    }

    > :last-child {
      flex: 1;
    }
  }
`;
