import styled, { css } from 'styled-components';

type SideBarStylesProps = {
  $variant: 'primary' | 'secondary' | 'default';
};

export const SideBarWrapper = styled.div<SideBarStylesProps>`
  ${(props) => {
    return css`
      width: 23.563rem;
      height: 100vw;
      display: flex;
      position: fixed;
      flex-direction: column;
      padding-left: 3.125rem;
      top: 0;
      background: ${props.$variant === 'primary' ? props.theme.colors.bgSidebar : props.theme.colors.secondary};
      @media only screen and (max-width: ${props.theme.breakpoints.xl}) {
        display: none;
      }
    `;
  }}
`;

export const SideBarWizard = styled.div<SideBarStylesProps>`
  display: flex;
`;

export const SideBarLogo = styled.nav`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3rem;
  img {
    width: 10rem;
    margin-bottom: 2rem;
  }
`;

export const SideBarTitle = styled.div<SideBarStylesProps>`
  ${(props) => {
    return css`
      color: ${props.$variant === 'primary' ? props.theme.colors.sideBarColorHeader : props.theme.colors.primary};
      padding-top: 1.5625rem;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.625rem;
      font-weight: 600;
    `;
  }}
`;

export const FooterSideBar = styled.nav`
  display: flex;
  padding: 0.3125rem 5.125rem 0 0;
  align-items: center;
  justify-content: center;
`;
