import React from 'react';
import { DropDownBody } from './DropDown.styles';
import SafeHTML from '@/utils/safeHTML';

interface IDropdownBodyProps {
  isOpen: boolean;
  verbiage: string;
}

const DropdownBody = ({ isOpen, verbiage = '' }: IDropdownBodyProps) => (
  <DropDownBody $isOpen={isOpen}>
    <SafeHTML tag="p" html={verbiage} />
  </DropDownBody>
);

export default DropdownBody;
