import styled, { css } from 'styled-components';
import dollarPurposeIcon from '../../../assets/images/dollar-icon.svg?react';
import ArrowDownIcon from '../../../assets/images/arrow-down.svg?react';
import errorTriangle from '../../../assets/images/error_triangle.svg?react';
import InfoIcons from '../../../assets/images/info-icons.svg?react';
import IconLocation from '../../../assets/images/location-icon.svg?react';

export type DropDownProps = {
  size?: string;
  $hasError?: boolean;
  $isIconUp?: boolean;
  $hasIcon?: boolean;
  $isHidden?: boolean;
  $iconColor?: string;
  $labelContainer?: boolean;
  $hasAsterisk?: boolean;
};

const AlignText = css`
  display: flex;
  align-items: center;
`;

const FontsStyles = css`
  font-family: Poppins;
  font-style: normal;
  line-height: normal;
`;

export const DropDownContainer = styled('div')<DropDownProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  flex-direction: column;
  width: ${(props) => props.size};
  flex-shrink: 0;
  flex-grow: 1;
  ${({ $hasAsterisk, theme }) =>
    $hasAsterisk &&
    css`
      label::after {
        content: '*';
        color: ${theme.colors.errorColor || 'red'};
        visibility: visible;
      }
    `}
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 12rem;
    flex-shrink: revert;
  }
  span {
    color: ${({ $hasError, theme }) => $hasError && theme.colors.errorColor};
  }
`;

export const WrapperLabel = styled.div<DropDownProps>`
  display: flex;
  align-items: center;
`;

export const Label = styled.label<DropDownProps>`
  display: block;
  font-weight: bold;
  font-size: 1.063rem;
  font-weight: 400;
  ${FontsStyles};
  color: ${({ theme }) => theme.colors.bgGrey};
  flex-direction: row;
  justify-content: space-between;
  ${AlignText};
  width: ${({ $hasAsterisk }) => ($hasAsterisk ? 'max-content' : '100%')};
`;

export const InputContainer = styled.div<DropDownProps>`
  ${AlignText};
  justify-content: space-between;
  width: 100%;
`;

export const DollarIcon = styled(dollarPurposeIcon)`
  width: 2rem;
  height: 2rem;
  g {
    path {
      width: 1.25rem;
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
  .circle-color {
    fill: ${({ theme }) => theme.colors.borderColor};
  }
`;

export const LocationIcon = styled(IconLocation)`
  width: 1.5rem;
  height: 2rem;
  path {
    fill: ${({ theme }) => theme.colors.bgWhite} !important;
    stroke: ${({ theme }) => theme.colors.primary};
  }
`;
export const IconContainer = styled.div<DropDownProps>`
  position: relative;
  margin: 0.6rem;
  ${AlignText};
  pointer-events: none;
  flex-shrink: 0;
  path {
    fill: ${({ $iconColor }) => ($iconColor ? $iconColor : 'inherent')};
  }
`;

export const ContainerInfoIcon = styled.div`
  height: 50%;
  margin-bottom: 5px;
  ${AlignText};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-left: 0.1rem;
    align-items: stretch;
  }
`;

export const InfoIcon = styled(InfoIcons)<DropDownProps>`
  width: 1.18rem;
  height: 1.18rem;
  margin-left: 1rem;
  cursor: pointer;
  path {
    fill: ${({ $iconColor, theme }) => ($iconColor ? $iconColor : theme.colors.primary)};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-left: 0.3rem;
  }
`;

export const DropDownHeader = styled('div')<DropDownProps>`
  flex-shrink: 0;
  border-radius: 1.875rem;
  padding-left: ${({ $hasIcon }) => ($hasIcon ? '0' : '1rem')};
  border: 0.063rem solid ${({ $hasError, theme }) => ($hasError ? theme.colors.errorColor : theme.colors.borderColor)};
  background: ${({ theme }) => theme.colors.bgWhite};
  ${FontsStyles};
  font-size: 1.188rem;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.textGrey};
  ${AlignText};
  flex-direction: row;
  height: 3.125rem;
`;
export const DropDownListContainer = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
`;
export const DropDownList = styled.ul<DropDownProps>`
  padding: 0;
  margin: ${({ $labelContainer }) => ($labelContainer ? '4.8rem 0 0' : '3rem 0 0')};
  background: ${({ theme }) => theme.colors.bgWhite};
  border: 0.063rem solid ${({ theme }) => theme.colors.borderColor};
  box-sizing: border-box;
  box-shadow: 0 0.625rem 3.125rem rgba(0, 0, 0, 0.2);
  height: 100%;
  max-height: 20rem;
  overflow-y: auto;

  &:first-child {
    padding: 1rem;
    max-width: 15rem;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const ListItem = styled.li`
  list-style: none;
  margin-bottom: 0.5em;
  width: 100%;
  padding: 0.2rem;
  font-size: 1rem;
  &:hover {
    background: ${({ theme }) => theme.colors.bgLightGrey};
  }
`;
export const ErrorMessage = styled.span`
  display: flex;
  ${FontsStyles};
  font-size: 0.625rem;
  font-weight: 400;
`;

export const DownIcon = styled(ArrowDownIcon)<DropDownProps>`
  width: 1.25rem;
  height: 1.75rem;
  margin-right: 1rem;
  transform: ${({ $isIconUp }) => $isIconUp && 'rotate(3.142rad)'};
  path {
    width: 1.25rem;
  }
  .circle-color {
    fill: ${({ theme }) => theme.colors.borderColor};
  }
`;

export const ErrorIcon = styled(errorTriangle)`
  width: 0.75rem;
  height: 0.75rem;
  path {
    width: 0.25rem;
  }
  .circle-color {
    fill: ${({ theme }) => theme.colors.borderColor};
  }
`;

export const InputText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 12.65rem;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    max-width: 10.3rem;
  }
`;
