import React, { useEffect } from 'react';
import ReactSlider from 'react-slider';
import { Container, TextContainer, ValueContent, SliderContainer, TextWrapper } from './Slider.styles';
import { useDispatch } from 'react-redux';

interface SliderProps {
  value: number;
  min: number;
  max: number;
  onChange: (value: number) => void;
  scoreConfig: { label: string; rank: string; minRank: number; maxRank: number }[];
}

export const Slider: React.FC<SliderProps> = ({ value, min, max, scoreConfig, onChange }) => {
  const dispatch = useDispatch();

  const handleChange = (newValue: number) => {
    onChange(newValue);
  };
  const step = 10;

  useEffect(() => {
    dispatch({
      type: 'LoanApplication/updateSpecificField',
      payload: {
        depth: ['borrowers', 0, 'creditScore'],
        value,
      },
    });
  }, [value]);

  return (
    <Container>
      <ValueContent>
        <h2>{value}</h2>
      </ValueContent>
      <SliderContainer>
        <ReactSlider
          className="custom-slider"
          thumbClassName="custom-thumb"
          trackClassName="custom-track"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={handleChange}
          renderThumb={(props) => <div {...props} className="custom-thumb"></div>}
          renderTrack={(props, state) => <div {...props} className={`custom-track ${state.index === 0 ? 'custom-track-completed' : ''}`} />}
        />
        <TextWrapper>
          {scoreConfig.map((score, index) => (
            <TextContainer key={`score-${index}`}>
              <span>{score.label}</span>
              <p>{score.rank}</p>
            </TextContainer>
          ))}
        </TextWrapper>
      </SliderContainer>
    </Container>
  );
};
