import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wrapper } from '../wrapper.styles';
import TermsAmounts from '@/components/TermsAmounts/TermsAmounts';
import { NavigationButton, PageOrigin } from '@components/Button/NavigationButton/NavigationButton';
import { pages } from '@/defaultVerbiages';
import AmountCalculator, { TermsSection } from '../AmountCalculator/AmountCalculator';
import { useAppSelector } from '@app/hooks';

interface VmoProps {
  onAccess: (page: string) => void;
}

const VmoLineAmount: React.FC<VmoProps> = ({ onAccess }) => {
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  const [isReprice, setIsReprice] = useState<boolean>(false);
  const frontProgramType = loanApplication?.selectedProgramType === 'Line of Credit' ? 'helocData' : 'helData';
  const isICE = templateConfig.isICE ?? false;
  const isHelProgram = frontProgramType === 'helData' || isICE;
  const hasDrawAmount = templateConfig?.pages?.hasDrawAmountPage ?? false;
  const lineCalculatorInfo = templateConfig?.pages?.vmo?.vmoCalculate
    ? isHelProgram
      ? templateConfig?.pages?.vmo?.vmoCalculate?.vmoLoanAmount
      : templateConfig?.pages?.vmo?.vmoCalculate?.vmoLineAmount
    : pages?.vmo?.vmoCalculate?.vmoLineAmount;
  const amountPage = 'Line Amount';

  const pageNavigation = hasDrawAmount ? PageOrigin.VMO_DRAW_AMOUNT : PageOrigin.PERSONAL_INFORMATION;
  const navigate = useNavigate();
  return (
    <>
      <Wrapper $gap="2rem">
        <TermsAmounts vmoPage={amountPage} hasDrawAmount={hasDrawAmount} />
        <AmountCalculator info={lineCalculatorInfo as TermsSection} setIsReprice={setIsReprice} isReprice={isReprice} />
      </Wrapper>
      <NavigationButton
        variant="primary"
        nextButton={templateConfig?.pages?.vmo?.NavigationButton?.nextButton || 'Next'}
        isBackButton={templateConfig?.pages?.vmo?.NavigationButton?.backButton?.isHidden || false}
        backButton={templateConfig?.pages?.vmo?.NavigationButton?.backButton?.text || 'Back'}
        disabled={!isReprice}
        type="next"
        onAccess={onAccess}
        navigation={navigate}
        pageOrigin={PageOrigin.VMO}
        currentPage={PageOrigin.VMO_LINE_AMOUNT}
        destinationPage={pageNavigation}
      />
    </>
  );
};

export default VmoLineAmount;
