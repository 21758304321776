import React, { useEffect } from 'react';
import { NavBarWrapper, Brand, NavLoanInfoText, Col, SupportPhone, NavBarLogo } from './NavBar.styles';
import { useAppSelector } from '@app/hooks';
import { useIsMobile } from '@/hooks/useIsMobile';
import { handleCurrentVmoPage } from '@/utils/handleCurrentPage';

export interface NavBarProps {
  variant: 'primary' | 'secondary' | 'default';
  storybookLabel?: {
    pageLabel?: string;
    helpLabel?: string;
    supportLabel?: string;
    loanLabel?: string;
  };
  color?: string;
  backgroundColor?: string;
  isCustomColor?: boolean;
  isGetStarted?: boolean;
  isStartedPage?: boolean;
}

export const NavBar: React.FC<NavBarProps> = ({ variant = 'primary', storybookLabel, color = '#ffffff', isCustomColor = false, backgroundColor = '', isStartedPage = true }) => {
  const { step, value } = useAppSelector((state) => state.currentPage);
  const isMobile = useIsMobile();
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  const hasDrawPage = templateConfig?.pages?.hasDrawAmountPage ?? false;
  const { isEnabled: isVmoRedesignEnabled = false } = templateConfig?.features?.vmoRedesign ?? {};
  const hasDeclarations = !!templateConfig?.pages?.declarations;

  const pagesWithVmoVersion = handleCurrentVmoPage(isVmoRedesignEnabled, hasDrawPage, hasDeclarations);
  const isGetStarted = templateConfig?.pages?.pages?.[step] === templateConfig?.pages?.pages?.[0] && step === 0 && isStartedPage;
  let isVmoPage;
  const getPageName = () => {
    const pageName = storybookLabel?.pageLabel || pagesWithVmoVersion[step];
    isVmoPage = pageName === 'View My Offer! (Available Programs)';
    return isMobile && isVmoPage ? 'View My Offer!' : pageName;
  };
  const isLoanDepot = templateConfig?.lenderName === 'loanDepot';
  const isCompletionPage = value === 'Completion';
  const isPhoneHelp = !isCompletionPage && !isMobile && templateConfig?.pages?.navBar?.phoneHelp;
  const showConnectSupport = storybookLabel?.loanLabel || !templateConfig?.pages?.navBar?.connectSupport?.isHidden;
  const connectSupportText = storybookLabel?.loanLabel || templateConfig?.pages?.navBar?.connectSupport?.text || '';
  const phoneHelpText = `${storybookLabel?.loanLabel || templateConfig?.pages?.navBar?.phoneHelp?.text || ''} ${templateConfig?.phoneContact || ''}`;
  const logo = templateConfig?.fixedValues?.logoInCongratsScreen || templateConfig?.globalTheme?.logo;

  useEffect(() => {
    if (!loanApplication.configurationId && !isGetStarted) {
      window.location.href = '/';
    }
  }, [loanApplication.configurationId, step]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <NavBarWrapper $variant={variant} data-testid="nav-bar" $backgroundColor={backgroundColor} $isCustomColor={isCustomColor} $isGetStarted={isGetStarted}>
      {isCompletionPage && isLoanDepot ? (
        <NavBarLogo>
          <img src={logo} alt={`${templateConfig?.lenderName}-logo`} />
        </NavBarLogo>
      ) : (
        <Brand $variant={variant} data-testid="brand-bar" color={color} $isCustomColor={isCustomColor}>
          {getPageName()}
        </Brand>
      )}
      {isPhoneHelp && (
        <SupportPhone>
          <NavLoanInfoText $isGetStarted={isGetStarted} $isHidden={templateConfig?.pages?.navBar?.phoneHelp?.isHidden ?? false}>
            <Col $variant={variant} color={color} $isCustomColor={isCustomColor}>
              {showConnectSupport && <p>{connectSupportText ?? ''}</p>}
              <p>{phoneHelpText ?? ''}</p>
            </Col>
          </NavLoanInfoText>
        </SupportPhone>
      )}
      {!isGetStarted && (
        <NavLoanInfoText $isGetStarted={isGetStarted} $isHidden={templateConfig?.pages?.navBar?.loanNumber?.isHidden ?? false}>
          <Col $variant={variant} color={color} $isCustomColor={isCustomColor}>
            {storybookLabel?.loanLabel || `${templateConfig?.pages?.navBar?.loanNumber?.text} ${loanApplication?.loanNumber}` || 'Loan Number: '}
          </Col>
        </NavLoanInfoText>
      )}
    </NavBarWrapper>
  );
};
